// import React, { Component } from "react";
// import { withRouter } from "react-router-dom";
// import Loader from "../Loader";
// import Pagination from "react-js-pagination";
// import { Link } from "react-router-dom";
// import * as constatnts from "../../Constatnts";

// class ItemLedger extends Component {
//   constructor() {
//     super();
//     this.state = {
//       products: [],
//       loading: true,
//     };
//     this.onExportClick = this.onExportClick.bind(this);
//     this.loadItemLedger = this.loadItemLedger.bind(this);
//   }

//   componentDidMount() {
//     this.loadItemLedger();
//   }
//   ////s,,,

//   loadItemLedger() {
//     this.setState({ loading: true });
//     this.props
//       .getItemLedger(
//         this.props.location.state.ProductID,
//         this.props.location.state.LocationID
//       )
//       .then((result) => {
//         this.setState({ products: result, loading: false });
//       });
//   }

//   onExportClick() {
//     let data = [];
//     this.state.products.map((ledger) => {
//       let obj = {
//         Date: new Date(ledger.ActionOn.seconds * 1000)
//           .toString()
//           .substring(0, 24),
//         Location: ledger.Location,
//         "Store Name": this.props.user.store.StoreName,
//         SKU: ledger.ProductSKU,
//         Name: ledger.ProductName,
//         Category: ledger.Category,
//         "Sub Category": ledger.SubCategory,
//         Brand: ledger.Brand,
//         Barcode: ledger.BarCode,
//         "Transaction No": ledger.TransactionNo,
//         "Transaction Type": ledger.TransactionType,
//         "Transaction Action": ledger.Action,
//         "Transaction Qty": Number(ledger.TransactionQty).toFixed(2),
//         "In Stock Qty": Number(ledger.InStockQty).toFixed(2),
//       };
//       data.push(obj);
//     });

//     const csvData = constatnts.objToCSV(data);
//     this.setState({ exportCalled: false });
//     const csvBlob = new Blob([csvData], { type: "text/csv" });
//     const url = window.URL.createObjectURL(csvBlob);
//     const a = document.createElement("a");
//     a.setAttribute("hidden", "");
//     a.setAttribute("href", url);
//     a.setAttribute("download", "Item Ledger.csv");
//     document.body.appendChild(a);
//     a.click();
//     document.body.removeChild(a);
//   }

//   render() {
//     return (
//       <div className="content-wrapper">
//         <section className="content">
//           <div className="row">
//             <div className="col-md-12">
//               <div className="box ">
//                 <div className="box-header with-border">
//                   <div className="row">
//                     <div className="col-md-6 col-xs-9">
//                       <font style={{ fontSize: "30px" }}>Item Ledger</font>
//                     </div>
//                     <div className="col-md-6 col-xs-3">
//                       <button
//                         type="cancel"
//                         className="btn btn-default pull-right btn-flat"
//                         style={{ marginTop: "5px", marginLeft: "10px" }}
//                         onClick={() =>
//                           this.props.history.push({
//                             pathname: "/home/reports/Instock",
//                           })
//                         }
//                       >
//                         <i className="fa fa-arrow-left"></i> Back
//                       </button>
//                       <button
//                         className="btn btn-default btn-flat btn-md pull-right"
//                         style={{ marginTop: "5px" }}
//                         onClick={this.onExportClick}
//                       >
//                         {" "}
//                         Export{" "}
//                         <i className="glyphicon glyphicon-download-alt "></i>
//                       </button>
//                     </div>
//                   </div>
//                 </div>

//                 <div className="box-body">
//                   {this.state.loading && <Loader show={this.state.loading} />}
//                   {!this.state.loading && (
//                     <div
//                       style={{ width: "auto", overflowX: "auto" }}
//                       className="scroll2"
//                     >
//                       <table className="table table-bordered table-striped">
//                         <thead>
//                           <tr>
//                             <th>Date</th>
//                             <th>Location</th>
//                             <th>SKU</th>
//                             <th>Name</th>
//                             <th>Category</th>
//                             <th>Sub Category</th>
//                             <th>Brand</th>
//                             <th>Barcode</th>
//                             {/* <th>Retail Price</th> */}
//                             <th>Transaction Type</th>
//                             <th>Transaction No</th>
//                             <th>Transaction Action</th>
//                             <th>Transaction Qty</th>
//                             <th>In Stock Qty</th>
//                           </tr>
//                         </thead>
//                         <tbody>
//                           {this.state.products.map((item, index) => (
//                             <tr>
//                               <td>
//                                 {new Date(item.ActionOn.seconds * 1000)
//                                   .toString()
//                                   .substring(0, 24)}
//                               </td>
//                               <td>{item.Location}</td>
//                               <td>{item.ProductSKU}</td>
//                               <td>{item.ProductName}</td>
//                               <td>{item.Category}</td>
//                               <td>{item.SubCategory}</td>
//                               <td>{item.Brand}</td>
//                               <td>{item.BarCode}</td>
//                               {/* <td>{item.RetailPrice}</td> */}
//                               <td>{item.TransactionType}</td>
//                               <td>{item.TransactionNo}</td>
//                               <td>{item.Action}</td>
//                               <td>{Number(item.TransactionQty).toFixed(2)}</td>
//                               <td>{Number(item.InStockQty).toFixed(2)}</td>
//                             </tr>
//                           ))}
//                         </tbody>
//                       </table>
//                     </div>
//                   )}
//                 </div>
//               </div>
//             </div>
//           </div>
//         </section>
//       </div>
//     );
//   }
// }

// export default withRouter(ItemLedger);
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Loader from "../Loader";
import { Link } from "react-router-dom";
import * as constants from "../../Constatnts";

class ItemLedger extends Component {
  constructor() {
    super();
    this.state = {
      products: [],
      loading: true,
      itemsPerPage: 5, // Number of items to load per click
      hasMore: true, // Indicates if there are more records to load
    };
    this.onExportClick = this.onExportClick.bind(this);
    this.loadItemLedger = this.loadItemLedger.bind(this);
    this.handleShowMore = this.handleShowMore.bind(this);
  }

  componentDidMount() {
    this.loadItemLedger();
  }

  // Load item ledger data
  loadItemLedger(lastVisibleDoc = null) {
    this.setState({ loading: true });
    this.props
      .getItemLedger(
        this.props.location.state.ProductID,
        this.props.location.state.LocationID,
        lastVisibleDoc,
        this.state.itemsPerPage
      )
      .then((result) => {
        const newProducts = result.itemLedgers;
        const hasMore = newProducts.length === this.state.itemsPerPage; // Check if there are more records

        this.setState((prevState) => ({
          products: [...prevState.products, ...newProducts], // Append new records
          loading: false,
          lastVisibleDoc: result.lastVisible, // Update last visible document
          hasMore, // Update whether more records are available
        }));
      })
      .catch((error) => {
        console.error("Error loading item ledger:", error);
        this.setState({ loading: false });
      });
  }

  // Handle "Show More Records" button click
  handleShowMore() {
    this.loadItemLedger(this.state.lastVisibleDoc);
  }

  onExportClick() {
    let data = [];
    this.state.products.map((ledger) => {
      let obj = {
        Date: new Date(ledger.ActionOn.seconds * 1000)
          .toString()
          .substring(0, 24),
        Location: ledger.Location,
        "Store Name": this.props.user.store.StoreName,
        SKU: ledger.ProductSKU,
        Name: ledger.ProductName,
        Category: ledger.Category,
        "Sub Category": ledger.SubCategory,
        Brand: ledger.Brand,
        Barcode: ledger.BarCode,
        "Transaction No": ledger.TransactionNo,
        "Transaction Type": ledger.TransactionType,
        "Transaction Action": ledger.Action,
        "Transaction Qty": Number(ledger.TransactionQty).toFixed(2),
        "In Stock Qty": Number(ledger.InStockQty).toFixed(2),
      };
      data.push(obj);
    });

    const csvData = constants.objToCSV(data);
    this.setState({ exportCalled: false });
    const csvBlob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "Item Ledger.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  render() {
    const { products, loading, hasMore } = this.state;

    return (
      <div className="content-wrapper">
        <section className="content">
          <div className="row">
            <div className="col-md-12">
              <div className="box ">
                <div className="box-header with-border">
                  <div className="row">
                    <div className="col-md-6 col-xs-9">
                      <font style={{ fontSize: "30px" }}>Item Ledger</font>
                    </div>
                    <div className="col-md-6 col-xs-3">
                      <button
                        type="cancel"
                        className="btn btn-default pull-right btn-flat"
                        style={{ marginTop: "5px", marginLeft: "10px" }}
                        onClick={() =>
                          this.props.history.push({
                            pathname: "/home/reports/Instock",
                          })
                        }
                      >
                        <i className="fa fa-arrow-left"></i> Back
                      </button>
                      <button
                        className="btn btn-default btn-flat btn-md pull-right"
                        style={{ marginTop: "5px" }}
                        onClick={this.onExportClick}
                      >
                        {" "}
                        Export{" "}
                        <i className="glyphicon glyphicon-download-alt "></i>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="box-body">
                  {loading && <Loader show={loading} />}
                  {!loading && products.length === 0 && (
                    <div className="text-center">
                      <h4>No data found.</h4>
                    </div>
                  )}
                  {!loading && products.length > 0 && (
                    <div
                      style={{ width: "auto", overflowX: "auto" }}
                      className="scroll2"
                    >
                      <table className="table table-bordered table-striped">
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Location</th>
                            <th>SKU</th>
                            <th>Name</th>
                            <th>Category</th>
                            <th>Sub Category</th>
                            <th>Brand</th>
                            <th>Barcode</th>
                            <th>Transaction Type</th>
                            <th>Transaction No</th>
                            <th>Transaction Action</th>
                            <th>Transaction Qty</th>
                            <th>In Stock Qty</th>
                          </tr>
                        </thead>
                        <tbody>
                          {products.map((item, index) => (
                            <tr key={index}>
                              <td>
                                {new Date(item.ActionOn.seconds * 1000)
                                  .toString()
                                  .substring(0, 24)}
                              </td>
                              <td>{item.Location}</td>
                              <td>{item.ProductSKU}</td>
                              <td>{item.ProductName}</td>
                              <td>{item.Category}</td>
                              <td>{item.SubCategory}</td>
                              <td>{item.Brand}</td>
                              <td>{item.BarCode}</td>
                              <td>{item.TransactionType}</td>
                              <td>{item.TransactionNo}</td>
                              <td>{item.Action}</td>
                              <td>{Number(item.TransactionQty).toFixed(2)}</td>
                              <td>{Number(item.InStockQty).toFixed(2)}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>

                {/* Show More Records Button */}
                {!loading && hasMore && (
                  <div className="box-footer text-center">
                    <button
                      className="btn btn-primary"
                      onClick={this.handleShowMore}
                    >
                      Show More Records
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(ItemLedger);
