import firebase, { database } from "../firebase";
import * as actionTypes from "../actionTypes";
import * as constants from "../Constatnts";

export function setStores(stores) {
  return (dispatch) => {
    dispatch({ type: actionTypes.SET_STORES, stores });
  };
}
export function saveBarcodeSetting(barcodeSetting, key) {
  return (dispatch, getState) => {
    barcodeSetting.ActionOn = firebase.firestore.FieldValue.serverTimestamp();
    barcodeSetting.ActionBy = getState().user.user.Name;
    barcodeSetting.ActionByUID = getState().user.user.UID;
    barcodeSetting.ActionByEmailID = getState().user.user.EmailID;
    barcodeSetting.Action = constants.AUDIT_TRAIL_EDIT;
    // console.log('saveBarcodeSetting - barcodeSetting',barcodeSetting)
    return database
      .collection("BarcodeSettings")
      .doc(key)
      .set({ ...barcodeSetting });
    // return database.collection("BarcodeSettings").doc(key).set({
    //   ...barcodeSetting
    // })
  };
}

export function getBarcodeSettingDetails(key) {
  return (dispatch) => {
    return database
      .collection("BarcodeSettings")
      .doc(key)
      .get()
      .then((doc) => {
        if (doc.exists) {
          return doc.data();
        } else {
          console.log("No such barcode setting found!");
        }
      });
  };
}

export function addNewStore(store) {
  return (dispatch, getState) => {
    var startDate = new Date().toISOString().split("T")[0];

    let date = new Date();
    let expiryDays = 15;

    if (
      getState().config.WhiteLabel.Code === "PINCODE" &&
      getState().registration
    ) {
      expiryDays = 182;
    }

    date.setDate(date.getDate() + expiryDays);
    var endDate = new Date(date).toISOString().split("T")[0];
    store.ActionOn = firebase.firestore.FieldValue.serverTimestamp();
    store.ActionBy = getState().user.user.Name;
    store.ActionByUID = getState().user.user.UID;
    store.ActionByEmailID = getState().user.user.EmailID;
    store.Action = constants.AUDIT_TRAIL_NEW;
    store.NoOfEmployeesAllowed = 1;
    store.NoOfTransactionalSMS = 0;
    store.NoOfCashRegistersAllowed = 1;
    store.NoOfUsersAllowed = 1;
    store.StartDate = startDate;
    store.ExpiryDate = endDate;

    return database
      .collection("Stores")
      .add({
        ...store,
      })
      .then(function(docRef) {
        return docRef.id;
      })
      .catch(function(error) {
        console.error("Error adding store: ", error);
      });
  };
}

export function getStores(
  registrationID,
  from,
  size,
  option = "active",
  searchText = "",
  searchCity = "",
  searchState = "",
  searchPostalCode = "",
  searchCountry = "",
  searchCustomField1 = "",
  searchCustomField2 = "",
  searchCustomField3 = "",
  searchCustomField4 = "",
  searchCustomField5 = "",
  searchCustomField6 = ""
) {
  let searchParameters = {
    from: from,
    size: size,
    track_total_hits: true,
    query: {
      bool: {
        must: [{ match: { "RegistrationID.keyword": registrationID } }],
        must_not: [],
        should: [],
        minimum_should_match: 1,
        filter: {
          bool: {
            must: [],
          },
        },
      },
    },
    sort: [
      {
        "StoreCode.keyword": {
          order: "asc",
        },
      },
    ],
  };
  if (option === "active") {
    searchParameters.query.bool.must_not.push({ match: { IsDeleted: "true" } });
  }
  if (option === "inactive") {
    searchParameters.query.bool.must_not.push({
      match: { IsDeleted: "false" },
    });
  }
  searchParameters.query.bool.should.push({
    regexp: { StoreCode: "@" + searchText.trim().toLowerCase() + ".*" },
  });
  searchParameters.query.bool.should.push({
    match_phrase: { StoreName: searchText.trim().toLowerCase() },
  });
  searchParameters.query.bool.should.push({
    regexp: { EmailID: "@" + searchText.trim().toLowerCase() + ".*" },
  });
  searchParameters.query.bool.should.push({
    regexp: { PhoneNo: "@" + searchText.trim().toLowerCase() + ".*" },
  });

  if (searchCity.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      regexp: { City: "@" + searchCity.trim().toLowerCase() + ".*" },
    });
  }
  if (searchState.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      regexp: { State: "@" + searchState.trim().toLowerCase() + ".*" },
    });
  }
  if (searchPostalCode.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      regexp: {
        PostalCode: "@" + searchPostalCode.trim().toLowerCase() + ".*",
      },
    });
  }
  if (searchCountry.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      regexp: { Country: "@" + searchCountry.trim().toLowerCase() + ".*" },
    });
  }
  if (searchCustomField1.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      regexp: {
        CustomField1: "@" + searchCustomField1.trim().toLowerCase() + ".*",
      },
    });
  }
  if (searchCustomField2.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      regexp: {
        CustomField2: "@" + searchCustomField2.trim().toLowerCase() + ".*",
      },
    });
  }
  if (searchCustomField3.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      regexp: {
        CustomField3: "@" + searchCustomField3.trim().toLowerCase() + ".*",
      },
    });
  }
  if (searchCustomField4.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      regexp: {
        CustomField4: "@" + searchCustomField4.trim().toLowerCase() + ".*",
      },
    });
  }
  if (searchCustomField5.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      regexp: {
        CustomField5: "@" + searchCustomField5.trim().toLowerCase() + ".*",
      },
    });
  }
  if (searchCustomField6.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      regexp: {
        CustomField6: "@" + searchCustomField6.trim().toLowerCase() + ".*",
      },
    });
  }
  // console.log("Store Search Query", JSON.stringify(searchParameters))
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable("getStores");
    return addMessage({ text: searchParameters }).then(function(result) {
      const stores = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        stores.push({ key: data._id, ...data._source });
      });
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: stores,
      };
    });
  };
}

export function getUserStores(registrationID, userID) {
  let user = { RegistrationID: registrationID };

  return async (dispatch) => {
    let userStores = [];
    // let userStore = null
    // userStore = await getLastLoggedInStore(registrationID)

    // if(userStore)
    // {
    //     userStores.push(userStore)
    //     return userStores;
    // }
    // else
    // {
    let addMessage = firebase.functions().httpsCallable("getUserStoresV1");
    return addMessage({ text: user }).then(function(result) {
      userStores = result.data.text;
      console.log("userStores", userStores);
      dispatch({ type: actionTypes.SET_USER_STORES, userStores });
      return userStores;
    });
    // }
  };
}

function getLastLoggedInStore(registrationID) {
  let store = JSON.parse(localStorage.getItem("store"));
  // console.log('checkUserInLocalStorage', store)
  if (store && store.RegistrationID === registrationID) {
    return store;
  } else {
    return null;
  }
}

export function deleteStore(key) {
  return (dispatch, getState) => {
    return database
      .collection("Stores")
      .doc(key)
      .update({
        IsDeleted: true,
        ActionOn: firebase.firestore.FieldValue.serverTimestamp(),
        ActionBy: getState().user.user.Name,
        ActionByUID: getState().user.user.UID,
        ActionByEmailID: getState().user.user.EmailID,

        Action: constants.AUDIT_TRAIL_DELETE,
      })
      .then(() => {
        return true;
      });
  };
}

export function getStoreDetails(key) {
  return (dispatch) => {
    return database
      .collection("Stores")
      .doc(key)
      .get()
      .then((doc) => {
        if (doc.exists) {
          return doc.data();
        } else {
          console.log("No such store found!");
        }
      });
  };
}

export function updateStore(store, key, action = "") {
  return (dispatch, getState) => {
    store.ActionOn = firebase.firestore.FieldValue.serverTimestamp();
    store.ActionBy = getState().user.user.Name;
    store.ActionByUID = getState().user.user.UID;
    store.ActionByEmailID = getState().user.user.EmailID;

    store.Action = constants.AUDIT_TRAIL_EDIT;
    if (action && action !== "") {
      store.Action = action;
    }
    // console.log('store',store)
    return database
      .collection("Stores")
      .doc(key)
      .update({
        ...store,
      });
  };
}

export function checkIfStoreCodeIsUnique(storeCode, storeID, registrationID) {
  return (dispatch) => {
    let searchParameters = {
      from: 0,
      size: 1,
      query: {
        bool: {
          must: [
            { match: { RegistrationID: registrationID } },
            { match: { IsDeleted: "false" } },
            { match_phrase: { StoreCode: storeCode } },
          ],
          must_not: [],
        },
      },
    };
    if (storeID !== "") {
      searchParameters.query.bool.must_not.push({ match: { _id: storeID } });
    }
    let addMessage = firebase.functions().httpsCallable("getStores");
    return addMessage({ text: searchParameters }).then(function(result) {
      let sanitizedMessage = result.data.text;
      let isUnique = true;
      if (sanitizedMessage.hits.total.value > 0) {
        isUnique = false;
      }
      return isUnique;
    });
  };
}

export function getStorePaymentProviders(storeID) {
  return (dispatch) => {
    //  return database.collection("Product").where("RegistrationID","==",registrationID).orderBy("Name","asc").get().then((querySnapshot) => {
    return database
      .collection("StorePaymentProviders")
      .where("StoreID", "==", storeID)
      .get()
      .then((querySnapshot) => {
        const paymentProviders = [];
        querySnapshot.forEach((doc) => {
          paymentProviders.push({
            key: doc.id,
            ...doc.data(),
          });
        });
        return paymentProviders;
      })
      .catch((error) => {
        console.log("Error getting store payment providers list: ", error);
      });
  };
}

export function saveStorePaymentProvider(key, paymentProvider) {
  return (dispatch, getState) => {
    console.log("paymentProvider", paymentProvider);
    if (key === "") {
      return database
        .collection("StorePaymentProviders")
        .add({
          ...paymentProvider,
        })
        .then(function(docRef) {
          return docRef.id;
        })
        .catch(function(error) {
          console.error("Error adding store payment provider: ", error);
        });
    }
  };
}
