import Main from "./Main";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getPincodeGlobalCatalogMasterData } from "../actions/PincodeAction";
import {
  setAuthUser,
  getUserDetails,
  signOutUser,
  createUser,
  setStore,
  setHasAccessToMultipleStores,
  setCashRegister,
  getUserACL,
  setCashRegisterClosing,
  updateUser,
} from "../actions/userAction";
import {
  getPosts,
  savePost,
  deactivatePost,
  publishPost,
  getNextSequenceNo,
} from "../actions/PostAction";
import {
  checkBusinessNameIsUnique,
  createRegistration,
  getRegistration,
  setLogoPath,
  setFavIconPath,
  updateRegistrationSettings,
  updateRegistrationGeneralDetails,
  generateOTPForRegistration,
  validateOTPForRegistration,
  checkIfPhoneNoExists,
  checkIfEmailIDExists,
} from "../actions/RegistrationAction";
import {
  uploadFileToServer,
  uploadImagesToServer,
} from "../actions/fileUploadAction";
import {
  pushProductToRedux,
  getActiveProducts,
  addNewProduct,
  updateProductImage,
  getProducts,
  deleteProduct,
  getProductDetails,
  updateProduct,
  updateProductVariants,
  getProductsForInventorySearch,
  getProductsByBarCode,
  getProductsBySKU,
  getProductsSearch,
  pushProductsToRedux,
  getBulkProducts,
  poupulateSaleCategories,
  poupulateSaleSubCategories,
  getProductsForPOSearch,
  getProductsByCategoryID,
  getProductsBySubCategoryID,
  getActiveProductsCountByCategoryID,
  getActiveProductsCountBySubCategoryID,
  checkIfSKUExists,
  checkIfBarcodeMRPIsUnique,
} from "../actions/ProductAction";
import {
  checkIfCustomerCodeIsUnique,
  searchCustomer,
  getCustomerWalletBalance,
  addCustomer,
  getCustomers,
  getCustomerDetails,
  updateCustomer,
  deleteCustomer,
  getCustomerDashboardHeaders,
  getWalletLedgersByCustomerID,
  getCustomerTransactions,
  checkIfCustomerPhoneNoIsUnique,
  checkIfCustomerStoreMappingExists,
  getActiveCustomers,
  pushCustomersToRedux,
  getBulkCustomers,
  getCustomerProducts,
  getLoyaltyLedgersByCustomerID,
  getPackagesByCustomerID,
  getTopProductsForACustomer,
  updateCustomerNote,
  initialiseCustomers,
  getCustomerDetailsByStoreID,
} from "../actions/CustomerAction";
import {
  generateOTP,
  validateOTP,
  addSales,
  updateSales,
  updateSalesStatus,
  cancelSales,
  parkSales,
  getSaleDetails,
  getTransactionRunningNo,
  getKOTRunningNo,
  updateTransactionRunningNo,
  getCashRegisterOpeningDetails,
  openCashRegister,
  getPaymenDetailstForCashRegisterClosing,
  closeCashRegister,
  getCashRegisterClosingList,
  getCashRegisterClosingDetails,
  getCustomerOutstanding,
  initializseSales,
  getOutstandingRRN,
  outForDelivery,
  markDelivered,
  readyForPickup,
  getDeliveryDetailReport,
  getDeliveryDetailReportInfinityApp,
  getDeliveryStatusSummary,
  apiGetSaleDetails,
  addPaymentProviderTransaction,
  updatePaymentProviderTransaction,
  updateOrderStatus,
  updateDeliveryDetails,
  saveKOT,
  orderConfirmed,
  updateONDCDeliveryStatus,
  generatePowerUserOTP,
  validatePowerUserOTP,
  deleteCashRegisterOpening,
  generateEInvoice,
} from "../actions/SalesAction";
import {
  addNewStore,
  getUserStores,
  getStores,
  deleteStore,
  getStoreDetails,
  updateStore,
  checkIfStoreCodeIsUnique,
  saveBarcodeSetting,
  getBarcodeSettingDetails,
  setStores,
  saveStorePaymentProvider,
  getStorePaymentProviders,
} from "../actions/StoresAction";
import {
  addStoreLocation,
  getStoreLocations,
  deleteStoreLocations,
  getStoreLocationDetails,
  updateStoreLocation,
} from "../actions/StoreLocationsAction";
import {
  addCashRegister,
  getCashRegisters,
  deleteCashRegister,
  getCashRegisterDetails,
  updateCashRegister,
  updateLastClosingBalance,
  getLastClosingBalance,
} from "../actions/CashRegisterAction";
import {
  addNewEmployee,
  getEmployees,
  deleteEmployee,
  getEmployeeDetails,
  updateEmployee,
  checkIfEmployeeCodeIsUnique,
  getActiveEmployees,
  getEmployeeServices,
  saveEmployeeServices,
  getEmployeeRole,
} from "../actions/EmployeesAction";
import {
  getCustomFieldNames,
  saveCustomFieldNames,
} from "../actions/CustomFieldsAction";
import {
  getAllCustomFieldNames,
  getKeywords,
  getSalesChannel,
  getAppBanner,
  getHelpVideos,
  getCountries,
  getCurrencyDenominations,
  getAllCountries,
  updateCountryConfig,
  setPageFiltersV1,
  setSelectedMenu,
  setSelectedSubMenu,
  // setSalesChannel,
  setSelectedSalesChannel,
  setNewOnlineOrder,
} from "../actions/ConfigAction";
import {
  getPaymentTypes,
  savePaymentType,
  deletePaymentType,
} from "../actions/PaymentTypes";
import {
  searchProductBrands,
  getProductBrands,
  deleteProductBrand,
  saveProductBrand,
  checkIfProductBrandIsUnique,
} from "../actions/ProductBrandsAction";
import {
  getProductTaxes,
  saveProductTaxes,
  deleteProductTax,
} from "../actions/ProductTaxesAction";
import {
  pushProductCategoriesToRedux,
  pushProductSubCategoriesToRedux,
  saveProductCategory,
  deleteProductCategory,
  getProductCategories,
  getProductSubCategories,
  checkIfProductCategoryIsUnique,
  checkIfProductSubCategoryIsUnique,
  searchProductCategories,
  searchProductSubCategories,
  getActiveSubCategoriesCount,
} from "../actions/ProductCategoryAction";
import {
  getTransactionTypes,
  deleteTransactionType,
  saveTransactionType,
} from "../actions/TransctionTypeAction";
import {
  savePrintConfig,
  savePrintConfigTemplate,
  deletePrintConfig,
  getPrintConfigs,
  getPrintConfigsList,
  getPrintConfigByID,
  apiGetPrintConfigByID,
  pushPrintConfigToRedux,
} from "../actions/PrintConfigAction";
import {
  getMembership,
  saveMembership,
  deleteMembership,
  getMembershipDetails,
  getActiveMemberships,
} from "../actions/MembershipAction";
import {
  saveExpenseCategories,
  deleteExpenseCategories,
  getExpenseCategories,
  getActiveExpenseCategories,
  checkIfExpenseCategoriesIsUnique,
} from "../actions/ExpenseCategoryAction";
import {
  getEmployeeDashboard,
  getEmployeeProductTypeWise,
  getEmployeeTransactionTypeProductTypeWise,
  getBillWiseDetailReport,
  getInvoiceDetailReport,
  getEmployeeItemDetailReport,
  getProductTypeSoldReportData,
  getItemBrandSoldReportData,
  getItemCategorySoldReportData,
  getSubCategoryItemSoldReportData,
  getDashboardHeaders,
  getSalesDashboardPincode,
  getProductWiseSales,
  getProductTransactionTypeWiseSales,
  getCategoryWiseSales,
  getCategoryTransactionTypeWiseSales,
  getEmployeeWiseSales,
  getItemSoldReportData,
  getSalesTransaction,
  getPaymentTypeWiseSales,
  getSalesDashboard,
  getPurchaseDashboard,
  getPurchaseForLast12Months,
  getCustomerReferenceReport,
  getProductTypeWiseSales,
  getPaymentWiseTransaction,
  getWalletWiseTransaction,
  getExpensesforSalesDashboard,
  getExpenseList,
  getExpenseCategoryList,
  getExpenseByParty,
  getItemInStockData,
  getItemLedger,
  getDailySales,
  getDailySalesWithoutReceivePayment,
  getPaymentModeWiseDaily,
  getPaymentModeWiseData,
  getEmployeeWorksheet,
  getPaymentModeTransactionTypeWiseSales,
  getAllInvetoryDetailReport,
  getCustomerWalletLedger,
  getCustomerOutStandingLedger,
  getOutStandingLedgerByCustomerID,
  getWalletLedgersByCustomer,
  getUniqueNos,
  getUniqueNoLedger,
  getBulkInstock,
  getItemInStockTotals,
  getVendorLedgerFromInventory,
  getVendorLedgerFromExpense,
  getVendorOpeningBalanceFromExpense,
  getVendorOpeningBalanceFromInventory,
  getCustomersForMembershipValidity,
  getTopSpentCustomers,
  getTopSpentCustomersByCustomerIDs,
  getCustomersForLoyalty,
  getLoyaltyLedger,
  getInventoryHistoryProducts,
  getOpeningStockFromInventoryHistory,
  getOpeningStockFromDenomInventory,
  getStockUpdateFromInventoryHistory,
  getOpeningStockFromDenomSales,
  getProductSoldForAMonth,
  getAvgProductSoldForAPeriod,
  getInventoryHistoryReport,
  getDenomInventoryHistoryReport,
  getInstockQtybyProductIDs,
  getDenomSalesHistoryReport,
  getMembershipData,
  getExpiredMembershipData,
  getActiveMembershipData,
  getMembershipSales,
  getPaymentReceivedDaily,
  getSalesForLast12Months,
  getSubCategoryWiseSales,
  getBrandWiseSales,
  getBrandTransactionTypeWiseSales,
  getTopGoodsForSales,
  getTopServices,
  getTopWallets,
  getTopPackages,
  getWalkinCustomers,
  getSalesCustomers,
  getRepeatedCustomers,
  getNewCustomers,
  getTopProductType,
  getSpecificPaymentModeSales,
  getTotalPaymentReceived,
  getProfitandLossReport,
  getInvoiceDetailReport2,
  getInvoicesForGSTR1B2B,
  getInvoicesForGSTR1,
  getInvoicesForGSTR1B2C,
  getProductsByProductIDs,
  getSalesBasedOnTransactionTypes,
  getOfferReport,
  getOfferSummaryReport,
  getAttendanceReport,
  getBatchData,
  getActiveProductsForInventoryHistory,
  getHSNWiseSummaryReport,
  getProductSoldBasedOnCashRegister,
  getSalesPaymentModesBySalesIDs,
} from "../actions/ReportAction";
import {
  saveExpenses,
  getExpenses,
  getExpenseDetails,
  searchParty,
  cancelExpense,
  getExpenseRunningNo,
  getCashFromCounterAmountForCashRegisterClosing,
  getVendorPendingPayments,
} from "../actions/ExpenseAction";
import {
  searchVendor,
  addNewVendor,
  getVendors,
  deleteVendor,
  getVendorDetails,
  updateVendor,
  checkIfVendorCodeIsUnique,
} from "../actions/VendorAction";
import {
  convertNumberToWords,
  getPrintTemplate,
  sendSMS,
  sendEmail,
  getToastr,
  importCSV,
  getImportLogs,
  getImportDetails,
  getImportLogList,
  getVisibility,
  addDays,
  saveDataForBarcodePrint,
  callEventHook,
  isMobileApp,
  onStoreChangeResetConfig,
  onStoreChangeResetUser,
  onInventoryLineItemChange,
  connectBlueToothPrinter,
  printTextBlueToothPrinter,
  getStateCodeMaster,
  getVehicleType,
  getModeOfTransport,
  setPageFilters,
  getPageFilters,
  saveDashboardSettings,
  getDashBoardSettings,
  getFoodDeliveryPlatforms,
  getYears,
  getMonths,
  getStartDateOfMonth,
  getEndDateOfMonth,
  saveFCMToken,
  updateFCMToken,
  getFCMTokenDetails,
  getModules,
  saveConnectionStatus,
  isElectron,
  getPetTypes,
  getPetBreeds,
  saveREExciseLog,
  getRELogs,
  getDamageDataForExcise,
  showModule,
  inventoryPreConditions,
} from "../actions/CommonAction";
import { getAuditTrails } from "../actions/AuditTrailAction";
import { withRouter } from "react-router";
import { getAttendance, saveAttendance } from "../actions/AttendanceAction";
import {
  addInventory,
  updateInventory,
  cancelInventory,
  getInventoryRunningNo,
  getInventoryDetails,
  getInventoryList,
  getProductInStockQty,
  getInwardUniqueList,
  getUniqueNoIDBasedOnTransaction,
  checkIfUniqueNoIsUnique,
  getUniqueListForSalesReturn,
  getInStockQtyForProducts,
  checkUniqueNoExist,
  updateInventoryRunningNo,
  getInTransitInventoryForAStore,
  getInventoryToSale,
  getBatches,
  getBatchAuditTrail,
  getPurchaseEntryForPurchaseReturn,
  initialiseUniqueNoList,
  initialiseBatches,
  deleteInventoryInStock,
} from "../actions/InventoryAction";
// import {createMasters,createGlobalConfigurationRoles,createRoles,updateInvoices,updateProducts,updateExpenses, updateVendors} from '../actions/OnBoardingAction'
import {
  searchProductTags,
  saveProductTag,
  getProductTags,
  deleteProductTag,
  getProductTagDetails,
} from "../actions/ProductTagAction";
import {
  getOffers,
  saveOffer,
  deleteOffer,
  getOfferDetails,
  getApplicableOffers,
  saveCouponCode,
  getCouponDetails,
  validateCoupon,
} from "../actions/OfferAction";
import {
  getSurveys,
  saveSurvey,
  deleteSurvey,
  getSurveyDetails,
} from "../actions/SurveyAction";

import {
  getModifiers,
  saveModifiers,
  deleteModifiers,
  deleteModifierGroup,
  saveModifierGroup,
  getModifierGroups,
  getActiveModifiers,
} from "../actions/ModifierAction";
import {
  getPackages,
  getPackageDetails,
  updatePackage,
  getCustomerPackages,
} from "../actions/PackageAction";
import {
  getAccounts,
  deleteAccount,
  getAccountDetails,
  saveAccount,
  checkIfAccountIsUnique,
} from "../actions/AccountAction";
import {
  getTransactions,
  cancelTransaction,
  getTransactionDetails,
  saveTransaction,
  getCashDebitEntriesFromAccountManagement,
  getCashCreditEntriesFromAccountManagement,
} from "../actions/TransactionAction";
import {
  saveBOM,
  getBOMList,
  deleteBOMs,
  getBOMDetails,
} from "../actions/BOMAction";
import {
  getCoupons,
  saveCoupon,
  deleteCoupon,
  getCouponsDetails,
  getApplicableCoupons,
} from "../actions/CouponAction";
import {
  getLoyaltyType,
  deleteLoyaltyType,
  saveLoyaltyType,
  getLoyaltyTypeDetails,
  saveLoyaltySetup,
  getLoyaltySetupDetails,
  getTotalLoyaltyCustomer,
  getCustomerLoyaltyPoints,
  getLoyaltyTypeCustomerCount,
  getLoyaltyPointEarnAndBurn,
  getMonthWiseLoyaltyPointEarnAndBurn,
} from "../actions/LoyaltyAction";
// import {getLoyaltyPoint, deleteLoyaltyPoint, saveLoyaltyPoint, getLoyaltyPointDetails} from '../actions/LoyaltyPointAction'
import {
  getCustomerReferralProgram,
  saveCustomerReferralProgram,
  saveReferrals,
  apiGetCustomerReferralProgram,
} from "../actions/CustomerReferralAction";
import {
  saveAppointment,
  getAppointmentDetails,
  getAppointments,
  updateAppointmentStatus,
  cancelAppointment,
  getAppointmentsForAPeriod,
  getAllAppointments,
  getUpcomingAppointmentsForCustomer,
  getPastAppointmentsForCustomer,
  getAppointmentsForCustomer,
} from "../actions/AppointmentAction";
import {
  getSMSTemplates,
  saveSMSTemplate,
  deleteSMSTemplate,
  getSMSTemplateDetails,
  getSMSTemplatesByModule,
  getSMSTemplatesBySMSType,
  createMessageTemplates,
  getMessageTemplates,
  getMessageTemplateTypes,
} from "../actions/SMS";
import {
  getCampaign,
  saveCampaign,
  deleteCampaign,
  getCampaignDetails,
  getCustomersForCampaign,
  getSalesCustomersForCampaign,
  getNonVisitedCustomersForCampaign,
  getCampaignsToRun,
  getCampaignToRunCustomers,
  getCampaignToRunByCampaignID,
} from "../actions/CampaignAction";
import {
  getEvents,
  saveEvent,
  deleteEvent,
  getEventDetails,
} from "../actions/EventAction";
import {
  saveDraftTransaction,
  getDraftTransactions,
  deleteDraftTransaction,
} from "../actions/DraftTransaction";
import {
  initiateThirdPartyPayment,
  checkThirdPartyPaymentStatus,
} from "../actions/ThirdPartyPayment";
import {
  getProductConsumptionDetails,
  saveProductConsumption,
} from "../actions/ProductConsumption";
import { getData } from "../actions/CommonDataAction";
import {
  saveBusinessCard,
  getBusinessCard,
} from "../actions/BusinessCardAction";
import {
  getCarousels,
  saveCarousel,
  deleteCarousel,
} from "../actions/CarouselsAction";
import {
  getOmniPages,
  saveOmniPage,
  deleteOmniPage,
  getOmniShippingOptions,
  getPincodes,
  savePincodes,
  saveOmniSettings,
  getOmniSettings,
  saveOmniPaymentGatewaySettings,
  getOmniPaymentGatewaySettings,
} from "../actions/OmniAction";

import {
  addProductsFoodDeliveryPlatforms,
  updateProductsFoodDeliveryPlatforms,
  addToFoodDeliveryPlatformUpdateQueue,
  getFoodDeliveryPlatformProducts,
  getFoodDeliveryNewOrders,
  createSalesTransactionForUrbanPiperOrder,
  cancelUrbanPiperOrder,
  getInProgressOrders,
  getCompletedCancelledOrders,
  getLatestOrderNo,
} from "../actions/FoodDeliveryPlatformAction";

import {
  getCustomRoles,
  deleteCustomRoles,
  saveCustomRoles,
  getCustomRoleDetails,
  getModulesForARole,
} from "../actions/CustomRoleAction";

import { updateKOTStatus, getKOTS } from "../actions/KDSAction";

import {
  deleteTable,
  saveTable,
  checkIfTableIsUnique,
  getTables,
} from "../actions/TableAction";

import { getPlans } from "../actions/PlanAction";

import { getCancellationReasonCodes } from "../actions/ONDC";

import {
  shadowfaxPlaceOrder,
  shadowfaxCancelOrder,
  shadowfaxUpdateOrder,
} from "../actions/ShadowFaxAction";

function mapStateToProps(state) {
  return {
    user: state.user,
    post: state.post,
    registration: state.registration,
    fileUpload: state.fileUpload,
    product: state.product,
    sales: state.sales,
    config: state.config,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getPincodeGlobalCatalogMasterData,
      uploadFileToServer,
      uploadImagesToServer,
      convertNumberToWords,
      getPrintTemplate,
      sendSMS,
      sendEmail,
      getToastr,
      importCSV,
      getImportLogs,
      getImportDetails,
      getAuditTrails,
      getImportLogList,
      getVisibility,
      addDays,
      saveDataForBarcodePrint,
      callEventHook,
      isMobileApp,
      onStoreChangeResetConfig,
      onStoreChangeResetUser,
      onInventoryLineItemChange,
      connectBlueToothPrinter,
      printTextBlueToothPrinter,
      getStateCodeMaster,
      getVehicleType,
      getModeOfTransport,
      setPageFilters,
      getPageFilters,
      saveDashboardSettings,
      getDashBoardSettings,
      getFoodDeliveryPlatforms,
      getYears,
      getMonths,
      getStartDateOfMonth,
      getEndDateOfMonth,
      saveFCMToken,
      updateFCMToken,
      getFCMTokenDetails,
      getModules,
      saveConnectionStatus,
      isElectron,
      getPetTypes,
      getPetBreeds,
      saveREExciseLog,
      getRELogs,
      getDamageDataForExcise,
      showModule,
      inventoryPreConditions,
      checkBusinessNameIsUnique,
      createRegistration,
      getRegistration,
      setLogoPath,
      setFavIconPath,
      updateRegistrationSettings,
      updateRegistrationGeneralDetails,
      generateOTPForRegistration,
      validateOTPForRegistration,
      checkIfPhoneNoExists,
      checkIfEmailIDExists,
      setAuthUser,
      getUserDetails,
      signOutUser,
      createUser,
      setStore,
      setHasAccessToMultipleStores,
      setCashRegister,
      getUserACL,
      setCashRegisterClosing,
      updateUser,
      getPosts,
      savePost,
      deactivatePost,
      publishPost,
      getNextSequenceNo,
      pushProductToRedux,
      getActiveProducts,
      addNewProduct,
      updateProductImage,
      getProducts,
      deleteProduct,
      getProductDetails,
      updateProduct,
      updateProductVariants,
      getProductsForInventorySearch,
      getProductsByBarCode,
      getProductsBySKU,
      getProductsSearch,
      pushProductsToRedux,
      getBulkProducts,
      poupulateSaleCategories,
      poupulateSaleSubCategories,
      getProductsForPOSearch,
      getProductsByCategoryID,
      getProductsBySubCategoryID,
      getActiveProductsCountByCategoryID,
      getActiveProductsCountBySubCategoryID,
      checkIfSKUExists,
      checkIfBarcodeMRPIsUnique,
      checkIfCustomerCodeIsUnique,
      searchCustomer,
      getCustomerWalletBalance,
      addCustomer,
      getCustomers,
      getCustomerDetails,
      updateCustomer,
      deleteCustomer,
      getCustomerDashboardHeaders,
      getWalletLedgersByCustomerID,
      getCustomerTransactions,
      checkIfCustomerPhoneNoIsUnique,
      checkIfCustomerStoreMappingExists,
      getActiveCustomers,
      pushCustomersToRedux,
      getBulkCustomers,
      getCustomerProducts,
      getLoyaltyLedgersByCustomerID,
      getPackagesByCustomerID,
      getTopProductsForACustomer,
      updateCustomerNote,
      initialiseCustomers,
      getCustomerDetailsByStoreID,
      generateOTP,
      validateOTP,
      addSales,
      updateSales,
      updateSalesStatus,
      cancelSales,
      parkSales,
      getSaleDetails,
      getTransactionRunningNo,
      getKOTRunningNo,
      updateTransactionRunningNo,
      getCashRegisterOpeningDetails,
      openCashRegister,
      getPaymenDetailstForCashRegisterClosing,
      closeCashRegister,
      getCashRegisterClosingList,
      getCashRegisterClosingDetails,
      getCustomerOutstanding,
      initializseSales,
      getOutstandingRRN,
      outForDelivery,
      markDelivered,
      readyForPickup,
      getDeliveryDetailReport,
      getDeliveryDetailReportInfinityApp,
      getDeliveryStatusSummary,
      apiGetSaleDetails,
      addPaymentProviderTransaction,
      updatePaymentProviderTransaction,
      updateOrderStatus,
      updateDeliveryDetails,
      saveKOT,
      orderConfirmed,
      updateONDCDeliveryStatus,
      generatePowerUserOTP,
      validatePowerUserOTP,
      deleteCashRegisterOpening,
      generateEInvoice,
      addNewStore,
      getUserStores,
      getStores,
      deleteStore,
      getStoreDetails,
      updateStore,
      checkIfStoreCodeIsUnique,
      getStorePaymentProviders,
      saveStorePaymentProvider,
      saveBarcodeSetting,
      getBarcodeSettingDetails,
      setStores,
      getHelpVideos,
      getCountries,
      getCurrencyDenominations,
      getAllCountries,
      updateCountryConfig,
      setPageFiltersV1,
      setSelectedMenu,
      setSelectedSubMenu,
      // setSalesChannel,
      setSelectedSalesChannel,
      setNewOnlineOrder,
      addStoreLocation,
      getStoreLocations,
      deleteStoreLocations,
      getStoreLocationDetails,
      updateStoreLocation,
      addCashRegister,
      getCashRegisters,
      deleteCashRegister,
      getCashRegisterDetails,
      updateCashRegister,
      updateLastClosingBalance,
      getLastClosingBalance,
      addNewEmployee,
      getEmployees,
      deleteEmployee,
      getEmployeeDetails,
      updateEmployee,
      checkIfEmployeeCodeIsUnique,
      getActiveEmployees,
      getEmployeeServices,
      saveEmployeeServices,
      getEmployeeRole,
      getCustomFieldNames,
      saveCustomFieldNames,
      getAllCustomFieldNames,
      getKeywords,
      getSalesChannel,
      getAppBanner,
      getPaymentTypes,
      savePaymentType,
      deletePaymentType,
      searchProductBrands,
      getProductBrands,
      deleteProductBrand,
      saveProductBrand,
      checkIfProductBrandIsUnique,
      getProductTaxes,
      saveProductTaxes,
      deleteProductTax,
      pushProductCategoriesToRedux,
      pushProductSubCategoriesToRedux,
      saveProductCategory,
      deleteProductCategory,
      getProductCategories,
      getProductSubCategories,
      checkIfProductCategoryIsUnique,
      checkIfProductSubCategoryIsUnique,
      searchProductCategories,
      searchProductSubCategories,
      getActiveSubCategoriesCount,
      getTransactionTypes,
      deleteTransactionType,
      saveTransactionType,
      savePrintConfig,
      savePrintConfigTemplate,
      deletePrintConfig,
      getPrintConfigs,
      getPrintConfigsList,
      getPrintConfigByID,
      apiGetPrintConfigByID,
      pushPrintConfigToRedux,
      getMembership,
      saveMembership,
      deleteMembership,
      getMembershipDetails,
      getActiveMemberships,
      saveExpenseCategories,
      deleteExpenseCategories,
      getExpenseCategories,
      getActiveExpenseCategories,
      checkIfExpenseCategoriesIsUnique,
      // ,getEmployeeDashboard, getEmployeeProductTypeWise,getBillWiseDetailReport,getInvoiceDetailReport,getProductTypeSoldReportData,getItemBrandSoldReportData,getItemCategorySoldReportData,getSubCategoryItemSoldReportData,getEmployeeItemDetailReport,getDashboardHeaders,getProductWiseSales,getCategoryWiseSales,getEmployeeWiseSales,getItemSoldReportData,getSalesTransaction,getPaymentTypeWiseSales,getSalesDashboard,getCustomerReferenceReport,getProductTypeWiseSales,getPaymentWiseTransaction,getWalletWiseTransaction,getExpensesforSalesDashboard, getExpenseList,getExpenseCategoryList,getExpenseByParty, getItemInStockData,getItemLedger, getDailySales,getPaymentModeWiseDaily,getPaymentModeWiseData, getEmployeeWorksheet, getPaymentModeTransactionTypeWiseSales, getAllInvetoryDetailReport,getItemInStockTotals,getVendorLedgerFromInventory , getVendorLedgerFromExpense,getVendorOpeningBalanceFromExpense,getVendorOpeningBalanceFromInventory, getCustomersForMembershipValidity,getTopSpentCustomers,getTopSpentCustomersByCustomerIDs,getCustomersForLoyalty,getLoyaltyLedger,getInventoryHistoryProducts,getOpeningStockFromInventoryHistory,getOpeningStockFromDenomInventory,getStockUpdateFromInventoryHistory,getOpeningStockFromDenomSales,getProductSoldForAMonth, getAvgProductSoldForAPeriod,getInventoryHistoryReport,getDenomInventoryHistoryReport,getDenomSalesHistoryReport
      // ,getMembershipData,getExpiredMembershipData,getActiveMembershipData,getMembershipSales, getPaymentReceivedDaily,getSalesForLast12Months ,getSubCategoryWiseSales, getBrandWiseSales, getTopGoodsForSales, getTopServices, getTopWallets, getTopPackages, getWalkinCustomers, getSalesCustomers, getRepeatedCustomers, getNewCustomers
      getEmployeeDashboard,
      getEmployeeProductTypeWise,
      getEmployeeTransactionTypeProductTypeWise,
      getBillWiseDetailReport,
      getInvoiceDetailReport,
      getEmployeeItemDetailReport,
      getProductTypeSoldReportData,
      getItemBrandSoldReportData,
      getItemCategorySoldReportData,
      getSubCategoryItemSoldReportData,
      getDashboardHeaders,
      getSalesDashboardPincode,
      getProductWiseSales,
      getProductTransactionTypeWiseSales,
      getCategoryWiseSales,
      getCategoryTransactionTypeWiseSales,
      getEmployeeWiseSales,
      getItemSoldReportData,
      getSalesTransaction,
      getPaymentTypeWiseSales,
      getSalesDashboard,
      getPurchaseDashboard,
      getPurchaseForLast12Months,
      getCustomerReferenceReport,
      getProductTypeWiseSales,
      getPaymentWiseTransaction,
      getWalletWiseTransaction,
      getExpensesforSalesDashboard,
      getExpenseList,
      getExpenseCategoryList,
      getExpenseByParty,
      getItemInStockData,
      getItemLedger,
      getDailySales,
      getDailySalesWithoutReceivePayment,
      getPaymentModeWiseDaily,
      getPaymentModeWiseData,
      getEmployeeWorksheet,
      getPaymentModeTransactionTypeWiseSales,
      getAllInvetoryDetailReport,
      getCustomerWalletLedger,
      getCustomerOutStandingLedger,
      getOutStandingLedgerByCustomerID,
      getWalletLedgersByCustomer,
      getUniqueNos,
      getUniqueNoLedger,
      getBulkInstock,
      getItemInStockTotals,
      getVendorLedgerFromInventory,
      getVendorLedgerFromExpense,
      getVendorOpeningBalanceFromExpense,
      getVendorOpeningBalanceFromInventory,
      getCustomersForMembershipValidity,
      getTopSpentCustomers,
      getTopSpentCustomersByCustomerIDs,
      getCustomersForLoyalty,
      getLoyaltyLedger,
      getInventoryHistoryProducts,
      getOpeningStockFromInventoryHistory,
      getOpeningStockFromDenomInventory,
      getStockUpdateFromInventoryHistory,
      getOpeningStockFromDenomSales,
      getProductSoldForAMonth,
      getAvgProductSoldForAPeriod,
      getInventoryHistoryReport,
      getDenomInventoryHistoryReport,
      getInstockQtybyProductIDs,
      getDenomSalesHistoryReport,
      getMembershipData,
      getExpiredMembershipData,
      getActiveMembershipData,
      getMembershipSales,
      getPaymentReceivedDaily,
      getSalesForLast12Months,
      getSubCategoryWiseSales,
      getBrandWiseSales,
      getBrandTransactionTypeWiseSales,
      getTopGoodsForSales,
      getTopServices,
      getTopWallets,
      getTopPackages,
      getWalkinCustomers,
      getSalesCustomers,
      getRepeatedCustomers,
      getNewCustomers,
      getTopProductType,
      getSpecificPaymentModeSales,
      getTotalPaymentReceived,
      getProfitandLossReport,
      getInvoiceDetailReport2,
      getInvoicesForGSTR1B2B,
      getInvoicesForGSTR1,
      getInvoicesForGSTR1B2C,
      getProductsByProductIDs,
      getSalesBasedOnTransactionTypes,
      getOfferReport,
      getOfferSummaryReport,
      getAttendanceReport,
      getBatchData,
      getActiveProductsForInventoryHistory,
      getHSNWiseSummaryReport,
      getProductSoldBasedOnCashRegister,
      getSalesPaymentModesBySalesIDs,
      getCustomerWalletLedger,
      getCustomerOutStandingLedger,
      getOutStandingLedgerByCustomerID,
      getWalletLedgersByCustomer,
      getUniqueNos,
      getUniqueNoLedger,
      getBulkInstock,
      getExpenses,
      getExpenseDetails,
      searchParty,
      cancelExpense,
      getExpenseRunningNo,
      saveExpenses,
      getCashFromCounterAmountForCashRegisterClosing,
      getVendorPendingPayments,
      searchVendor,
      addNewVendor,
      getVendors,
      deleteVendor,
      getVendorDetails,
      updateVendor,
      checkIfVendorCodeIsUnique,
      getAttendance,
      saveAttendance,
      addInventory,
      updateInventory,
      cancelInventory,
      getInventoryRunningNo,
      getInventoryDetails,
      getInventoryList,
      getProductInStockQty,
      getInwardUniqueList,
      getUniqueNoIDBasedOnTransaction,
      checkIfUniqueNoIsUnique,
      getUniqueListForSalesReturn,
      getInStockQtyForProducts,
      checkUniqueNoExist,
      updateInventoryRunningNo,
      getInTransitInventoryForAStore,
      getInventoryToSale,
      getBatches,
      getBatchAuditTrail,
      getPurchaseEntryForPurchaseReturn,
      initialiseUniqueNoList,
      initialiseBatches,
      deleteInventoryInStock,
      // ,createMasters,createGlobalConfigurationRoles,createRoles,updateInvoices,updateProducts,updateExpenses,updateVendors,
      searchProductTags,
      saveProductTag,
      getProductTags,
      deleteProductTag,
      getProductTagDetails,
      getOffers,
      saveOffer,
      deleteOffer,
      getOfferDetails,
      getApplicableOffers,
      saveCouponCode,
      getCouponDetails,
      validateCoupon,
      getSurveys,
      saveSurvey,
      deleteSurvey,
      getSurveyDetails,
      getModifiers,
      saveModifiers,
      deleteModifiers,
      deleteModifierGroup,
      saveModifierGroup,
      getModifierGroups,
      getActiveModifiers,
      getPackages,
      getPackageDetails,
      updatePackage,
      getCustomerPackages,
      getAccounts,
      deleteAccount,
      getAccountDetails,
      saveAccount,
      checkIfAccountIsUnique,
      getTransactions,
      cancelTransaction,
      getTransactionDetails,
      saveTransaction,
      getCashDebitEntriesFromAccountManagement,
      getCashCreditEntriesFromAccountManagement,
      saveBOM,
      getBOMList,
      deleteBOMs,
      getBOMDetails,
      getCoupons,
      saveCoupon,
      deleteCoupon,
      getCouponsDetails,
      getApplicableCoupons,
      getLoyaltyType,
      deleteLoyaltyType,
      saveLoyaltyType,
      getLoyaltyTypeDetails,
      saveLoyaltySetup,
      getLoyaltySetupDetails,
      getTotalLoyaltyCustomer,
      getCustomerLoyaltyPoints,
      getLoyaltyTypeCustomerCount,
      getLoyaltyPointEarnAndBurn,
      getMonthWiseLoyaltyPointEarnAndBurn,
      // ,getLoyaltyPoint, deleteLoyaltyPoint, saveLoyaltyPoint, getLoyaltyPointDetails
      getCustomerReferralProgram,
      saveCustomerReferralProgram,
      saveReferrals,
      apiGetCustomerReferralProgram,
      saveAppointment,
      getAppointmentDetails,
      getAppointments,
      updateAppointmentStatus,
      cancelAppointment,
      getAppointmentsForAPeriod,
      getAllAppointments,
      getUpcomingAppointmentsForCustomer,
      getPastAppointmentsForCustomer,
      getAppointmentsForCustomer,
      getSMSTemplates,
      saveSMSTemplate,
      deleteSMSTemplate,
      getSMSTemplateDetails,
      getSMSTemplatesByModule,
      getSMSTemplatesBySMSType,
      createMessageTemplates,
      getMessageTemplates,
      getMessageTemplateTypes,
      getCampaign,
      saveCampaign,
      deleteCampaign,
      getCampaignDetails,
      getCustomersForCampaign,
      getSalesCustomersForCampaign,
      getNonVisitedCustomersForCampaign,
      getCampaignsToRun,
      getCampaignToRunCustomers,
      getCampaignToRunByCampaignID,
      getEvents,
      saveEvent,
      deleteEvent,
      getEventDetails,
      saveDraftTransaction,
      getDraftTransactions,
      deleteDraftTransaction,
      initiateThirdPartyPayment,
      checkThirdPartyPaymentStatus,
      getProductConsumptionDetails,
      saveProductConsumption,
      addProductsFoodDeliveryPlatforms,
      updateProductsFoodDeliveryPlatforms,
      addToFoodDeliveryPlatformUpdateQueue,
      getFoodDeliveryPlatformProducts,
      getFoodDeliveryNewOrders,
      createSalesTransactionForUrbanPiperOrder,
      cancelUrbanPiperOrder,
      getInProgressOrders,
      getCompletedCancelledOrders,
      getLatestOrderNo,
      getData,
      saveBusinessCard,
      getBusinessCard,
      getCarousels,
      saveCarousel,
      deleteCarousel,
      // ,getOmniInquiries
      getCustomRoles,
      deleteCustomRoles,
      saveCustomRoles,
      getCustomRoleDetails,
      getModulesForARole,
      updateKOTStatus,
      getKOTS,
      getOmniPages,
      saveOmniPage,
      deleteOmniPage,
      getOmniShippingOptions,
      getPincodes,
      savePincodes,
      saveOmniSettings,
      getOmniSettings,
      saveOmniPaymentGatewaySettings,
      getOmniPaymentGatewaySettings,
      deleteTable,
      saveTable,
      checkIfTableIsUnique,
      getTables,
      getPlans,
      getCancellationReasonCodes,
      shadowfaxPlaceOrder,
      shadowfaxCancelOrder,
      shadowfaxUpdateOrder,
    },
    dispatch
  );
}

const App = withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));

export default App;
