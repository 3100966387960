import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import * as constatnts from "../../Constatnts";
import Loader from "../Loader";
import ReactHtmlParser from "react-html-parser";
import Input from "../2/Controls/Input";

class HSNWiseReport extends Component {
  constructor() {
    super();
    this.state = {
      invoices: [],
      stores: [],
      storeList: [],
      searchTransactiontype: "",
      searchTransactionFromDate: new Date().toISOString().split("T")[0],
      searchTransactionToDate: new Date().toISOString().split("T")[0],
      loading: true,
      years: [],
      months: [],
      selectedYear: 0,
      selectedMonth: 0,
      showCategory: false,
    };
    this.onClearSearch = this.onClearSearch.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.onExportClick = this.onExportClick.bind(this);
    this.onStoreChange = this.onStoreChange.bind(this);
    this.loadHSNWiseSummaryReport = this.loadHSNWiseSummaryReport.bind(this);
    this.onMonthChange = this.onMonthChange.bind(this);
    this.onYearChange = this.onYearChange.bind(this);
  }

  componentDidMount() {
    let selectedYear = new Date().getFullYear();
    let selectedMonth = new Date().getMonth();
    let years = this.props.getYears();
    let months = this.props.getMonths(selectedYear);
    let searchTransactionFromDate = this.getMonthStartDate(
      selectedYear,
      selectedMonth
    );
    let searchTransactionToDate = this.getMonthEndDate(
      selectedYear,
      selectedMonth
    );
    let storeList = [];
    storeList.push({ key: this.props.user.store.key });
    this.setState({
      storeID: this.props.user.store.key,
      storeList: storeList,
      years: years,
      months: months,
      selectedYear: selectedYear,
      selectedMonth: selectedMonth,
      searchTransactionFromDate: searchTransactionFromDate,
      searchTransactionToDate: searchTransactionToDate,
    });

    this.props
      .getUserStores(this.props.user.user.RegistrationID, "")
      .then((stores) => {
        this.setState({ stores: stores });
      });

    this.loadHSNWiseSummaryReport(
      storeList,
      this.state.searchTransactiontype,
      searchTransactionFromDate,
      searchTransactionToDate,
      this.state.showCategory
    );
  }

  onYearChange(evt) {
    let selectedYear = evt.target.value;
    let months = this.props.getMonths(selectedYear);
    let selectedMonth = months[months.length - 1].Month;
    let searchTransactionFromDate = this.getMonthStartDate(
      selectedYear,
      selectedMonth
    );
    let searchTransactionToDate = this.getMonthEndDate(
      selectedYear,
      selectedMonth
    );
    this.setState({
      months: months,
      selectedYear: selectedYear,
      selectedMonth: selectedMonth,
      searchTransactionFromDate: searchTransactionFromDate,
      searchTransactionToDate: searchTransactionToDate,
    });
  }

  onMonthChange(evt) {
    let selectedMonth = evt.target.value;
    let searchTransactionFromDate = this.getMonthStartDate(
      this.state.selectedYear,
      selectedMonth
    );
    let searchTransactionToDate = this.getMonthEndDate(
      this.state.selectedYear,
      selectedMonth
    );
    this.setState({
      selectedMonth: selectedMonth,
      searchTransactionFromDate: searchTransactionFromDate,
      searchTransactionToDate: searchTransactionToDate,
    });
  }

  getMonthStartDate(selectedYear, selectedMonth) {
    return this.props.getStartDateOfMonth(selectedYear, selectedMonth);
  }

  getMonthEndDate(selectedYear, selectedMonth) {
    return this.props.getEndDateOfMonth(selectedYear, selectedMonth);
  }

  async loadData(
    storeList,
    searchTransactiontype,
    searchTransactionFromDate,
    searchTransactionToDate,
    showCategory
  ) {
    let result = await this.props.getHSNWiseSummaryReport(
      this.props.user.user.RegistrationID,
      storeList,
      searchTransactiontype,
      searchTransactionFromDate,
      searchTransactionToDate,
      showCategory
    );
    return result.searchResult;
  }

  async loadHSNWiseSummaryReport(
    storeList,
    searchTransactiontype,
    searchTransactionFromDate,
    searchTransactionToDate,
    showCategory
  ) {
    this.setState({ loading: true });

    let invoices = await this.loadData(
      storeList,
      searchTransactiontype,
      searchTransactionFromDate,
      searchTransactionToDate,
      showCategory
    );
    var col = [];
    for (var i = 0; i < invoices.length; i++) {
      for (var key in invoices[i]) {
        if (col.indexOf(key) === -1) {
          col.push(key);
        }
      }
    }

    var result = "<table class='table table-bordered'><thead><tr>";
    for (var i = 0; i < col.length; i++) {
      result += "<th>";
      result += col[i];
      result += "</th>";
    }
    result += "</tr></thead>";

    // ADD JSON DATA TO THE TABLE AS ROWS.
    result += "<tbody>";
    for (var i = 0; i < invoices.length; i++) {
      result += "<tr>";
      for (var j = 0; j < col.length; j++) {
        result += "<td>";
        result += invoices[i][col[j]] == undefined ? 0 : invoices[i][col[j]];
        result += "</td>";
      }
      result += "</tr>";
    }
    result += "</tbody>";
    result += "</table>";
    //console.log(result);
    this.setState({ invoices: result, loading: false });
  }

  handleKeyPress(e) {
    if (e.key === "Enter") {
      this.onSearchClick();
    }
  }

  onClearSearch() {
    let storeList = [];
    storeList.push({ key: this.props.user.store.key });

    let selectedYear = new Date().getFullYear();
    let selectedMonth = new Date().getMonth();

    let searchTransactionFromDate = this.getMonthStartDate(
      selectedYear,
      selectedMonth
    );
    let searchTransactionToDate = this.getMonthEndDate(
      selectedYear,
      selectedMonth
    );

    this.setState({
      selectedYear: selectedYear,
      selectedMonth: selectedMonth,
      searchTransactionFromDate: searchTransactionFromDate,
      searchTransactionToDate: searchTransactionToDate,
      storeList: storeList,
      storeID: this.props.user.store.key,
      searchTransactiontype: "",
      showCategory: false,
    });
    this.loadHSNWiseSummaryReport(
      storeList,
      "",
      searchTransactionFromDate,
      searchTransactionToDate,
      false
    );
  }

  async onExportClick() {
    let invoices = await this.loadData(
      this.state.storeList,
      this.state.searchTransactiontype,
      this.state.searchTransactionFromDate,
      this.state.searchTransactionToDate,
      this.state.showCategory
    );

    const csvData = constatnts.objToCSV(invoices);
    this.setState({ loading: false, exportCalled: false });
    const csvBlob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "HSNWiseSummaryReport.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  onSearchClick() {
    this.setState({ loading: true });
    this.loadHSNWiseSummaryReport(
      this.state.storeList,
      this.state.searchTransactiontype,
      this.state.searchTransactionFromDate,
      this.state.searchTransactionToDate,
      this.state.showCategory
    );
  }

  onStoreChange(e) {
    let storeList = [];
    if (e.target.value === "all") {
      this.state.stores.map((a) => {
        storeList.push({ key: a.key });
      });
    } else {
      storeList.push({ key: e.target.value });
    }
    this.setState({ storeList: storeList, storeID: e.target.value });
  }

  render() {
    return (
      <div className="content-wrapper">
        <section className="content">
          <div className="row">
            <div className="col-md-12">
              <div className="box ">
                <div className="box-header with-border">
                  <div className="row">
                    <div className="col-md-6 col-xs-9">
                      <font style={{ fontSize: "30px" }}>
                        HSN Wise Summary Report
                      </font>
                    </div>
                    <div className="col-md-6 col-xs-3">
                      <button
                        type="cancel"
                        className="btn btn-default pull-right btn-flat"
                        style={{ marginTop: "5px", marginLeft: "10px" }}
                        onClick={() =>
                          this.props.history.push({
                            pathname: "/home/reports/allreport",
                          })
                        }
                      >
                        <i className="fa fa-arrow-left"></i> Back
                      </button>
                    </div>
                  </div>
                </div>
                <div className="box-body">
                  <div className="row">
                    <div className="col-md-4">
                      <label>Store Name</label>
                      <select
                        className="form-control"
                        value={this.state.storeID}
                        onChange={this.onStoreChange}
                      >
                        <option value="all">All Stores</option>
                        {this.state.stores
                          .filter((e) => !e.IsDeleted)
                          .map((store) => (
                            <option value={store.key}>
                              {store.StoreCode}-{store.StoreName}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="col-md-2">
                      <div className="form-group">
                        <label>Transaction Type</label>
                        <select
                          type="text"
                          className="form-control"
                          onChange={(evt) => {
                            this.setState({
                              searchTransactiontype: evt.target.value,
                            });
                          }}
                          value={this.state.searchTransactiontype}
                        >
                          <option value="">All</option>
                          {this.props.config.TransactionTypes.filter(
                            (e) => e.Module === "sales" && !e.IsDeleted
                          ).map((e) => (
                            <option value={e.key}>{e.TransactionType}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-group">
                        <label>Year</label>
                        <select
                          type="text"
                          className="form-control"
                          value={this.state.selectedYear}
                          onChange={this.onYearChange}
                        >
                          {this.state.years.map((e) => (
                            <option value={e}>{e}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-group">
                        <label>Month</label>
                        <select
                          type="text"
                          className="form-control"
                          value={this.state.selectedMonth}
                          onChange={this.onMonthChange}
                        >
                          {this.state.months.map((e) => (
                            <option value={e.Month}>{e.MonthName}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <Input
                        type="switch"
                        label="Show Category"
                        value={this.state.showCategory}
                        onChange={(value) =>
                          this.setState({ showCategory: value })
                        }
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12" style={{ marginTop: "-25px" }}>
                      <button
                        type="button"
                        className="btn btn-primary btn-flat btn-md pull-right"
                        style={{ marginTop: "25px", marginLeft: "5px" }}
                        onClick={this.onSearchClick}
                        disabled={
                          this.state.searchTransactionFromDate === "" ||
                          this.state.searchTransactionToDate === ""
                        }
                      >
                        Show
                      </button>
                      <button
                        type="button"
                        className="btn btn-default btn-flat btn-md pull-right"
                        style={{ marginTop: "25px" }}
                        onClick={this.onClearSearch}
                      >
                        Clear
                      </button>
                      <button
                        className="btn btn-default btn-flat btn-md pull-right"
                        style={{ marginTop: "25px", marginRight: "5px" }}
                        onClick={this.onExportClick}
                      >
                        {" "}
                        Export <i className="fa fa-download "></i>
                      </button>
                    </div>
                  </div>
                  <br></br>
                  {this.state.loading && <Loader show={this.state.loading} />}
                  {!this.state.loading && (
                    <div
                      style={{ width: "auto", overflowX: "auto" }}
                      className="scroll2"
                    >
                      {ReactHtmlParser(this.state.invoices)}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(HSNWiseReport);
