import React, { Component } from "react";
import { auth } from "../firebase";
import axios from "axios";
// import './Common/Common.css'
import "./2/style.css";
// import logo from '../images/Posible_logo_new.png'
import Logo from "./2/Controls/Logo";
import Input from "./2/Controls/Input";
import Button from "./2/Controls/Button";
import { isMobileApp } from "../actions/Util";
import { getUserIP } from "../services/api/CommonApi";
// import sound from "../audio/audio/electronic-school-bell-81033.mp3"
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginid: "",
      password: "",
    };
    this.onSignIn = this.onSignIn.bind(this);
  }

  componentDidMount() {
    document.title = this.props.config.WhiteLabel.Title;
    document.documentElement.style.setProperty(
      "--primary-clr",
      this.props.config.WhiteLabel.PrimaryColor
    );
    const favicon = document.getElementById("favicon");
    favicon.href = this.props.config.WhiteLabel.FavIcon;

    let loginid = window.localStorage.getItem("loginid");
    if (loginid) {
      this.setState({ loginid: loginid });
    }
  }

  onSignIn() {
    // const audio = new Audio('https://drive.google.com/uc?export=download&id=1M95VOpto1cQ4FQHzNBaLf0WFQglrtWi7');
    // const audio = new Audio(sound);

    // audio.play();
    this.setState({ loading: true });
    let loginidError = "";
    let passwordError = "";
    if (this.state.loginid.trim() === "") {
      loginidError = "Please provide login id";
    }
    if (this.state.password.trim() === "") {
      passwordError = "Please provide password";
    }
    if (loginidError !== "" || passwordError !== "") {
      this.setState({
        loginidError: loginidError,
        passwordError: passwordError,
        loading: false,
      });
      return;
    }
    var storage = window.localStorage;
    storage.setItem("loginid", this.state.loginid);
    auth
      .signInWithEmailAndPassword(this.state.loginid, this.state.password)
      .then((result) => {
        console.log("result", result);
        const user = result.user;
        this.props.setAuthUser(user);
        this.props.getUserDetails(user.uid, true).then(async () => {
          console.log("this.props.user.user", this.props.user.user);
          // console.log("this.props.user.user", this.props.user.user);
          if (this.props.user.user) {
            const userID = this.props.user.user.key
              ? this.props.user.user.key
              : "";
            const response = await axios.post(
              "https://backend-o3dyulj4pa-el.a.run.app/v1/user/adduser",
              {
                userId: userID,
                password: this.state.password,
              }
            );
            if (this.props.user.user.AccessibleToSpecificIP) {
              // console.log('ipaddres',this.props.user.user.IPAddress);
              let ipResult = await getUserIP();
              if (ipResult.Success) {
                if (ipResult.Data.ip !== this.props.user.user.IPAddress) {
                  //
                  alert(
                    "User not allowed to access system from this IP Address"
                  );
                  this.setState({ loading: false });
                  return;
                }
              } else {
                alert("Unable to login. Please try again.");
                this.setState({ loading: false });
                return;
              }
            }
            this.props
              .getRegistration(this.props.user.user.RegistrationID)
              .then(() => {
                // this.props.onHistory.push('/home')
                this.props.history.push("/home");
              });
          } else {
            //user registration not found
            // this.props.onHistory.push('/register')
            this.props.history.push("/register");
          }
        });
      })
      .catch((error) => {
        alert(error);
        this.setState({ loading: false });
      });
  }

  render() {
    return (
      <div
        className={
          this.props.config.WhiteLabel.RegisterBackgroundCSS
            ? "login " + this.props.config.WhiteLabel.RegisterBackgroundCSS
            : "login"
        }
      >
        {isMobileApp() && <div className="header-new-background"></div>}
        <br />
        <br />
        <br />
        <div
          className={
            !isMobileApp() ? "login-container" : "login-container-mobile"
          }
        >
          {/* {(!this.props.isElectron()) && <div className="login-logo" style={{ paddingTop: "30px", paddingBottom: "30px" }}>
                    {window.cordova && <img style={{ width: "150px" }} src={"/android_asset/www/images/logo.png"} />}
                    {!window.cordova &&  <img  style={{ width: "210px" }} src={logo}/>}
                    </div>} */}
          {/* <img  style={{ width: "210px" }} src={logo}/> */}
          <Logo
            {...this.props}
            logoOption={this.props.config.WhiteLabel.LoginLogoOption}
            height={
              this.props.config.WhiteLabel.LogoDefaultHeight
                ? this.props.config.WhiteLabel.LogoDefaultHeight
                : "122px"
            }
          />
          <div>
            <Input
              type="loginid"
              placeholder="Login id"
              onEnter={this.onSignIn}
              value={this.state.loginid}
              onChange={(value) =>
                this.setState({ loginid: value, loginidError: "" })
              }
              error={this.state.loginidError}
            />
            <br />
            <Input
              type="password"
              placeholder="Password"
              onEnter={this.onSignIn}
              value={this.state.password}
              onChange={(value) =>
                this.setState({ password: value, passwordError: "" })
              }
              error={this.state.passwordError}
            />
            <br />
            <Button
              type="primary"
              text="SIGN IN"
              onClick={this.onSignIn}
              processing={this.state.loading}
            />
            <br />
            <br />
            <center>
              <Button
                type="link"
                text="Forgot Password"
                onClick={() => {
                  this.props.history.push("/forgotpassword");
                }}
              />{" "}
            </center>
            <br />
            {/* {this.props.config.WhiteLabel.Code === "PINCODE" && (
              <center>
                <Button
                  type="link"
                  text="Don't have an account? Register now"
                  onClick={() => {
                    this.props.history.push("/signup");
                  }}
                />{" "}
              </center>
            )} */}
            <br />
            <br />
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
