import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import Pagination from "react-js-pagination";
import Loader from "../Loader";
import * as constatnts from "../../Constatnts";
import { isNullOrUndefined } from "util";
// import * as actionTypes from '../../actionTypes'

class CustomerOutStandingLedgerReport extends Component {
  constructor() {
    super();
    this.state = {
      customers: [],
      showFilters: false,
      searchText: "",
      searchOutstandingFrom: "",
      searchOutstandingTo: "",
      activePage: 1,
      itemsCountPerPage: 20,
      totalItemsCount: 0,
      recordFrom: 0,
      paginationStatus: "No records to display",
      searchActiveInactiveAllRecords: "active",
      loading: true,
      exportCalled: false,
    }; //
    this.loadCustomers = this.loadCustomers.bind(this);
    this.showHideFilters = this.showHideFilters.bind(this);
    this.onExportClick = this.onExportClick.bind(this);
    this.showPaginationStatus = this.showPaginationStatus.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onClearSearch = this.onClearSearch.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    this.loadCustomers(
      this.state.recordFrom,
      this.state.searchActiveInactiveAllRecords,
      this.state.searchText,
      this.state.searchOutstandingFrom,
      this.state.searchOutstandingTo
    );
  }

  loadCustomers(
    from,
    option,
    searchText,
    searchOutstandingFrom,
    searchOutstandingTo
  ) {
    this.setState({ loading: true });
    this.props
      .getCustomerOutStandingLedger(
        this.props.user.user.RegistrationID,
        this.props.user.store.key,
        from,
        this.state.itemsCountPerPage,
        option,
        searchText,
        searchOutstandingFrom,
        searchOutstandingTo
      )
      .then((result) => {
        this.setState({
          customers: result.searchResult,
          totalItemsCount: result.totalItemsCount,
          loading: false,
        });
        this.showPaginationStatus();
      });
  }

  showHideFilters() {
    let showFilters = this.state.showFilters;
    this.setState({ showFilters: !showFilters });
  }

  onSearchClick() {
    this.loadCustomers(
      this.state.recordFrom,
      this.state.searchActiveInactiveAllRecords,
      this.state.searchText,
      this.state.searchOutstandingFrom,
      this.state.searchOutstandingTo
    );
  }

  onClearSearch() {
    this.setState({
      recordFrom: 0,
      searchActiveInactiveAllRecords: "active",
      searchText: "",
      searchOutstandingFrom: "",
      searchOutstandingTo: "",
    });
    this.loadCustomers(0, "active", "", "", "");
  }

  handleKeyPress(e) {
    if (e.key === "Enter") {
      this.onSearchClick();
    }
  }

  onExportClick() {
    this.setState({ exportCalled: true });
    this.props
      .getCustomerOutStandingLedger(
        this.props.user.user.RegistrationID,
        this.props.user.store.key,
        0,
        10000,
        this.state.searchActiveInactiveAllRecords,
        this.state.searchText,
        this.state.searchOutstandingFrom,
        this.state.searchOutstandingTo
      )
      .then((result) => {
        // console.log(result.searchResult);
        if (
          isNullOrUndefined(result.searchResult) ||
          result.searchResult.length === 0
        ) {
          this.props.getToastr("No Record Found");
          return;
        }
        let walletLedgers = [];
        result.searchResult.map((data) => {
          let walletLedger = {
            "Customer Code": data.CustomerCode,
            "Customer Name": data.FirstName,
            "PhoneNo.": !isNullOrUndefined(data.PhoneNo) ? data.PhoneNo : "",
            EmailID: !isNullOrUndefined(data.EmailID) ? data.EmailID : "",
            Gender: !isNullOrUndefined(data.Gender) ? data.Gender : "",
            "OutStanding Amount": data.OutStandingAmount,
          };
          walletLedgers.push(walletLedger);
        });
        const csvData = constatnts.objToCSV(walletLedgers);
        this.setState({ exportCalled: false });
        const csvBlob = new Blob([csvData], { type: "text/csv" });
        const url = window.URL.createObjectURL(csvBlob);
        const a = document.createElement("a");
        a.setAttribute("hidden", "");
        a.setAttribute("href", url);
        a.setAttribute("download", "OutStandingLedger.csv");
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
  }

  handlePageChange(pageNumber) {
    let recordFrom = (pageNumber - 1) * this.state.itemsCountPerPage;
    this.setState({ activePage: pageNumber, recordFrom: recordFrom });
    this.loadCustomers(
      this.state.recordFrom,
      this.state.searchActiveInactiveAllRecords,
      this.state.searchText,
      this.state.searchOutstandingFrom,
      this.state.searchOutstandingTo
    );
  }

  showPaginationStatus() {
    // console.log('this.state.recordFrom', this.state.recordFrom)
    let paginationStatus;
    if (this.state.recordFrom + 1 === this.state.totalItemsCount) {
      paginationStatus =
        "Showing " +
        this.state.totalItemsCount +
        " of " +
        this.state.totalItemsCount;
    } else if (
      this.state.recordFrom + this.state.itemsCountPerPage + 1 >
      this.state.totalItemsCount
    ) {
      paginationStatus =
        "Showing " +
        (this.state.recordFrom + 1) +
        " to  " +
        this.state.totalItemsCount +
        " of " +
        this.state.totalItemsCount;
    } else {
      paginationStatus =
        "Showing " +
        (this.state.recordFrom + 1) +
        " to  " +
        (this.state.recordFrom + this.state.itemsCountPerPage) +
        " of " +
        this.state.totalItemsCount;
    }
    this.setState({ paginationStatus: paginationStatus });
  }

  render() {
    return (
      <div className="content-wrapper">
        <section className="content">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="box-header with-border">
                  <div className="row">
                    <div className="col-md-6 col-xs-9">
                      <font style={{ fontSize: "30px" }}>
                        Customer Outstanding Ledger
                      </font>
                    </div>
                    <div className="col-md-6 col-xs-3">
                      <button
                        type="cancel"
                        className="btn btn-default pull-right btn-flat"
                        style={{ marginTop: "5px", marginLeft: "10px" }}
                        onClick={() =>
                          this.props.history.push({
                            pathname: "/home/reports/allreport",
                          })
                        }
                      >
                        <i className="fa fa-arrow-left"></i> Back
                      </button>
                    </div>
                  </div>
                </div>
                <div className="box-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>
                            Search by customer name, code, email id, contact no
                          </label>
                          <input
                            type="text"
                            onKeyPress={this.handleKeyPress}
                            autoFocus={true}
                            className="form-control input-md"
                            value={this.state.searchText}
                            onChange={(evt) =>
                              this.setState({ searchText: evt.target.value })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <label>Outstanding Amount From</label>
                          <input
                            type="number"
                            className="form-control input-md"
                            value={this.state.searchOutstandingFrom}
                            onKeyPress={this.handleKeyPress}
                            onChange={(evt) =>
                              this.setState({
                                searchOutstandingFrom: evt.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <label>Outstanding Amount To</label>
                          <input
                            type="number"
                            className="form-control input-md"
                            value={this.state.searchOutstandingTo}
                            onKeyPress={this.handleKeyPress}
                            onChange={(evt) =>
                              this.setState({
                                searchOutstandingTo: evt.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-5">
                        <button
                          type="button"
                          className="btn btn-primary btn-flat btn-md pull-right"
                          style={{ marginTop: "25px", marginLeft: "5px" }}
                          onClick={this.onSearchClick}
                        >
                          Search
                        </button>
                        <button
                          type="button"
                          className="btn btn-default btn-flat btn-md pull-right"
                          style={{ marginTop: "25px" }}
                          onClick={this.onClearSearch}
                        >
                          Clear
                        </button>
                        <button
                          className="btn btn-default btn-flat btn-md pull-right"
                          style={{ marginTop: "25px", marginRight: "5px" }}
                          onClick={this.onExportClick}
                        >
                          Export{" "}
                          <i className="glyphicon glyphicon-download-alt "></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="col-md-12">
                        {this.state.loading && (
                          <Loader show={this.state.loading} />
                        )}
                        {!this.state.loading && (
                          <div
                            style={{ width: "auto", overflowX: "auto" }}
                            className="scroll2"
                          >
                            <table className="table table-bordered table-striped">
                              <thead>
                                <tr>
                                  <th>Customer Code</th>
                                  <th>Customer Name</th>
                                  <th>Customer PhoneNo</th>
                                  <th>Customer EmailID</th>
                                  <th>Customer Gender</th>
                                  <th>Outstanding Balance</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.customers.map((customer) => (
                                  <tr>
                                    <td>{customer.CustomerCode}</td>
                                    <td>
                                      {customer.FirstName +
                                        " " +
                                        customer.LastName}
                                    </td>
                                    <td>{customer.PhoneNo}</td>
                                    <td>{customer.EmailID}</td>
                                    <td>{customer.Gender}</td>
                                    <td className="number">
                                      <Link
                                        to={{
                                          pathname:
                                            "/home/reports/outstandingledger",
                                          state: {
                                            Customer: customer,
                                          },
                                        }}
                                      >
                                        {customer.OutStandingAmount}
                                      </Link>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        )}
                        {this.state.totalItemsCount > 0 && (
                          <div
                            className="pull-left"
                            style={{ marginTop: "25px" }}
                          >
                            {this.state.paginationStatus}
                          </div>
                        )}
                        <div className="pull-right">
                          <Pagination
                            activePage={this.state.activePage} //this.state.activePage
                            itemsCountPerPage={this.state.itemsCountPerPage}
                            totalItemsCount={this.state.totalItemsCount}
                            pageRangeDisplayed={2}
                            onChange={this.handlePageChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(CustomerOutStandingLedgerReport);
