import React, { useState, useEffect, useRef, forwardRef } from "react";

const CustomAsyncTypeahead = forwardRef((props, ref) => {
  const {
    id,
    autoFocus = false,
    allowNew = false,
    isLoading = false,
    multiple = false,
    options = [],
    labelKey = "label",
    minLength = 3,
    onSearch = () => {},
    onInputChange = () => {},
    onKeyDown = () => {},
    placeholder = "Type to search...",
    filterBy = () => true,
    bsSize = "medium",
    dropup = false,
    highlightOnlyResult = false,
    selected = [],
    onChange = () => {},
    renderMenuItemChildren,
  } = props;

  const [optionsData, setOptionsData] = useState(options);
  const [inputValue, setInputValue] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [isScan, setIsScan] = useState(false); // Track scan mode
  const [scannedInput, setScannedInput] = useState(""); // Store scanned input
  const [isFocused, setIsFocused] = useState(false); // Track focus state
  const inputRef = useRef(null);
  const dropdownRef = useRef(null); // Ref for dropdown container

  // Forward ref to the input element
  useEffect(() => {
    if (ref) {
      ref.current = inputRef.current;
    }
  }, [ref]);

  // Add shortcut key listener (e.g., F1)
  useEffect(() => {
    const handleShortcutKey = (e) => {
      if (e.key === "F1") {
        e.preventDefault(); // Prevent default browser behavior
        if (inputRef.current) {
          inputRef.current.focus(); // Focus the input
        }
      }
    };

    window.addEventListener("keydown", handleShortcutKey);
    return () => {
      window.removeEventListener("keydown", handleShortcutKey);
    };
  }, []);

  // Focus the input if autoFocus is true
  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [autoFocus]);

  // Update input value when selected prop changes
  useEffect(() => {
    if (selected && selected.length > 0) {
      setInputValue(selected[0][labelKey]);
    } else {
      setInputValue("");
      setScannedInput("");
      setHighlightedIndex(-1); // Reset highlighted index
    }
  }, [selected, labelKey]);

  // Handle input change
  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    // onInputChange(value, e);

    // Reset scan mode when typing
    setIsScan(false);

    // Trigger search if input length meets the minimum requirement
    if (value.length >= minLength) {
      // onSearch(value);
      onInputChange(value, e);
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
  };

  // Handle Enter key press
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && inputValue !== "") {
      // If there is a highlighted item, select it
      if (highlightedIndex >= 0 && optionsData[highlightedIndex]) {
        const selectedOption = optionsData[highlightedIndex];
        handleOptionSelect(selectedOption);
      } else {
        // Activate scan mode and trigger scan action
        setIsScan(true);
        setScannedInput(inputValue); // Store the scanned input
        onKeyDown(e); // Notify parent component of the Enter key press
        setShowDropdown(false); // Hide dropdown in scan mode
        setHighlightedIndex(-1); // Reset highlighted index
      }
    } else {
      // Handle arrow keys for dropdown navigation
      if (e.key === "ArrowDown") {
        setHighlightedIndex((prev) =>
          Math.min(prev + 1, optionsData.length - 1)
        );
      } else if (e.key === "ArrowUp") {
        setHighlightedIndex((prev) => Math.max(prev - 1, -1));
      }
    }
  };

  // Handle option selection
  const handleOptionSelect = (option) => {
    if (multiple) {
      onChange([...selected, option]);
    } else {
      onChange([option]);
    }
    setHighlightedIndex(-1); // Reset highlighted index
    setInputValue(""); // Clear input value
    setScannedInput(""); // Clear scanned input
    setShowDropdown(false); // Hide dropdown
  };

  // Handle focus event
  const handleFocus = () => {
    setIsFocused(true);
  };

  // Handle blur event
  const handleBlur = () => {
    setIsFocused(false);
  };

  // Automatically highlight the only result if highlightOnlyResult is true
  useEffect(() => {
    if (highlightOnlyResult && options.length === 1 && !isScan) {
      setHighlightedIndex(0);
    } else {
      setHighlightedIndex(-1);
    }
    setOptionsData(options);
  }, [options, highlightOnlyResult]);

  // Scroll to the highlighted item
  useEffect(() => {
    if (dropdownRef.current && highlightedIndex >= 0) {
      const highlightedItem = dropdownRef.current.children[highlightedIndex];
      if (highlightedItem) {
        highlightedItem.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    }
  }, [highlightedIndex]);

  // Filter options based on the filterBy function
  const filteredOptions = optionsData.filter(filterBy);

  return (
    <div style={{ position: "relative", width: "100%" }}>
      {/* Input Field */}
      <input
        ref={inputRef}
        id={id}
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        onFocus={handleFocus}
        onBlur={handleBlur}
        autoComplete="off"
        style={{
          width: "100%",
          height: "46px",
          padding: bsSize === "large" ? "10px" : "8px",
          fontSize: bsSize === "large" ? "16px" : "14px",
          boxShadow: isFocused
            ? "0 0 0 0.2rem rgba(10, 171, 219, 0.25)" // Bootstrap-like focus shadow
            : "inset 0 1px 1px rgba(0, 0, 0, 0.075)",
          color: "#555",
          backgroundColor: "#fff",
          backgroundImage: "none",
          outline: 0,
          border: `1px solid ${isFocused ? "#80bdff" : "#ccc"}`, // Blue border on focus
          transition:
            "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out", // Smooth transition
        }}
      />

      {/* Loading Indicator */}
      {isLoading && (
        <ul
          style={{
            position: "absolute",
            top: dropup ? "auto" : "100%",
            bottom: dropup ? "100%" : "auto",
            left: 0,
            zIndex: 1000,
            display: showDropdown ? "block" : "none",
            float: "left",
            width: "-webkit-fill-available",
            maxHeight: "300px",
            padding: "5px 0",
            margin: "2px 0 0",
            fontSize: "14px",
            textAlign: "left",
            listStyle: "none",
            backgroundColor: "#fff",
            backgroundClip: "padding-box",
            border: "1px solid #ccc",
            border: "1px solid rgba(0, 0, 0, 0.15)",
            borderRadius: "4px",
            boxShadow: "0 6px 12px rgba(0, 0, 0, 0.175)",
            WebkitBoxShadow: "0 6px 12px rgba(0, 0, 0, 0.175)",
          }}
        >
          <li style={{ padding: "8px" }}>Loading...</li>
        </ul>
      )}

      {/* Dropdown Options */}
      {showDropdown && !isLoading && filteredOptions.length > 0 && !isScan && (
        <ul
          ref={dropdownRef} // Ref for dropdown container
          style={{
            position: "absolute",
            top: dropup ? "auto" : "100%",
            bottom: dropup ? "100%" : "auto",
            left: 0,
            zIndex: 1000,
            display: showDropdown ? "block" : "none",
            float: "left",
            minWidth: "160px",
            maxHeight: "300px",
            overflowY: "auto",
            padding: "5px 0",
            margin: "2px 0 0",
            fontSize: "14px",
            textAlign: "left",
            listStyle: "none",
            backgroundColor: "#fff",
            backgroundClip: "padding-box",
            border: "1px solid #ccc",
            border: "1px solid rgba(0, 0, 0, 0.15)",
            borderRadius: "4px",
            boxShadow: "0 6px 12px rgba(0, 0, 0, 0.175)",
            WebkitBoxShadow: "0 6px 12px rgba(0, 0, 0, 0.175)",
          }}
        >
          {filteredOptions.map((option, index) => (
            <li
              key={index}
              onClick={() => handleOptionSelect(option)}
              style={{
                padding: "8px",
                cursor: "pointer",
                borderBottom: "1px solid #eee",
                backgroundColor:
                  highlightedIndex === index ? "#f0f0f0" : "#fff",
              }}
              onMouseEnter={() => setHighlightedIndex(index)}
              onMouseLeave={() => setHighlightedIndex(-1)}
            >
              {renderMenuItemChildren ? (
                renderMenuItemChildren(option, props)
              ) : (
                <div>{option[labelKey]}</div>
              )}
            </li>
          ))}
        </ul>
      )}

      {/* No Results Message */}
      {showDropdown && !isLoading && filteredOptions.length === 0 && !isScan && (
        <ul
          style={{
            position: "absolute",
            top: dropup ? "auto" : "100%",
            bottom: dropup ? "100%" : "auto",
            left: 0,
            zIndex: 1000,
            display: showDropdown ? "block" : "none",
            float: "left",
            width: "-webkit-fill-available",
            maxHeight: "300px",
            padding: "5px 0",
            margin: "2px 0 0",
            fontSize: "14px",
            textAlign: "left",
            listStyle: "none",
            backgroundColor: "#fff",
            backgroundClip: "padding-box",
            border: "1px solid #ccc",
            border: "1px solid rgba(0, 0, 0, 0.15)",
            borderRadius: "4px",
            boxShadow: "0 6px 12px rgba(0, 0, 0, 0.175)",
            WebkitBoxShadow: "0 6px 12px rgba(0, 0, 0, 0.175)",
          }}
        >
          <li style={{ padding: "8px", color: "#888" }}>No results found.</li>
        </ul>
      )}
    </div>
  );
});

export default CustomAsyncTypeahead;
