import React, { Component } from "react";
import ReportHeader from "./Common/ReportHeader";
import ReportBody from "./Common/ReportBody";
import ReportButton from "./Common/ReportButton";
import Spinner from "../Common/Spinner";
import * as constants from "../../Constatnts";
import * as actionTypes from "../../actionTypes";
import Pagination from "react-js-pagination";
import axios from "axios";
import { Link } from 'react-router-dom';
class PurchaseProjectionReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      products: [],
      stores: [],
      vendors: [],
      storeID: "",
      vendorID: "",
      activePage: 1,
      itemsCountPerPage: 20,
      totalItemsCount: 0,
      recordFrom: 1,
      paginationStatus: "No records to display",
      selectedProducts: [], // Track selected product IDs
      selectAll: false, // Track "Select All" status
      transactionType: null
    };
    this.onStoreChange = this.onStoreChange.bind(this);
    this.onVendorChange = this.onVendorChange.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.getVendors = this.getVendors.bind(this);
    this.showPaginationStatus = this.showPaginationStatus.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.handleSelectRow = this.handleSelectRow.bind(this);
    this.generatepo = this.generatepo.bind(this);
    this.parseDeliveryDays = this.parseDeliveryDays.bind(this);
    this.formatDate = this.formatDate.bind(this);
  }

  async componentDidMount() {
    this.setState({ loading: true });
    // console.log("props",this.props);
    this.setState({ storeID: this.props.user.store.key });
    // console.log("this.props.config.TransactionTypes", this.props.config.TransactionTypes)
    // this.props.config.TransactionTypes
    let inventoryTransactionTypes = this.props.config.TransactionTypes.find(
      (f) => f.Module === "inventory" &&
        !f.IsDeleted &&
        f.TransactionType.trim() === "Purchase Order"
    );

    this.props
      .getUserStores(this.props.user.user.RegistrationID, "")
      .then((stores) => {
        this.setState({ stores: stores });
      });

    this.setState({ transactionType: inventoryTransactionTypes })

    // this.getVendors(this.props.user.user.RegistrationID, 0, 1000).then((vendors) => {
    //     // console.log("vendors", vendors);
    //     this.setState({ vendors: vendors.searchResult ? vendors.searchResult : [] });
    // });
    this.getVendors(this.props.user.store.key);

    await this.loadData(
      this.props.user.store.key,
      1,
      this.state.itemsCountPerPage
    );
  }

  async loadData(storeid, page, pageSize = 20, vendorID = "") {
    try {
      let payloadData = {
        StoreID: storeid,
        page: page,
        pageSize: pageSize,
      };

      if (vendorID !== "") {
        payloadData.VendorID = vendorID;
      }
      const response = await axios.post(
        "https://posible-reports-45orxt2wla-el.a.run.app/purchaseprojectionreport",
        payloadData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // console.log("purchase response", response);
      let data = response.data;
      if (data.Code == 0) {
        data.report.map((item) => {
          if (!item.PODate) {
            item.PODate = ""
          }
        })
        // console.log("report-->", data.report);
        this.setState({
          products: data.report,
          loading: false,
          totalItemsCount: data.totalRecords,
        });
        this.showPaginationStatus();
      }
    } catch (error) {
      console.error("Error fetching products:", error);
      this.setState({ loading: false });
    }
  }

  async exportProduct() {

    try {
      let payloadData = {
        StoreID: this.state.storeID,
      };

      if (this.state.vendorID !== "") {
        payloadData.VendorID = this.state.vendorID;
      }
      const response = await axios.post(
        "https://posible-reports-45orxt2wla-el.a.run.app/purchaseprojectionreport",
        payloadData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = response.data;
      if (data.Code == 0) {

        const products = data.report;


        const productsData = products.map((item) => {
          // Parse DeliveryDays if it's a stringified JSON-like object or array
          // let deliveryDays = item.DeliveryDays;
          // try {
          //   // Clean up the string if necessary
          //   if (deliveryDays.startsWith("{") && deliveryDays.endsWith("}")) {
          //     // Check if it's already a valid JSON object
          //     try {
          //       deliveryDays = JSON.parse(deliveryDays); // Try parsing as JSON object
          //       deliveryDays = Object.keys(deliveryDays)
          //         .filter((day) => deliveryDays[day]) // Filter days with `true` value
          //         .join(", "); // Convert to a comma-separated string
          //     } catch (error) {
          //       // If parsing fails, clean up the string and convert it into a valid JSON object
          //       deliveryDays = deliveryDays.replace(/[{}"]/g, ""); // Remove curly braces and quotes
          //       deliveryDays = deliveryDays
          //         .split(",")
          //         .map((day) => `"${day.trim()}":true`)
          //         .join(","); // Add key-value pairs
          //       deliveryDays = JSON.parse(`{${deliveryDays}}`); // Parse into an object
          //       deliveryDays = Object.keys(deliveryDays).join(", "); // Convert to a comma-separated string
          //     }
          //   } else if (deliveryDays.startsWith("[") && deliveryDays.endsWith("]")) {
          //     // Handle array format
          //     deliveryDays = JSON.parse(deliveryDays); // Parse into an array
          //     deliveryDays = deliveryDays.join(", "); // Convert array to a comma-separated string
          //   } else {
          //     // Handle invalid JSON format (e.g., "{\"Wednesday\",\"Friday\",\"Monday\"}")
          //     deliveryDays = deliveryDays.replace(/[{}"]/g, ""); // Remove curly braces and quotes
          //     deliveryDays = deliveryDays.split(",").join(", "); // Convert to comma-separated string
          //   }
          // } catch (error) {
          //   console.error("Error parsing DeliveryDays:", error);
          //   deliveryDays = item.DeliveryDays; // Fallback to the original value if parsing fails
          // }

          // Format dates to MM/DD/YYYY

          let deliveryDays = item.DeliveryDays;
          try {
            // Parse the JSON string if it's a string
            if (typeof deliveryDays === 'string') {
              deliveryDays = JSON.parse(deliveryDays);
            }

            // Extract days with true values
            if (typeof deliveryDays === 'object' && deliveryDays !== null) {
              deliveryDays = Object.keys(deliveryDays)
                .filter(day => deliveryDays[day] === true)
                .join(", ");
            } else {
              deliveryDays = String(deliveryDays); // Fallback to string representation
            }
          } catch (error) {
            console.error("Error parsing DeliveryDays:", error);
            deliveryDays = item.DeliveryDays; // Fallback to original value
          }
          const formatDate = (dateString) => {
            const date = new Date(dateString);
            const month = date.getMonth() + 1; // getMonth() returns 0-based month
            const day = date.getDate();
            const year = date.getFullYear();
            return `${month}/${day}/${year}`; // Format as MM/DD/YYYY
          };

          return {
            ProductID: item.ProductID,
            StoreID: item.StoreID,
            SKU: item.SKU,
            Name: item.Name,
            VendorID: item.VendorID,
            VendorCode: item.VendorCode,
            VendorName: item.VendorName,
            DeliveryDays: deliveryDays, // Use the formatted deliveryDays
            OrderDays: item.OrderDays,
            DailyDemand: item.ActualDailyDemand,
            MinDate: formatDate(item.MinDate),
            MaxDate: formatDate(item.MaxDate),
            Duration: item.DurationDaysSale,
            DaysSale: item.UniqueDaySale,
            // DaysSale: item.DurationDaysSale,
            Frequency: item.Frequency,
            RequirementQty: item.RequirementQty,
            LeadTime: item.LeadTime,
            StockInHand: item.StockInHand,
            ProjectedStockInHandOnDeliveryDay:
              item.ProjectedStockInHandOnDeliveryDay,
            IdealOrderQty: item.IdealOrderQty,
            StockShortageDays: item.StockShortageDays,
            MOQ: item.MOQ,
            ReOrderQty: item.ReOrderQty,
            PONumber:item.PONumber?item.PONumber:"",
            PODate: item.PODate ? formatDate(item.PODate) : "", // Assuming ProcessedOn is the PO Dat
          };
        });

        const csvData = constants.objToCSV(productsData);
        const csvBlob = new Blob([csvData], { type: "text/csv" });
        const url = window.URL.createObjectURL(csvBlob);
        const a = document.createElement("a");
        a.setAttribute("hidden", "");
        a.setAttribute("href", url);
        a.setAttribute("download", "PurchaseProjectionReport.csv");
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

      }
    } catch (error) {
      console.error("Error fetching products:", error);

    }

  }

  async onStoreChange(evt) {
    let storeID = evt.target.value;

    this.setState({
      storeID: storeID,
    });
  }
  async getVendors(storeId) {
    try {
      const response = await axios.get(
        `https://posible-reports-302355316035.asia-south1.run.app/getuniquevendor/${storeId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("vendor response", response);
      const data = response.data;
      if (data.code == 0) {
        this.setState({
          vendors: data.result,
        });
      }
    } catch (error) {
      console.error("Error fetching products:", error);
      // this.setState({ loading: false });
    }
  }

  onSearchClick() {
    this.setState({
      loading: true,
    });

    this.loadData(this.state.storeID, 1, 20, this.state.vendorID);
  }
  onVendorChange(evt) {
    let id = evt.target.value;

    this.setState({ vendorID: id });
  }

  handlePageChange(pageNumber) {
    // console.log(`active page is ${pageNumber}`);
    let recordFrom = (pageNumber - 1) * this.state.itemsCountPerPage;
    this.setState({ activePage: pageNumber, recordFrom: recordFrom });
    this.loadData(this.state.storeID, recordFrom);
  }

  showPaginationStatus() {
    // console.log('this.state.recordFrom', this.state.recordFrom)
    let paginationStatus;
    if (this.state.recordFrom + 1 === this.state.totalItemsCount) {
      paginationStatus =
        "Showing " +
        this.state.totalItemsCount +
        " of " +
        this.state.totalItemsCount;
    } else if (
      this.state.recordFrom + this.state.itemsCountPerPage + 1 >
      this.state.totalItemsCount
    ) {
      paginationStatus =
        "Showing " +
        (this.state.recordFrom + 1) +
        " to  " +
        this.state.totalItemsCount +
        " of " +
        this.state.totalItemsCount;
    } else {
      paginationStatus =
        "Showing " +
        (this.state.recordFrom + 1) +
        " to  " +
        (this.state.recordFrom + this.state.itemsCountPerPage) +
        " of " +
        this.state.totalItemsCount;
    }
    this.setState({ paginationStatus: paginationStatus });
  }

  handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    this.setState({
      selectAll: isChecked,
      selectedProducts: isChecked
        ? this.state.products.map(product => product.ProductID)
        : []
    });
  };

  handleSelectRow = (e, productId) => {
    const isChecked = e.target.checked;
    let updatedSelectedProducts;

    if (isChecked) {
      updatedSelectedProducts = [...this.state.selectedProducts, productId];
    } else {
      updatedSelectedProducts = this.state.selectedProducts.filter(
        id => id !== productId
      );
    }

    this.setState({
      selectedProducts: updatedSelectedProducts,
      selectAll: updatedSelectedProducts.length === this.state.products.length
    });
  };
  generatepo = () => {
    // console.log("selectedProducts",this.state.selectedProducts)

    const generatepoData = this.state.products.filter(p =>
      this.state.selectedProducts.includes(p.ProductID)
    );

    this.props.history.push({
      pathname: "/home/inventory/newinventory",
      state: { transactionType: this.state.transactionType, type: actionTypes.INVENTORY_NEW, poproducts: generatepoData, vendorID: this.state.vendorID },
    });



  }

  parseDeliveryDays(deliveryDays) {
    // Handle null/undefined cases first
    if (deliveryDays == null) {
      return '';
    }

    try {
      // If it's a string, try to parse it as JSON
      if (typeof deliveryDays === 'string') {
        // Handle case where string might already be a JSON object
        if (deliveryDays.startsWith('{') && deliveryDays.endsWith('}')) {
          deliveryDays = JSON.parse(deliveryDays);
        }
        // Handle case where string is just comma-separated days
        else if (deliveryDays.includes(',')) {
          return deliveryDays; // Return as-is if already comma-separated
        }
      }

      // If we have an object, extract the days with true values
      if (typeof deliveryDays === 'object' && deliveryDays !== null) {
        return Object.keys(deliveryDays)
          .filter(day => deliveryDays[day] === true)
          .join(", ");
      }

      // For any other case, return string representation
      return String(deliveryDays);
    } catch (error) {
      console.error("Error parsing DeliveryDays:", error);
      // Return original value if parsing fails
      return typeof deliveryDays === 'string' ? deliveryDays : JSON.stringify(deliveryDays);
    }
  }

  formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = date.getMonth() + 1; // getMonth() returns 0-based month
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month}/${day}/${year}`; // Format as MM/DD/YYYY
  };

  render() {
    return (
      <div className="content-wrapper">
        <section className="content">
          <ReportHeader
            title={"Purchase Projection Report"}
            showBack={true}
            onBack={() => {
              this.props.history.push({
                pathname: "/home/reports/allreport",

              });
            }}
          />
          <ReportBody>
            <div className="row">
              <div className="col-md-2">
                <label>Store Name</label>
                <select
                  className="form-control"
                  value={this.state.storeID}
                  onChange={this.onStoreChange}
                >
                  {/* <option value="">All Stores</option> */}
                  {this.state.stores
                    .filter((e) => !e.IsDeleted)
                    .map((store) => (
                      <option value={store.key}>
                        {store.StoreCode}-{store.StoreName}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-md-2">
                <label>Vendors</label>
                <select
                  className="form-control"
                  value={this.state.vendorID}
                  onChange={this.onVendorChange}
                >
                  <option value=""></option>
                  {this.state.vendors
                    // .filter((e) => !e.IsDeleted)
                    .map((vendor) => (
                      <option value={vendor.VendorID}>
                        {vendor.VendorName}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 pull-right">
                <button
                  type="button"
                  className="btn btn-primary btn-flat btn-md pull-right"
                  style={{ marginLeft: "5px" }}
                  onClick={this.onSearchClick}
                >
                  Search
                </button>
                {(this.state.selectedProducts.length > 0 && this.state.vendorID !== "") &&
                  <button
                    type="button"
                    className="btn btn-primary btn-flat btn-md pull-right"
                    style={{ marginLeft: "5px" }}
                    onClick={() => this.generatepo()}
                  >
                    Generate PO
                  </button>}
                <ReportButton
                  type="export"
                  onClick={() => this.exportProduct()}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div
                  style={{ width: "auto", overflowX: "auto" }}
                  className="scroll2"
                >
                  {this.state.loading && <Spinner />}
                  <table className="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th>

                          <input
                            type="checkbox"
                            checked={this.state.selectAll}
                            onChange={this.handleSelectAll}
                          />
                        </th>
                        <th>SKU</th>
                        <th>Name</th>
                        <th>Vendor Code</th>
                        <th>Vendor Name</th>
                        <th>Delivery Days</th>
                        <th>Daily Demand</th>
                        {/* <th>Min Date</th>
                        <th>Max Date</th>
                        <th>Days Sale</th>
                        <th>Frequency</th>
                        <th>Requirement Qty</th> */}
                        <th>Stock In Hand</th>
                        <th>Lead Time</th>

                        {/* <th>Projected Stock in Hand on Delivery Day</th>
                        <th>Ideal Order Qty</th> */}
                        <th>Stock Shortage Days</th>
                        <th>MOQ</th>
                        <th>Order Days</th>
                        <th>Re order Qty</th>
                        <th>PONumber</th>
                        <th>PO Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.products.map((product, index) => (
                        <tr key={index}>
                          <td>
                            {product.PODate == "" && (
                              <input
                                type="checkbox"
                                checked={this.state.selectedProducts.includes(product.ProductID)}
                                onChange={(e) => this.handleSelectRow(e, product.ProductID)}
                              />)}
                          </td>
                          <td>{product.SKU}</td>
                          <td>{product.Name}</td>
                          <td>{product.VendorCode}</td>
                          <td>{product.VendorName}</td>
                          <td>{this.parseDeliveryDays(product.DeliveryDays)}</td>
                          <td>{product.ActualDailyDemand}</td>
                          {/* 
                          <td>{product.DailyDemand}</td>
                          <td>{product.MinDate}</td>
                          <td>{product.MaxDate}</td>
                          <td>{product.DurationDaysSale}</td>
                          <td>{product.Frequency}</td>
                          <td>{product.RequirementQty}</td> */}
                          <td>{product.StockInHand}</td>
                          <td>{product.LeadTime}</td>

                          {/* <td>{product.ProjectedStockInHandOnDeliveryDay}</td>
                          <td>{product.IdealOrderQty}</td>*/}
                          <td>{product.StockShortageDays}</td>
                          <td>{product.MOQ}</td>
                          <td>{product.OrderDays}</td>
                          <td>{product.ReOrderQty}</td>
                          <td>
                            {product.PONumber !== null && <Link
                              to={{
                                pathname:
                                  `/home/inventory/viewinventory/${product.POId}`,
                                state: {
                                 backPath:"/home/reports/purchaseprojectionreport"
                                },
                              }}
                            >
                              <span>
                                {product.PONumber}
                              </span>
                            </Link>}

                          </td>
                          <td>{product.PODate != "" ? this.formatDate(product.PODate) : ""}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {this.state.totalItemsCount > 0 && (
              <div className="pull-left" style={{ marginTop: "25px" }}>
                {this.state.paginationStatus}
              </div>
            )}
            <div className="pull-right">
              <Pagination
                activePage={this.state.activePage} //this.state.activePage
                itemsCountPerPage={this.state.itemsCountPerPage}
                totalItemsCount={this.state.totalItemsCount}
                pageRangeDisplayed={2}
                onChange={this.handlePageChange}
              />
            </div>
          </ReportBody>
        </section>
      </div>
    );
  }
}

export default PurchaseProjectionReport;
