import React, { Component } from "react";
import "./WidgetPincodeProductDashboard.css";
import axios from "axios";
class WidgetPincodeProductDashboard extends Component {
  constructor({ stores }) {
    super();
    this.state = {
      stores: stores,
      result: [],
    };
  }

  async componentDidMount() {
    // console.log("WidgetPincodeProductDashboard componentDidMount");
    this.loadData(this.state.stores, this.state.fromDate, this.state.toDate);
  }

  async loadData(stores) {
    // console.log("stores", stores);
    let storeids = [];
    stores.map((s) => {
      if (s.key !== "") {
        storeids.push(s.key);
      }
    });
    // console.log(storeids);
    // let result = await getPincodeProductDashboard(storeids);

    let result = await axios.post(
      "https://backend-197598313260.asia-south1.run.app/v1/reports/pincode/products/dashboard",
      {
        storeIds: storeids,
      },
      {
        headers: {
          "Content-Type": "application/json", // Explicitly set the content type
        },
      }
    );
    // console.log("WidgetPincodeProductDashboard result", result);
    let tmp = [];
    if (
      result &&
      result.data &&
      result.data.result &&
      result.data.result.length > 0
    ) {
      tmp = result.data.result;
    }
    // console.log(tmp);
    this.setState({ result: tmp });
  }

  componentWillReceiveProps({ stores }) {
    // console.log("WidgetPincodeProductDashboard componentWillReceiveProps");

    this.loadData(stores);
  }

  render() {
    return (
      <div className="WidgetPincodeProductDashboard-box ">
        <div className="WidgetPincodeProductDashboard-box-header">
          <div className="WidgetPincodeProductDashboard-box-title left">
            Product Funnel
          </div>
        </div>
        <div className="WidgetPincodeProductDashboard-box-body">
          <table className="table table-bordered table-striped">
            <thead>
              <tr>
                <th rowSpan={2}>Store</th>
                <th rowSpan={2}>GCS Count</th>
                <th rowSpan={2}>Total Items with Inventory Activity</th>
                <th rowSpan={2}>Found in GC Count</th>
                <th colSpan={7}>Found in GC (Bifurcation)</th>
                {/* <th colSpan={8}>Found in GC</th> */}
                {/* <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th> */}
              </tr>
              <tr>
                {/* <th>GC Count</th> */}
                <th>Reported Items</th>
                <th>Manual OOS</th>
                <th>OOS (0)</th>
                <th>OOS (-ve)</th>
                {/* <th>Other Issue</th> */}
                <th>One PSIN Live</th>
                <th>Active PSINs</th>
                <th>Active PSINs - In stock</th>
              </tr>
            </thead>
            <tbody>
              {this.state.result.map((m) => (
                <tr>
                  <td>{m.StoreName}</td>
                  <td className="align-right">{m.TotalGCSCount}</td>
                  <td className="align-right">{m.TotalInventoryCount}</td>
                  <td className="align-right">{m.TotalGcCount}</td>
                  <td className="align-right">
                    {m.IssueReportedInProductCount}
                  </td>
                  <td className="align-right">{m.ManualOutOfStockCount}</td>
                  <td className="align-right">{m.ZeroInventoryCount}</td>
                  <td className="align-right">{m.NegativeInventoryCount}</td>
                  {/* <td className="align-right">
                    {Number(
                      m.ProductNotFoundCount ? m.ProductNotFoundCount : 0
                    ) +
                      Number(
                        m.PSINNotMappedInProductCount
                          ? m.PSINNotMappedInProductCount
                          : 0
                      )}
                  </td> */}

                  <td className="align-right">{m.OnePSINIsLiveCount}</td>
                  <td className="align-right">{m.ActivePsinCount}</td>
                  <td className="align-right">
                    {m.InstockGreaterThanZeroCount}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default WidgetPincodeProductDashboard;
