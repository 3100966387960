import React, { Component } from "react";
import ReportHeader from "./Common/ReportHeader";
import ReportBody from "./Common/ReportBody";
import ReportButton from "./Common/ReportButton";
import Spinner from "../Common/Spinner";
import * as constants from "../../Constatnts";
import axios from "axios";

class PincodeSoldOutOfStock extends Component {
  constructor(props) {
    super();
    this.state = {
      loading: false,
      products: [],
    };
    this.loadData = this.loadData.bind(this);
  }

  async componentDidMount() {
    this.setState({ loading: true });
    await this.loadData();
    this.setState({ loading: false });
  }

  async loadData() {
    let params = { StoreID: this.props.user.store.key };
    if (this.props.type == "onlineorders") {
      params.ShowOnlineOrders = true;
    }
    let result = await axios.post(
      "https://backend-197598313260.asia-south1.run.app/v1/reports/pincode/sold/products/dashboard",
      params
    );
    // console.log(result.data);
    let products = result.data.response;
    products.sort((a, b) => b.Qty - a.Qty);
    this.setState({
      products: products,
    });
  }

  async exportProduct() {
    const productsData = this.state.products.map((item) => ({
      SKU: item.SKU,
      BarCode: item.Barcode,
      Name: item.Name,
      Category: item.Category,
      SubCategory: item.SubCategory,
      Brand: item.Brand,
      MRP: item.MRP,
      RetailPrice: item.RetailPrice,
      SoldQty: item.Qty,
      InStockQty: item.InStockQty,
    }));

    const csvData = constants.objToCSV(productsData);
    const csvBlob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "ossproductssold.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  render() {
    return (
      <div className="content-wrapper">
        <section className="content">
          <ReportHeader
            title={
              this.props.type === "onlineorder"
                ? "Products Sold Online (Last 45 Days) and Currently Out of Stock"
                : "Products Sold (Last 45 Days) and Currently Out of Stock"
            }
            showBack={true}
            onBack={() =>
              this.props.history.push({ pathname: "/home/reports/allreport" })
            }
          />
          <ReportBody>
            <div className="row">
              <div className="col-md-6 pull-right">
                <ReportButton
                  type="export"
                  onClick={() => this.exportProduct()}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                {this.state.loading && <Spinner />}
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>SKU</th>
                      <th>Barcode</th>
                      <th>Name</th>
                      <th>Category</th>
                      <th>SubCategory</th>
                      <th>Brand</th>
                      <th>MRP</th>
                      <th>Retail Price</th>
                      <th>Sold Qty</th>
                      <th>In Stock Qty</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.products.map((m, index) => (
                      <tr key={index}>
                        <td>{m.SKU}</td>
                        <td>{m.Barcode}</td>
                        <td>{m.Name}</td>
                        <td>{m.Category}</td>
                        <td>{m.SubCategory}</td>
                        <td>{m.Brand}</td>
                        <td style={{ textAlign: "right" }}>{m.MRP}</td>
                        <td style={{ textAlign: "right" }}>{m.RetailPrice}</td>
                        <td style={{ textAlign: "right" }}>{m.Qty}</td>
                        <td style={{ textAlign: "right" }}>{m.InStockQty}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </ReportBody>
        </section>
      </div>
    );
  }
}

export default PincodeSoldOutOfStock;
