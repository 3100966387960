import React, { state, useEffect, useState, Component } from "react";
import Dropzone from "react-dropzone";
import Button from "../2/Controls/Button";
import * as constatnts from "../../Constatnts";
import axios from "axios";
import Spinner from "../Common/Spinner";
import Input from "../2/Controls/Input";

class ImportProduct extends Component {
  constructor() {
    super();
    this.state = {
      importAction: "New",
      newImport: false,
      stores: [],
      selectedProductImportType: "",
      selectedStoreID: "",
      selectedStoreName: "",
      importID: "",
      logs: [],
      loading: false,
      completed: false,
      importStatus: "Uploading file...",
      showModal: true,
      importFile: null,
      emailID: "",
      rows: [],
      overWriteExistingCustomerData: false,
      importFolder: "/ImportProduct/",
      columnsToCheck: [
        "SKU",
        "BarCode",
        "Brand",
        "Category",
        "SubCategory",
        "Name",
        "Description",
        "HSNSACCode",
        "ChargeTaxOnProduct",
        "MRP",
        "SupplyPrice",
        "RetailPrice",
        "Markup",
        "DiscountPercentage",
        "DiscountAmount",
        "TaxInclusive",
        "TaxInclusivePurchase",
        "TaxGroup",
        "InventoryType",
        "MinQty",
        "MaxQty",
        "ReorderQty",
        "BatchTrackingBatchNoRequired",
        "BatchTrackingMRPRequired",
        "BatchTrackingMfgDateRequired",
        "BatchTrackingExpDateRequired",
        "BatchTrackingDateFormat",
      ],
      maxRecords: 2000,
    };
    this.onFileRemove = this.onFileRemove.bind(this);
    this.onUploadClick = this.onUploadClick.bind(this);
    this.fileReader = this.fileReader.bind(this);
    this.pollUploadStatus = this.pollUploadStatus.bind(this);
    this.showStatus = this.showStatus.bind(this);
    this.onExportClick = this.onExportClick.bind(this);
    this.renderPincodeImportOptions = this.renderPincodeImportOptions.bind(
      this
    );
    this.downloadImportFormat = this.downloadImportFormat.bind(this);
  }

  async componentDidMount() {
    let maxRecords = 2000;

    let newImport = false;
    if (this.props.registration.registration.PincodeNewImport) {
      newImport = true;
      maxRecords = 10000;
    }

    let stores = await this.props.getUserStores(
      this.props.user.user.RegistrationID,
      this.props.user.auth.uid
    );

    this.setState({
      emailID: this.props.user.auth.email,
      stores: stores,
      newImport: newImport,
      maxRecords: maxRecords,
    });
  }

  downloadCSV = (data, fileName) => {
    const csvData = constatnts.objToCSV(data);
    const csvBlob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  onExportClick() {
    let data = [];
    let fileName = "";

    for (let index = 0; index < this.state.logs.length; index++) {
      const element = this.state.logs[index];
      let rec = {
        ExcelRowNo: element.ExcelRowNo,
        SKU: element.SKU,
        BarCode: element.BarCode,
        Brand: element.Brand,
        Category: element.Category,
        SubCategory: element.SubCategory,
        Name: element.Name,
        HSNSACCode: element.HSNSACCode,
        ChargeTaxOnProduct: element.ChargeTaxOnProduct,
        MRP: element.MRP,
        SupplyPrice: element.SupplyPrice,
        RetailPrice: element.RetailPrice,
        Markup: element.Markup,
        DiscountPercentage: element.DiscountPercentage,
        DiscountAmount: element.DiscountAmount,
        TaxInclusive: element.TaxInclusive,
        TaxGroup: element.TaxGroup,
        InventoryType: element.InventoryType,
        MinQty: element.MinQty,
        MaxQty: element.MaxQty,
        ReorderQty: element.ReorderQty,
        BatchTrackingBatchNoRequired: element.BatchTrackingBatchNoRequired,
        BatchTrackingMRPRequired: element.BatchTrackingMRPRequired,
        BatchTrackingMfgDateRequired: element.BatchTrackingMfgDateRequired,
        BatchTrackingExpDateRequired: element.BatchTrackingExpDateRequired,
        BatchTrackingDateFormat: element.BatchTrackingDateFormat,
        OpeningStock: element.OpeningStock,
        Status: element.Status,
        Error: element.Error,
      };
      if (element.ProductID) {
        rec.ProductID = element.ProductID;
      }
      data.push(rec);
    }
    fileName = "ProductImportLog.csv";
    this.setState({ exportCalled: false });
    this.downloadCSV(data, fileName);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    this.timer = null;
  }

  onFileRemove() {
    this.setState({ importFile: null });
  }

  async onUploadClick() {
    if (!this.state.importFile) {
      alert("Please select file to import");
      return;
    }
    let rows = this.state.rows;
    if (rows.length - 1 > this.state.maxRecords) {
      alert(this.state.maxRecords + " rows allowed.");
      return;
    }
    let columns = rows[0].split(",");
    let columnsToCheck = this.state.columnsToCheck;
    let f = this.state.importFolder;
    let error = "";
    for (var i = 0; i < columnsToCheck.length; i++) {
      let columnFound = false;
      for (var j = 0; j < columns.length; j++) {
        if (columnsToCheck[i] === columns[j]) {
          columnFound = true;
          break;
        }
      }
      if (!columnFound) {
        error = error + columnsToCheck[i] + " missing.";
      }
    }
    if (error !== "") {
      alert("Invalid file format." + error);
      return;
    }
    this.setState({ loading: true });
    let uploadPath =
      this.props.registration.registration.StorageFolder +
      f +
      this.props.user.user.RegistrationID +
      "_" +
      Math.floor(Math.random() * 100000 + 1);
    this.setState({ loading: true });
    try {
      this.props
        .uploadFileToServer(this.state.importFile, uploadPath)
        .then(async (downloadURL) => {
          if (this.state.newImport) {
            const response = await axios.post(
              "https://import-engine-891778245735.us-central1.run.app/catalog/import/products/upload",
              {
                fileName: this.state.importFile.name,
                RegistrationID: this.props.user.user.RegistrationID,
                filePath: uploadPath + "/" + this.state.importFile.name,
                StoreID: this.props.user.store.key,
                ActionBy: this.props.user.user.Name,
                ActionByEmailID: this.props.user.user.EmailID,
                ActionByUID: this.props.user.user.UID,
                importType: "product",
                emailID: this.state.emailID,
                productImportType: "5",
                forStoreID: this.props.user.store.key,
                forStore: this.props.user.store.StoreName,
                Perform: this.state.importAction,
              }
            );
            this.setState({ importID: response.data.importID });
            this.pollUploadStatus();
            // console.log("newImport response", JSON.stringify(response));
          } else {
            const formData = new FormData();
            formData.append("file", this.state.importFile);
            formData.append(
              "filePath",
              uploadPath + "/" + this.state.importFile.name
            );
            formData.append("storageFolder", uploadPath);
            formData.append(
              "RegistrationID",
              this.props.user.user.RegistrationID
            );
            formData.append("StoreID", this.props.user.store.key);
            formData.append("ActionBy", this.props.user.user.Name);
            formData.append("ActionByEmailID", this.props.user.user.EmailID);
            formData.append("ActionByUID", this.props.user.user.UID);
            formData.append("importType", "product");
            formData.append("emailID", this.state.emailID);
            formData.append("productImportType", "5");
            formData.append(
              "forStoreID",
              this.state.selectedProductImportType === "3"
                ? ""
                : this.state.selectedStoreID
            );
            formData.append("forStore", this.state.selectedStoreName);
            const response = await axios.post(
              "https://backend-o3dyulj4pa-el.a.run.app/v1/uploadFileToServer",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data", // Set the content type to multipart/form-data
                },
              }
            );
            this.setState({ importID: response.data.id });
            this.pollUploadStatus();
          }
        });
    } catch (error) {
      this.setState({ loading: false });
    }
  }

  pollUploadStatus() {
    this.setState({ importStatus: "Processing file.." });
    this.timer = setInterval(() => this.showStatus(), 30000);
  }

  async showStatus() {
    // console.log("Polling Import Status...this.state.importID");
    if (this.state.newImport) {
      // https://import-engine-891778245735.us-central1.run.app/catalog/import/products/678ce0f39fee23f5f8e7b37e
      const response = await axios.get(
        "https://import-engine-891778245735.us-central1.run.app/catalog/import/products/" +
          this.state.importID
      );
      // console.log("showStatus response", JSON.stringify(response));
      if (
        response.data &&
        response.data.importLogs &&
        response.data.importLogs.Status &&
        response.data.importLogs.Status.toLowerCase() === "completed"
      ) {
        this.setState({
          loading: false,
          completed: true,
          importStatus: "Import completed",
        });
        this.props.onImportCompleted();
        const responseLogs = await axios.get(
          "https://import-engine-891778245735.us-central1.run.app/catalog/import/products/" +
            this.state.importID +
            "/logs"
        );
        // console.log("showStatus responseLogs", JSON.stringify(responseLogs));
        this.setState({ logs: responseLogs.data.importProduct });
        clearInterval(this.timer);
        this.timer = null;
      }
    } else {
      this.props.getImportDetails(this.state.importID).then((data) => {
        if (data.Status.toLowerCase() === "completed") {
          this.setState({
            loading: false,
            completed: true,
            downloadLink: data.DownloadLink,
            importStatus: "Import completed",
          });
          //get logs for download
          this.props.onImportCompleted();
          this.props.getImportLogs(this.state.importID).then((logs) => {
            this.setState({ logs: logs });
            clearInterval(this.timer);
            this.timer = null;
          });
        } else {
          this.setState({ importStatus: "Processing file.." });
        }
      });
    }
  }

  onFileSelect(acceptedFiles, rejectedFiles) {
    // console.log('acceptedFiles',acceptedFiles)
    // return
    if (acceptedFiles.length > 0) {
      if (acceptedFiles[0].size / 1024 > 6000) {
        alert("File size should not be more than 6 MB");
        return;
      }
      let ext = acceptedFiles[0].name.substring(
        acceptedFiles[0].name.length - 3,
        acceptedFiles[0].name.length
      );
      if (ext !== "csv") {
        alert("Invalid file format");
        return;
      }
      this.setState({ importFile: acceptedFiles[0] });
      const reader = new FileReader();
      reader.readAsText(this.state.importFile);
      reader.onload = this.fileReader;
    } else {
      alert("No file selected");
    }
  }

  fileReader(e) {
    let rows = e.target.result.split("\r");
    this.setState({ rows: rows });
  }

  downloadImportFormat() {
    let importTemplate = [];
    importTemplate.push({
      SKU: "",
      BarCode: "",
      Brand: "",
      Category: "",
      SubCategory: "",
      Name: "",
      Description: "",
      HSNSACCode: "",
      ChargeTaxOnProduct: "",
      MRP: "",
      SupplyPrice: "",
      RetailPrice: "",
      Markup: "",
      DiscountPercentage: "",
      DiscountAmount: "",
      TaxInclusive: "",
      TaxInclusivePurchase: "",
      TaxGroup: "",
      InventoryType: "",
      MinQty: "",
      MaxQty: "",
      ReorderQty: "",
      BatchTrackingBatchNoRequired: "",
      BatchTrackingMRPRequired: "",
      BatchTrackingMfgDateRequired: "",
      BatchTrackingExpDateRequired: "",
      BatchTrackingDateFormat: "",
      OpeningStock: "",
    });
    if (this.state.importAction.toLowerCase() === "update") {
      importTemplate[0].ProductID = "";
    }
    const csvData = constatnts.objToCSV(importTemplate);
    this.setState({ loading: false, exportCalled: false });
    const csvBlob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "ProductImport.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  renderPincodeImportOptions() {
    return (
      <React.Fragment>
        {!this.state.loading && !this.state.completed && (
          <React.Fragment>
            <div className="info">
              <div className="info-header">Info</div>
              <div className="info-text">
                - Maximum of {this.state.maxRecords} rows allowed per file
                upload.
              </div>
              <div className="info-text">
                - Click here to download import template.{" "}
                <a
                  // href={
                  //   "https://backend-o3dyulj4pa-el.a.run.app/v1/importExcelFile"
                  // }
                  onClick={() => this.downloadImportFormat()}
                >
                  Import Template
                </a>
              </div>
            </div>
            <Input
              type="select"
              lable="Please select"
              onChange={(value) => this.setState({ importAction: value })}
              value={this.state.importAction}
            >
              <option value={"New"}>New product import</option>
              <option value={"Update"}>Update existing product details</option>
            </Input>
            <div>
              {!this.state.importFile && (
                <Dropzone onDrop={(files) => this.onFileSelect(files)}>
                  <div>
                    Drop csv file you would like to upload or click to select
                    files to upload.
                  </div>
                </Dropzone>
              )}

              {this.state.importFile && (
                <div className="form-group">
                  <ul className="mailbox-attachments clearfix">
                    <li>
                      <span className="mailbox-attachment-icon has-img"></span>
                      <div className="mailbox-attachment-info">
                        <span className="mailbox-attachment-name">
                          {this.state.importFile.size.length > 20
                            ? this.state.importFile.size.substr(0, 20) + "..."
                            : this.state.importFile.name}
                        </span>
                        <span className="mailbox-attachment-size">
                          {Math.round(this.state.importFile.size / 1024)} KB
                          <a
                            onClick={this.onFileRemove}
                            className="btn btn-default btn-xs pull-right"
                          >
                            <i className="fa fa-trash"></i>
                          </a>
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        <div className="import-header">
          <div>{"Import Product (s)"}</div>
        </div>
        <div className="import-content">
          {this.renderPincodeImportOptions()}

          {this.state.loading && !this.state.completed && (
            <React.Fragment>
              <div>
                <h3>{this.state.importStatus}</h3>
              </div>
              {this.state.loading && (
                <div>
                  <Spinner hideOverlay={true} text="Importing data ..." />
                </div>
              )}
              <div>
                Import processing is under process. You can choose to close this
                window. Import completion status can be viewed in Import Log.
              </div>
            </React.Fragment>
          )}

          {this.state.completed && (
            <div>
              <h3>Import completed</h3>
              <button
                className="btn btn-default btn-flat btn-md pull-left"
                onClick={this.onExportClick}
              >
                Download import processing file{" "}
                <i className="glyphicon glyphicon-download-alt "></i>
              </button>
            </div>
          )}
        </div>

        {!this.state.loading && !this.state.completed && (
          <div className="import-footer">
            <div className="left">
              <Button
                type="secondary"
                text="Cancel"
                onClick={this.props.onClose}
              />
            </div>
            <div className="right">
              <Button
                type="primary"
                text="Upload"
                onClick={this.onUploadClick}
              />
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default ImportProduct;
