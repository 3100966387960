import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import * as constatnts from "../../Constatnts";
// import { isNullOrUndefined } from 'util';
import ReactHtmlParser from "react-html-parser";
import Loader from "../Loader";

class InventoryHistory extends Component {
  constructor() {
    super();
    this.state = {
      products: [],
      stores: [],
      storeList: [],
      searchText: "",
      searchStoreID: "",
      searchCategory: "",
      searchSubCategory: "",
      searchProductSKU: "",
      searchProductName: "",
      searchBrand: "",
      exportCalled: false,
      loading: true,
      searchTransactionFromDate: new Date().toISOString().split("T")[0],
      searchTransactionToDate: new Date().toISOString().split("T")[0],
    };
    this.loadInventoryHistory = this.loadInventoryHistory.bind(this);
    this.onClearSearch = this.onClearSearch.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.onExportClick = this.onExportClick.bind(this);
    this.onStoreChange = this.onStoreChange.bind(this);
    this.loadInventoryHistoryData = this.loadInventoryHistoryData.bind(this);
  }

  componentDidMount() {
    this.setState({ storeID: this.props.user.store.key });
    let storeList = [];
    storeList.push({ key: this.props.user.store.key });

    this.props
      .getUserStores(this.props.user.user.RegistrationID, "")
      .then((stores) => {
        this.setState({ stores: stores });
      });
    this.setState({ storeList: storeList, storeID: this.props.user.store.key });
    this.loadInventoryHistory(
      storeList,
      this.state.searchTransactionFromDate,
      this.state.searchTransactionToDate,
      this.state.searchCategory,
      this.state.searchSubCategory,
      this.state.searchProductSKU,
      this.state.searchProductName,
      this.state.searchBrand
    );
  }

  async loadInventoryHistory(
    storeList,
    searchTransactionFromDate,
    searchTransactionToDate,
    searchCategory,
    searchSubCategory,
    searchProductSKU,
    searchProductName,
    searchBrand
  ) {
    this.setState({ loading: true });

    let finalProducts = await this.loadInventoryHistoryData(
      storeList,
      searchTransactionFromDate,
      searchTransactionToDate,
      searchCategory,
      searchSubCategory,
      searchProductSKU,
      searchProductName,
      searchBrand
    );

    //console.log("finalProducts",JSON.stringify(finalProducts));

    var col = [];
    for (var i = 0; i < finalProducts.length; i++) {
      for (var key in finalProducts[i]) {
        if (
          col.indexOf(key) === -1 &&
          key !== "Inward" && key !== "Outward" && key !== "Opening Stock"
        ) {
          // console.log(key)
          col.push(key);
        }
      }
    }

    var result = "<table class='table table-bordered'><thead><tr>";
    for (var i = 0; i < col.length; i++) {
      result += "<th>";
      result += col[i];
      result += "</th>";
    }
    result += "</tr></thead>";

    // ADD JSON DATA TO THE TABLE AS ROWS.
    result += "<tbody>";
    for (var i = 0; i < finalProducts.length; i++) {
      result += "<tr>";
      for (var j = 0; j < col.length; j++) {
        result += "<td>";
        result +=
          finalProducts[i][col[j]] === undefined ? 0 : finalProducts[i][col[j]];
        result += "</td>";
      }
      result += "</tr>";
    }

    // ADD Totals
    result += "<tr><td style='font-weight:bold'>Totals</td>";
    result += "<td style='font-weight:bold'>-</td>";
    result += "<td style='font-weight:bold'>-</td>";
    result += "<td style='font-weight:bold'>-</td>";
    result += "<td style='font-weight:bold'>-</td>";
    for (var j = 5; j < col.length; j++) {
      let total = 0;
      for (var i = 0; i < finalProducts.length; i++) {
        total +=
          finalProducts[i][col[j]] === undefined
            ? 0
            : Number(finalProducts[i][col[j]]);
      }
      result += "<td style='font-weight:bold'>";
      result += Number(total).toFixed(2);
      result += "</td>";
    }
    result += "</tr>";

    result += "</tbody>";
    result += "</table>";
    this.setState({ finalTable: result, loading: false });
  }

  async loadInventoryHistoryData(
    storeList,
    searchTransactionFromDate,
    searchTransactionToDate,
    searchCategory,
    searchSubCategory,
    searchProductSKU,
    searchProductName,
    searchBrand
  ) {
    let transactionTypes = await this.props.config.TransactionTypes.filter(
      (f) => f.UpdateStockChecked === true && !f.IsDeleted
    );

    // //Getting all non-deleted products
    // let products = await this.props.getInventoryHistoryProducts(storeList, searchCategory, searchSubCategory, searchProductSKU, searchProductName, searchBrand)
    //     .then((result) => {
    //         return result.searchResult;
    //     });
    // console.log("products", products);

    let result = await this.props.getActiveProductsForInventoryHistory(
      this.props.user.user.RegistrationID,
      "",
      this.props.user.store.key,
      searchCategory,
      searchSubCategory,
      searchProductSKU,
      searchProductName,
      searchBrand
    );
    let products = result.searchResult;
    let scrollID = result.scrollID;
    for (let index = 1; index < result.totalItemsCount / 2000; index++) {
      result = await this.props.getActiveProductsForInventoryHistory(
        this.props.user.user.RegistrationID,
        scrollID,
        this.props.user.store.key
      );
      result.searchResult.forEach((element) => {
        products.push(element);
      });
    }

    let productIDs = [];
    for (let i = 0; i < products.length; i++) {
      const prod = products[i];
      productIDs.push(prod.key);
    }

    let openingInventoryHistoryList = await this.props
      .getOpeningStockFromInventoryHistory(storeList, productIDs)
      .then((result) => {
        return result.searchResult;
      });
    console.log("openingInventoryHistoryList", openingInventoryHistoryList);

    let openingStockUpdateList = await this.props
      .getStockUpdateFromInventoryHistory(
        storeList,
        searchTransactionFromDate,
        productIDs
      )
      .then((result) => {
        return result.searchResult;
      });

    console.log("openingStockUpdateList", openingStockUpdateList);

    let openingDenomSalesHistoryList = await this.props
      .getOpeningStockFromDenomSales(
        storeList,
        searchTransactionFromDate,
        productIDs
      )
      .then((result) => {
        return result.searchResult;
      });
    console.log("openingDenomSalesHistoryList", openingDenomSalesHistoryList);

    let openingDenomInventoryHistoryList = await this.props
      .getOpeningStockFromDenomInventory(
        storeList,
        searchTransactionFromDate,
        searchCategory,
        searchSubCategory,
        searchProductSKU,
        searchProductName,
        searchBrand
      )
      .then((result) => {
        return result.searchResult;
      });
    console.log(
      "openingDenomInventoryHistoryList",
      openingDenomInventoryHistoryList
    );

    let inventoryHistoryList = await this.props
      .getInventoryHistoryReport(
        storeList,
        searchTransactionFromDate,
        searchTransactionToDate,
        productIDs
      )
      .then((result) => {
        return result.searchResult;
      });
    // console.log("inventoryHistoryList", inventoryHistoryList);

    let denomSalesHistoryList = await this.props
      .getDenomSalesHistoryReport(
        storeList,
        searchTransactionFromDate,
        searchTransactionToDate,
        productIDs
      )
      .then((result) => {
        return result.searchResult;
      });
    // console.log("denomSalesHistoryList", denomSalesHistoryList);

    // let denomInventoryHistoryList = await this.props.getDenomInventoryHistoryReport(storeList, searchTransactionFromDate, searchTransactionToDate, searchCategory, searchSubCategory, searchProductSKU, searchProductName, searchBrand)
    let denomInventoryHistoryList = await this.props
      .getDenomInventoryHistoryReport(
        storeList,
        searchTransactionFromDate,
        searchTransactionToDate,
        productIDs
      )
      .then((result) => {
        return result.searchResult;
      });
    // console.log("denomInventoryHistoryList", denomInventoryHistoryList);

    let openingProducts = [];

    for (let id = 0; id < openingInventoryHistoryList.length; id++) {
      const obj = openingInventoryHistoryList[id];
      let inwardQty = 0;
      let outwardQty = 0;

      let transactionType = transactionTypes.filter(
        (e) => e.TransactionType === obj.transaction_type
      )[0];

      if (
        transactionType &&
        transactionType.UpdateStockInwardOrOutward === "inward"
      ) {
        inwardQty = Number(inwardQty) + Number(obj.total_quantity);
      }
      let prod = openingProducts.filter((p) => p.SKU === obj.sku)[0];
      if (!prod) {
        openingProducts.push({
          SKU: obj.sku,
          InwardQty: inwardQty,
          OutwardQty: outwardQty,
        });
      } else {
        prod.InwardQty = Number(prod.InwardQty) + Number(inwardQty);
        prod.OutwardQty = Number(prod.OutwardQty) + Number(outwardQty);
      }
    }
    //console.log("openingProducts",JSON.stringify(openingProducts));

    for (let id1 = 0; id1 < openingDenomSalesHistoryList.length; id1++) {
      let inwardQty = 0;
      let outwardQty = 0;

      const obj = openingDenomSalesHistoryList[id1];
      let transactionType = transactionTypes.filter(
        (e) => e.TransactionType === obj.transaction_type
      )[0];
      let prod = openingProducts.filter((p) => p.SKU === obj.sku)[0];

      if (!prod) {
        if (transactionType && transactionType.UpdateStockChecked) {
          outwardQty = Number(obj.total_quantity);
        }
        openingProducts.push({
          SKU: obj.sku,
          InwardQty: inwardQty,
          OutwardQty: outwardQty,
        });
      } else {
        inwardQty = Number(prod.InwardQty);
        outwardQty = Number(prod.OutwardQty);

        if (transactionType && transactionType.UpdateStockChecked) {
          outwardQty = Number(outwardQty) + Number(obj.total_quantity);
        }
        prod.InwardQty = Number(inwardQty);
        prod.OutwardQty = Number(outwardQty);
      }
    }

    // console.log("openingProducts1",JSON.stringify(openingProducts));

    for (let id2 = 0; id2 < openingDenomInventoryHistoryList.length; id2++) {
      let inwardQty = 0;
      let outwardQty = 0;
      const obj = openingDenomInventoryHistoryList[id2];
      let transactionType = transactionTypes.filter(
        (e) => e.TransactionType === obj.transaction_type
      )[0];

      let prod = openingProducts.filter((p) => p.SKU === obj.sku)[0];
      if (!prod) {
        if (
          transactionType &&
          transactionType.UpdateStockInwardOrOutward === "inward"
        ) {
          inwardQty = Number(obj.total_quantity);
        } else if (
          transactionType &&
          transactionType.UpdateStockInwardOrOutward === "outward"
        ) {
          outwardQty = Number(obj.total_quantity);
        }
        openingProducts.push({
          SKU: obj.sku,
          InwardQty: inwardQty,
          OutwardQty: outwardQty,
        });
      } else {
        inwardQty = Number(prod.InwardQty);
        outwardQty = Number(prod.OutwardQty);

        if (
          transactionType &&
          transactionType.UpdateStockInwardOrOutward === "inward"
        ) {
          inwardQty = Number(inwardQty) + Number(obj.total_quantity);
        } else if (
          transactionType &&
          transactionType.UpdateStockInwardOrOutward === "outward"
        ) {
          outwardQty = Number(outwardQty) + Number(obj.total_quantity);
        }

        prod.InwardQty = Number(inwardQty);
        prod.OutwardQty = Number(outwardQty);
      }
    }

    // console.log("openingProducts2",JSON.stringify(openingProducts));

    for (let id3 = 0; id3 < openingStockUpdateList.length; id3++) {
      let inwardQty = 0;
      let outwardQty = 0;
      const obj = openingStockUpdateList[id3];
      let transactionType = transactionTypes.filter(
        (e) => e.TransactionType === obj.transaction_type
      )[0];

      let prod = openingProducts.filter((p) => p.SKU === obj.sku)[0];
      if (!prod) {
        if (
          transactionType &&
          transactionType.UpdateStockInwardOrOutward === "inward"
        ) {
          inwardQty = Number(obj.total_quantity);
        }
        openingProducts.push({
          SKU: obj.sku,
          InwardQty: inwardQty,
          OutwardQty: outwardQty,
        });
      } else {
        inwardQty = Number(prod.InwardQty);
        outwardQty = Number(prod.OutwardQty);

        if (
          transactionType &&
          transactionType.UpdateStockInwardOrOutward === "inward"
        ) {
          inwardQty = Number(inwardQty) + Number(obj.total_quantity);
        }

        prod.InwardQty = Number(inwardQty);
        prod.OutwardQty = Number(outwardQty);
      }
    }

    // console.log("openingProducts3",JSON.stringify(openingProducts));

    for (let index = 0; index < openingProducts.length; index++) {
      const product = openingProducts[index];
      product.Opening = product.InwardQty - product.OutwardQty;
    }

    let finalProducts = [];

    for (let p = 0; p < products.length; p++) {
      const prod = products[p];

      let finalProduct = {
        Brand: prod.Brand,
        Category: prod.Category,
        "Sub Category": prod.SubCategory,
        SKU: prod.SKU,
        Name: prod.Name,
        "Retail Price": prod.RetailPrice,
        "Supply Price": prod.SupplyPrice,
        "Opening Qty": 0,
        Inward: 0,
        Outward: 0,
      };
      // console.log('transactionTypes',transactionTypes)
      for (let index = 0; index < transactionTypes.length; index++) {
        if (transactionTypes[index].TransactionType !== "Opening Stock")
          finalProduct[transactionTypes[index].TransactionType] = 0;
      }

      finalProduct["Opening Qty"] = Number(
        openingProducts.filter((o) => o.SKU === prod.key).length > 0
          ? openingProducts.filter((o) => o.SKU === prod.key)[0].Opening
          : 0
      ).toFixed(2);

      // finalProduct["Closing Qty"] = 0;
      // finalProduct["Closing Stock Value"] = 0;

      let dataList = inventoryHistoryList.filter((i) => i.sku === prod.key);
      for (let d = 0; d < dataList.length; d++) {
        let historyTransactionType = transactionTypes.filter(
          (e) => e.TransactionType === dataList[d].transaction_type
        )[0];

        if (
          historyTransactionType &&
          historyTransactionType.UpdateStockInwardOrOutward === "inward"
        ) {
          finalProduct.Inward =
            Number(finalProduct.Inward) + Number(dataList[d].total_quantity);
        }

        finalProduct[dataList[d].transaction_type] =
          Number(finalProduct[dataList[d].transaction_type]) +
          Number(dataList[d].total_quantity);
      }

      let dataList1 = denomInventoryHistoryList.filter(
        (i) => i.sku === prod.key
      );
      // console.log('dataList1',dataList1)
      for (let d1 = 0; d1 < dataList1.length; d1++) {
        if (
          transactionTypes.filter(
            (e) => e.TransactionType === dataList1[d1].transaction_type
          ).length === 0
        ) {
          continue;
        }
        let denomTransactionType = transactionTypes.filter(
          (e) => e.TransactionType === dataList1[d1].transaction_type
        )[0];
        console.log("denomTransactionType", denomTransactionType);
        if (
          denomTransactionType &&
          denomTransactionType.UpdateStockInwardOrOutward === "inward"
        ) {
          finalProduct.Inward =
            Number(finalProduct.Inward) + Number(dataList1[d1].total_quantity);
        } else if (
          denomTransactionType &&
          denomTransactionType.UpdateStockInwardOrOutward === "outward"
        ) {
          finalProduct.Outward =
            Number(finalProduct.Outward) + Number(dataList1[d1].total_quantity);
        }
        finalProduct[dataList1[d1].transaction_type] =
          Number(finalProduct[dataList1[d1].transaction_type]) +
          Number(dataList1[d1].total_quantity);
        // console.log('finalProduct[dataList1[d1].transaction_type]',finalProduct[dataList1[d1].transaction_type]);
        // console.log(' Number(finalProduct[dataList1[d1].transaction_type])', Number(finalProduct[dataList1[d1].transaction_type]));
        // console.log(' Number(dataList1[d1].total_quantity)', Number(dataList1[d1].total_quantity));
      }

      let dataList2 = denomSalesHistoryList.filter((i) => i.sku === prod.key);
      for (let d2 = 0; d2 < dataList2.length; d2++) {
        let denomSalesTransactionType = transactionTypes.filter(
          (e) => e.TransactionType === dataList2[d2].transaction_type
        )[0];

        if (
          denomSalesTransactionType &&
          denomSalesTransactionType.UpdateStockChecked
        ) {
          finalProduct.Outward =
            Number(finalProduct.Outward) + Number(dataList2[d2].total_quantity);
        }

        finalProduct[dataList2[d2].transaction_type] =
          Number(finalProduct[dataList2[d2].transaction_type]) +
          Number(dataList2[d2].total_quantity);
      }

      finalProducts.push(finalProduct);
    }

    for (let finalIndex = 0; finalIndex < finalProducts.length; finalIndex++) {
      const finalObj = finalProducts[finalIndex];
      finalObj["Closing Qty"] = Number(
        Number(finalObj["Opening Qty"]) +
          Number(finalObj.Inward) -
          Number(finalObj.Outward)
      ).toFixed(2);
      finalObj["Stock Value"] = Number(
        finalObj["Closing Qty"] * finalObj["Supply Price"]
      ).toFixed(2);
    }
    console.log("finalProducts", finalProducts);
    return finalProducts;
  }

  onClearSearch() {
    let storeList = [];
    storeList.push({ key: this.props.user.store.key });

    this.setState({
      storeList: storeList,
      searchTransactionFromDate: new Date().toISOString().split("T")[0],
      searchTransactionToDate: new Date().toISOString().split("T")[0],
      searchCategory: "",
      searchSubCategory: "",
      searchProductSKU: "",
      searchProductName: "",
      searchBrand: "",
    });
    this.loadInventoryHistory(
      storeList,
      new Date().toISOString().split("T")[0],
      new Date().toISOString().split("T")[0],
      "",
      "",
      "",
      "",
      ""
    );
  }

  async onExportClick() {
    this.setState({ loading: true });

    let finalProducts = await this.loadInventoryHistoryData(
      this.state.storeList,
      this.state.searchTransactionFromDate,
      this.state.searchTransactionToDate,
      this.state.searchCategory,
      this.state.searchSubCategory,
      this.state.searchProductSKU,
      this.state.searchProductName,
      this.state.searchBrand
    );

    const csvData = constatnts.objToCSV(finalProducts);
    this.setState({ loading: false, exportCalled: false });
    const csvBlob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "InventoryHistory.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  onSearchClick() {
    this.setState({ loading: true });
    this.loadInventoryHistory(
      this.state.storeList,
      this.state.searchTransactionFromDate,
      this.state.searchTransactionToDate,
      this.state.searchCategory,
      this.state.searchSubCategory,
      this.state.searchProductSKU,
      this.state.searchProductName,
      this.state.searchBrand
    );
  }

  handleKeyPress(e) {
    if (e.key === "Enter") {
      this.onSearchClick();
    }
  }

  onStoreChange(e) {
    let storeList = [];
    if (e.target.value !== "") {
      storeList.push({ key: e.target.value });
    } else {
      for (let i = 0; i < this.state.stores.length; i++) {
        const store = this.state.stores[i];
        storeList.push({ key: store.key });
      }
    }
    this.setState({ storeList: storeList, storeID: e.target.value });
  }

  render() {
    return (
      <div className="content-wrapper">
        <section className="content">
          <div className="row">
            <div className="col-md-12">
              <div className="box ">
                <div className="box-header with-border">
                  <div className="row">
                    <div className="col-md-6 col-xs-9">
                      <font style={{ fontSize: "30px" }}>
                        Inventory History Report
                      </font>
                    </div>
                    <div className="col-md-6 col-xs-3">
                      <button
                        type="cancel"
                        className="btn btn-default pull-right btn-flat"
                        style={{ marginTop: "5px", marginLeft: "10px" }}
                        onClick={() =>
                          this.props.history.push({
                            pathname: "/home/reports/allreport",
                          })
                        }
                      >
                        <i className="fa fa-arrow-left"></i> Back
                      </button>
                    </div>
                  </div>
                </div>
                <div className="box-body">
                  <div className="row">
                    <div className="col-md-3">
                      <label>Store Name</label>
                      <select
                        className="form-control"
                        value={this.state.storeID}
                        onChange={this.onStoreChange}
                      >
                        <option value="">All Stores</option>
                        {this.state.stores
                          .filter((e) => !e.IsDeleted)
                          .map((store) => (
                            <option value={store.key}>
                              {store.StoreCode}-{store.StoreName}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Transaction From</label>
                        <input
                          type="date"
                          className="form-control"
                          onChange={(evt) =>
                            this.setState({
                              searchTransactionFromDate: evt.target.value,
                            })
                          }
                          value={this.state.searchTransactionFromDate}
                          onKeyPress={this.handleKeyPress}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Transaction To</label>
                        <input
                          type="date"
                          className="form-control"
                          onChange={(evt) =>
                            this.setState({
                              searchTransactionToDate: evt.target.value,
                            })
                          }
                          value={this.state.searchTransactionToDate}
                          onKeyPress={this.handleKeyPress}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Brand</label>
                        <input
                          type="text"
                          className="form-control input-md"
                          value={this.state.searchBrand}
                          onChange={(evt) =>
                            this.setState({ searchBrand: evt.target.value })
                          }
                          onKeyPress={this.handleKeyPress}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Category</label>
                        <input
                          type="text"
                          className="form-control input-md"
                          value={this.state.searchCategory}
                          onChange={(evt) =>
                            this.setState({ searchCategory: evt.target.value })
                          }
                          onKeyPress={this.handleKeyPress}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Sub Category</label>
                        <input
                          type="text"
                          className="form-control input-md"
                          value={this.state.searchSubCategory}
                          onChange={(evt) =>
                            this.setState({
                              searchSubCategory: evt.target.value,
                            })
                          }
                          onKeyPress={this.handleKeyPress}
                        />
                      </div>
                    </div>
                    <div className="col-md-3" style1={{ padding: "0px" }}>
                      <div className="form-group">
                        <label>SKU</label>
                        <input
                          type="text"
                          className="form-control input-md"
                          value={this.state.searchProductSKU}
                          onChange={(evt) =>
                            this.setState({
                              searchProductSKU: evt.target.value,
                            })
                          }
                          onKeyPress={this.handleKeyPress}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Product Name</label>
                        <input
                          type="text"
                          className="form-control input-md"
                          value={this.state.searchProductName}
                          onChange={(evt) =>
                            this.setState({
                              searchProductName: evt.target.value,
                            })
                          }
                          onKeyPress={this.handleKeyPress}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <button
                        type="button"
                        className="btn btn-primary btn-flat btn-md pull-right"
                        style={{ marginTop: "25px", marginLeft: "5px" }}
                        onClick={this.onSearchClick}
                      >
                        Search
                      </button>
                      <button
                        type="button"
                        className="btn btn-default btn-flat btn-md pull-right"
                        style={{ marginTop: "25px" }}
                        onClick={this.onClearSearch}
                      >
                        Clear
                      </button>
                      <button
                        className="btn btn-default btn-flat btn-md pull-right"
                        style={{ marginTop: "25px", marginRight: "5px" }}
                        onClick={this.onExportClick}
                      >
                        {" "}
                        Export <i className="fa fa-download"></i>
                      </button>
                    </div>
                  </div>
                  {/* <div className="row">
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <h4><b>Total Qty: {Number(this.state.instockTotals.totalqty).toFixed(2)}</b></h4>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <h4><b>Total Purchase : {Number(this.state.instockTotals.totalpurchase).toFixed(2)}</b></h4>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <h4><b>Total Sales : {Number(this.state.instockTotals.totalsales).toFixed(2)}</b></h4>
                                        </div>
                                    </div>
                                </div> */}
                  <br></br>
                  {this.state.loading && <Loader show={this.state.loading} />}
                  {!this.state.loading && (
                    <div
                      style={{ width: "auto", overflowX: "auto" }}
                      className="scroll2"
                    >
                      {ReactHtmlParser(this.state.finalTable)}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(InventoryHistory);
