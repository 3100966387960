import firebase, { database } from "../firebase";
import * as actionTypes from "../actionTypes";
import * as constants from "../Constatnts";

export function checkBusinessNameIsUnique(businessUniqueName) {
  return async (dispatch) => {
    let businessNameIsUnique = true;
    businessNameIsUnique = await database
      .collection("Registration")
      .where("UniqueName", "==", businessUniqueName)
      .get()
      .then((querySnapshot) => {
        let result = true;
        querySnapshot.forEach((doc) => {
          result = false;
        });
        console.log("result", result);
        return result;
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
    return businessNameIsUnique;
  };
}

export function createRegistration(reg) {
  return async (dispatch) => {
    // console.log(JSON.stringify(reg));
    return database.collection("Registration").add(reg);
    // let addMessage = firebase.functions().httpsCallable("signupProcess");
    // return addMessage({ text: reg }).then(function(result) {
    //   return result;
    //   // dispatch({ type: actionTypes.GET_REGISTRATION, result })
    // });
  };
}

export function getRegistration(registrationID) {
  return async (dispatch, getState) => {
    let registration = null;
    // registration = await checkRegistrationInLocalStorage(registrationID)
    // if(registration && getState().config.ConnectionStatus.Status === 'disconnected')
    // {
    //     console.log('registration from local storage', registration);
    //     dispatch({type:actionTypes.GET_REGISTRATION, registration });
    // }
    // else
    // {
    // alert('getRegistration start')
    return database
      .collection("Registration")
      .doc(registrationID)
      .get()
      .then((doc) => {
        // alert('getRegistration firebase start')
        registration = { key: doc.id, ...doc.data() };
        if (!registration.hasOwnProperty("DoNotAllowPasswordChange")) {
          registration.DoNotAllowPasswordChange = false;
        }
        // alert('getRegistration firebase key'+ registration.key)
        // setRegistrationInLocalStorage(registration);
        dispatch({ type: actionTypes.GET_REGISTRATION, registration });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // }
}

function checkRegistrationInLocalStorage(registrationID) {
  let registration = JSON.parse(localStorage.getItem("registration"));
  console.log("checkRegistrationInLocalStorage", registration);
  if (registration && registration.key === registrationID) {
    return registration;
  } else {
    return null;
  }
}

function setRegistrationInLocalStorage(registration) {
  console.log("setRegistrationInLocalStorage", registration);
  localStorage.setItem("registration", JSON.stringify(registration));
}

export function setLogoPath(registrationID, logoPath) {
  return (dispatch) => {
    return database
      .collection("Registration")
      .doc(registrationID)
      .update({ LogoPath: logoPath });
  };
}

export function setFavIconPath(registrationID, favIconPath) {
  return (dispatch) => {
    return database
      .collection("Registration")
      .doc(registrationID)
      .update({ FavIconPath: favIconPath });
  };
}

export function updateRegistrationSettings(registrationID, settings) {
  return (dispatch, getState) => {
    settings.ActionOn = firebase.firestore.FieldValue.serverTimestamp();
    settings.ActionBy = getState().user.user.Name;
    settings.ActionByUID = getState().user.user.UID;
    settings.ActionByEmailID = getState().user.user.EmailID;

    settings.Action = constants.AUDIT_TRAIL_EDIT;
    return database
      .collection("Registration")
      .doc(registrationID)
      .update(settings);
  };
}

export function updateRegistrationGeneralDetails(
  registrationID,
  generalDetails
) {
  // console.log(registrationID)
  // console.log(generalDetails)
  return (dispatch, getState) => {
    generalDetails.ActionOn = firebase.firestore.FieldValue.serverTimestamp();
    generalDetails.ActionBy = getState().user.user.Name;
    generalDetails.ActionByUID = getState().user.user.UID;
    generalDetails.ActionByEmailID = getState().user.user.EmailID;

    generalDetails.Action = constants.AUDIT_TRAIL_EDIT;
    return database
      .collection("Registration")
      .doc(registrationID)
      .update(generalDetails);
  };
}

export function generateOTPForRegistration(mobileNo) {
  return (dispatch) => {
    let addMessage = firebase
      .functions()
      .httpsCallable("generateOTPForRegistration");
    return addMessage({ MobileNo: mobileNo }).then(function(result) {
      if (result) {
        return true;
      } else {
        return false;
      }
    });
  };
}

export function validateOTPForRegistration(mobileNo, otp) {
  return (dispatch) => {
    let data = { MobileNo: mobileNo, OTP: otp };
    let addMessage = firebase
      .functions()
      .httpsCallable("validateOTPForRegistration");
    return addMessage({ text: data }).then(function(result) {
      console.log(result);
      if (result.data !== null) {
        return true;
      } else {
        return false;
      }
    });
  };
}

export function checkIfPhoneNoExists(contactNo) {
  return (dispatch) => {
    let contatNoExists = false;
    return database
      .collection("Registration")
      .where("ContactNo", "==", contactNo)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          contatNoExists = true;
        });
        return contatNoExists;
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function checkIfEmailIDExists(emailID) {
  return (dispatch) => {
    let emailExists = false;
    return database
      .collection("Registration")
      .where("EmailID", "==", emailID)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          emailExists = true;
        });
        return emailExists;
      })
      .catch((error) => {
        console.log(error);
      });
  };
}
