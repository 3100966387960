import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import * as constatnts from "../../Constatnts";
import Loader from "../Loader";
import axios from "axios";

class PincodeAdminDashboard extends Component {
  constructor() {
    super();
    this.state = {
      searchTransactionFromDate: new Date().toISOString().split("T")[0],
      searchTransactionToDate: new Date().toISOString().split("T")[0],
      loading: false,
      result: [],
    };
    this.onClearSearch = this.onClearSearch.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onExportClick = this.onExportClick.bind(this);
    this.exportProducts = this.exportProducts.bind(this);
  }

  componentDidMount() {
    //load data
    this.loadData(
      this.state.searchTransactionFromDate,
      this.state.searchTransactionToDate
    );
  }

  async loadData(searchTransactionFromDate, searchTransactionToDate) {
    this.setState({ loading: true });
    const fromDateStr = searchTransactionFromDate + "T00:00:00";
    // Create a Date object
    const fromDate = new Date(fromDateStr);
    // Convert the Date object to epoch milliseconds
    const epochMillisFrom = fromDate.getTime();

    // Define the date-time string
    const toDateStr = searchTransactionToDate + "T23:59:59";
    // Create a Date object
    const toDate = new Date(toDateStr);
    // Convert the Date object to epoch milliseconds
    const epochMillisTo = toDate.getTime();

    let result = await axios.post(
      "https://backend-197598313260.asia-south1.run.app/v1/reports/pincode/products/dashboard",
      {},
      {
        headers: {
          "Content-Type": "application/json", // Explicitly set the content type
        },
      }
    );
    let tmp = [];
    if (
      result &&
      result.data &&
      result.data.result &&
      result.data.result.length > 0
    ) {
      tmp = result.data.result;
    }
    // console.log("tmp", tmp);
    let result1 = await axios.post(
      "https://backend-197598313260.asia-south1.run.app/v1/reports/pincode/storesummary/dashboard",
      {
        fromDate: epochMillisFrom,
        toDate: epochMillisTo,
      },
      {
        headers: {
          "Content-Type": "application/json", // Explicitly set the content type
        },
      }
    );
    let tmp1 = [];
    if (
      result1 &&
      result1.data &&
      result1.data.result &&
      result1.data.result.length > 0
    ) {
      tmp1 = result1.data.result;
    }

    tmp1.map((m) => {
      if (tmp.filter((f) => f.StoreID === m.storeId).length > 0) {
        let t = tmp.filter((f) => f.StoreID === m.storeId)[0];
        // m.TotalInventoryCount = t.TotalInventoryCount;
        // m.TotalGcCount = t.TotalGcCount;
        // m.IssueReportedCount = t.IssueReportedCount;
        // m.ActivePsinCount = t.ActivePsinCount;
        // m.InstockGreaterThanZeroCount = t.InstockGreaterThanZeroCount;

        m.TotalInventoryCount = t.TotalInventoryCount;
        m.TotalGcCount = t.TotalGcCount;
        m.TotalGCSCount = t.TotalGCSCount;
        m.ActivePsinCount = t.ActivePsinCount;
        m.InstockGreaterThanZeroCount = t.InstockGreaterThanZeroCount;
        m.OnePSINIsLiveCount = t.OnePSINIsLiveCount;
        m.ManualOutOfStockCount = t.ManualOutOfStockCount;
        m.ProductNotFoundCount = t.ProductNotFoundCount;
        m.PSINNotMappedInProductCount = t.PSINNotMappedInProductCount;
        m.IssueReportedInProductCount = t.IssueReportedInProductCount;
        m.NegativeInventoryCount = t.NegativeInventoryCount;
        m.ProductNotFoundCount = t.ProductNotFoundCount;
        m.ZeroInventoryCount = t.ZeroInventoryCount;
      }
    });
    // console.log("tmp1", tmp1);
    this.setState({ result: tmp1, loading: false });
  }

  onClearSearch() {
    this.setState({
      searchTransactionFromDate: new Date().toISOString().split("T")[0],
      searchTransactionToDate: new Date().toISOString().split("T")[0],
    });
    this.loadData(
      new Date().toISOString().split("T")[0],
      new Date().toISOString().split("T")[0]
    );
  }

  onSearchClick() {
    this.loadData(
      this.state.searchTransactionFromDate,
      this.state.searchTransactionToDate
    );
  }

  async exportProducts(storeid, storename) {
    this.setState({ loading: true, exportCalled: true });
    console.log("storeid ", storeid);
    console.log("storename", storename);

    let result = await this.props.getBulkInstock(
      "",
      [{ key: storeid }],
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    );

    let prod = result.searchResult;
    let scrollID = result.scrollID;

    for (let index = 1; index < result.totalItemsCount / 2000; index++) {
      let result = await this.props.getBulkInstock(
        scrollID,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        ""
      );
      result.searchResult.forEach((element) => {
        prod.push(element);
      });
    }
    let products = [];
    prod.map((data) => {
      let product = {
        "Store Name": data.StoreName,
        "Store Location": data.Location,
        Brand: data.Brand,
        Category: data.Category,
        "Sub Category": data.SubCategory,
        SKU: data.ProductSKU,
        Barcode: data.BarCode,
        "Product Name": data.ProductName,
      };
      product["Supply Price"] = Number(data.SupplyPrice).toFixed(2);
      product["Retail Price"] = Number(data.RetailPrice).toFixed(2);
      product["In Stock Qty"] = Number(
        data.InStockQty ? data.InStockQty : 0
      ).toFixed(2);
      product["ActivePSIN"] = data.PincodeProductID
        ? data.PincodeProductID
        : "";
      product["GCNGC"] = data.ProductCustomField2
        ? data.ProductCustomField2
        : "";
      product["GCIssue"] = data.ProductCustomField4
        ? data.ProductCustomField4
        : "";
      product["Remarks"] = data.Remarks ? data.Remarks : "";

      products.push(product);
    });
    this.setState({ loading: false });
    const csvData = constatnts.objToCSV(products);
    this.setState({ loading: false, exportCalled: false });
    const csvBlob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", storename + "_InStock.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  async onExportClick() {
    let result = this.state.result;

    let data = [];

    result.map((r) => {
      data.push({
        StoreId: r.storeId,
        StoreName: r.storeName,
        OnlineOrderCount: r.onlineOrderCount,
        OflineOrderCount: r.oflineOrderCount,
        VendorCount: r.vendorCount,
        PurchaseEntryCount: r.purchaseEntryCount,
        PurchaseReturnCount: r.purchaseReturnCount,
        StockUpdateCount: r.stockUpdateCount,
        DamageCount: r.damageCount,
        ExpenseCount: r.expenseCount,
        TotalGCSCount: r.TotalGCSCount,
        TotalItemsWithInventoryActivity: r.TotalInventoryCount,
        TotalGCCount: r.TotalGcCount,
        ReportedItems: r.IssueReportedInProductCount,
        ManualOSS: r.ManualOutOfStockCount,
        OSS: r.NegativeInventoryCount,
        OSSZero: r.ZeroInventoryCount,
        OtherIssue:
          Number(r.ProductNotFoundCount ? r.ProductNotFoundCount : 0) +
          Number(
            r.PSINNotMappedInProductCount ? r.PSINNotMappedInProductCount : 0
          ),
        OnePSINLive: r.OnePSINIsLiveCount,

        ActivePSINs: r.ActivePsinCount,
        InStock: r.InstockGreaterThanZeroCount,
      });
    });
    const csvData = constatnts.objToCSV(data);
    this.setState({ loading: false, exportCalled: false });
    const csvBlob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    let fileName = "PincodeDashboard.csv";
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  render() {
    return (
      <div className="content-wrapper">
        <section className="content">
          <div className="row">
            <div className="col-md-12">
              <div className="box ">
                <div className="box-header with-border">
                  <div className="row">
                    <div className="col-md-6 col-xs-9">
                      <font style={{ fontSize: "30px" }}>
                        Pincode Dashboard
                      </font>
                    </div>
                    <div className="col-md-6 col-xs-3">
                      <button
                        type="cancel"
                        className="btn btn-default pull-right btn-flat"
                        style={{ marginTop: "5px", marginLeft: "10px" }}
                        onClick={() =>
                          this.props.history.push({
                            pathname: "/home/reports/allreport",
                          })
                        }
                      >
                        <i className="fa fa-arrow-left"></i> Back
                      </button>
                    </div>
                  </div>
                </div>
                <div className="box-body">
                  <div className="row">
                    <div className="col-md-2">
                      <div className="form-group">
                        <label>From</label>
                        <input
                          type="date"
                          className="form-control"
                          onChange={(evt) =>
                            this.setState({
                              searchTransactionFromDate: evt.target.value,
                            })
                          }
                          value={this.state.searchTransactionFromDate}
                          onKeyPress={this.handleKeyPress}
                        />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-group">
                        <label>To</label>
                        <input
                          type="date"
                          className="form-control"
                          onChange={(evt) =>
                            this.setState({
                              searchTransactionToDate: evt.target.value,
                            })
                          }
                          value={this.state.searchTransactionToDate}
                          onKeyPress={this.handleKeyPress}
                        />
                      </div>
                    </div>
                    <div className="col-md-5">
                      <button
                        type="button"
                        className="btn btn-primary btn-flat btn-md pull-right"
                        style={{ marginTop: "25px", marginLeft: "5px" }}
                        disabled={
                          this.state.searchTransactionFromDate === "" ||
                          this.state.searchTransactionToDate === ""
                        }
                        onClick={this.onSearchClick}
                      >
                        Search
                      </button>
                      <button
                        type="button"
                        className="btn btn-default btn-flat btn-md pull-right"
                        style={{ marginTop: "25px" }}
                        onClick={this.onClearSearch}
                      >
                        Clear
                      </button>
                      <button
                        className="btn btn-default btn-flat btn-md pull-right"
                        style={{ marginTop: "25px", marginRight: "5px" }}
                        onClick={this.onExportClick}
                      >
                        Export{" "}
                        <i className="glyphicon glyphicon-download-alt "></i>
                      </button>
                    </div>
                  </div>
                  <br></br>
                  {this.state.loading && <Loader show={this.state.loading} />}
                  {!this.state.loading && (
                    <div
                      style={{ width: "auto", overflowX: "auto" }}
                      className="scroll2"
                    >
                      <table className="table table-bordered table-striped">
                        <thead>
                          <tr>
                            <th rowSpan={2}>Store</th>
                            <th rowSpan={2}>Online Order Count</th>
                            <th rowSpan={2}>Offline Sales Transaction Count</th>
                            <th rowSpan={2}>Vendor Count</th>
                            <th rowSpan={2}>PE Count</th>
                            <th rowSpan={2}>PR Count</th>
                            <th rowSpan={2}>Stock Update Count</th>
                            <th rowSpan={2}>Damage Count</th>
                            <th rowSpan={2}>Expense Count</th>
                            <th rowSpan={2}>GCS Count</th>
                            <th rowSpan={2}>
                              Total Items with Inventory Activity
                            </th>
                            <th rowSpan={2}>Found in GC Count</th>
                            <th colSpan={8}>Found in GC (Bifurcation)</th>
                            <th rowSpan={2}></th>
                          </tr>
                          <tr>
                            <th>Reported Items</th>
                            <th>Manual OOS</th>
                            <th>OOS(0)</th>
                            <th>OOS(-ve)</th>
                            <th>Other Issue</th>
                            <th>One PSIN Live</th>
                            <th>Active PSINs</th>
                            <th>Active PSINs - In stock</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.result.map((m) => (
                            <tr>
                              <td>{m.storeName}</td>
                              <td className="align-right">
                                {m.onlineOrderCount}
                              </td>
                              <td className="align-right">
                                {m.oflineOrderCount}
                              </td>
                              <td className="align-right">{m.vendorCount}</td>
                              <td className="align-right">
                                {m.purchaseEntryCount}
                              </td>
                              <td className="align-right">
                                {m.purchaseReturnCount}
                              </td>
                              <td className="align-right">
                                {m.stockUpdateCount}
                              </td>
                              <td className="align-right">{m.damageCount}</td>
                              <td className="align-right">{m.expenseCount}</td>
                              <td className="align-right">{m.TotalGCSCount}</td>
                              <td className="align-right">
                                {m.TotalInventoryCount}
                              </td>

                              <td className="align-right">{m.TotalGcCount}</td>
                              <td className="align-right">
                                {m.IssueReportedInProductCount}
                              </td>
                              <td className="align-right">
                                {m.ManualOutOfStockCount}
                              </td>
                              <td className="align-right">
                                {m.ZeroInventoryCount}
                              </td>
                              <td className="align-right">
                                {m.NegativeInventoryCount}
                              </td>
                              <td className="align-right">
                                {Number(
                                  m.ProductNotFoundCount
                                    ? m.ProductNotFoundCount
                                    : 0
                                ) +
                                  Number(
                                    m.PSINNotMappedInProductCount
                                      ? m.PSINNotMappedInProductCount
                                      : 0
                                  )}
                              </td>

                              <td className="align-right">
                                {m.OnePSINIsLiveCount}
                              </td>
                              <td className="align-right">
                                {m.ActivePsinCount}
                              </td>
                              <td className="align-right">
                                {m.InstockGreaterThanZeroCount}
                              </td>
                              <td>
                                <a
                                  onClick={() =>
                                    this.exportProducts(m.storeId, m.storeName)
                                  }
                                >
                                  Export Products
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(PincodeAdminDashboard);
