import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import * as constatnts from "../../Constatnts";
import Loader from "../Loader";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import Window from "../2/Controls/Window";
import Modal from "react-responsive-modal";
import UniqueNoSelection from "../Inventory/UniqueNoSelection";
import BatchSelection from "../Inventory/BatchSelection";

class Instock extends Component {
  constructor() {
    super();
    this.state = {
      products: [],
      stores: [],
      storeLocations: [],
      storeList: [],
      searchText: "",
      searchStoreID: "",
      searchStoreLocation: "",
      searchCategory: "",
      searchSubCategory: "",
      searchProductSKU: "",
      searchProductName: "",
      searchBarCode: "",
      searchBrand: "",
      searchProductCustomField1: "",
      searchProductCustomField2: "",
      searchProductCustomField3: "",
      searchProductCustomField4: "",
      searchProductCustomField5: "",
      searchProductCustomField6: "",
      searchProductTypeID: "",
      exportCalled: false,
      activePage: 1,
      itemsCountPerPage: 20,
      totalItemsCount: 0,
      recordFrom: 0,
      paginationStatus: "No records to display",
      loading: true,
      showCustomFields: false,
      instockTotals: { totalqty: 0, totalsales: 0, totalpurchase: 0 },
      productCustomField1DisplayName: "ProductCustomField1",
      productCustomField2DisplayName: "ProductCustomField2",
      productCustomField3DisplayName: "ProductCustomField3",
      productCustomField4DisplayName: "ProductCustomField4",
      productCustomField5DisplayName: "ProductCustomField5",
      productCustomField6DisplayName: "ProductCustomField6",
      showInstockQtyGreaterThanZero: false,

      productSelected: false,
      selectAll: false,
    };
    this.loadProductsReportData = this.loadProductsReportData.bind(this);
    this.onClearSearch = this.onClearSearch.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.onExportClick = this.onExportClick.bind(this);
    this.showPaginationStatus = this.showPaginationStatus.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.onStoreChange = this.onStoreChange.bind(this);
    this.checkIfAtLeastOneSelected = this.checkIfAtLeastOneSelected.bind(this);
    this.onDeleteStock = this.onDeleteStock.bind(this);
  }

  async componentDidMount() {
    this.setState({ storeID: this.props.user.store.key });
    let storeList = [];
    storeList.push({ key: this.props.user.store.key });

    this.props
      .getUserStores(this.props.user.user.RegistrationID, "")
      .then((stores) => {
        this.setState({ stores: stores });
      });

    let storeLocationsTmp = [];
    let storeLocations = [];
    let searchStoreLocation = "";
    storeLocationsTmp = await this.props.getStoreLocations(
      this.props.user.store.key
    );
    storeLocations = storeLocationsTmp.filter((f) => !f.IsDeleted);
    searchStoreLocation =
      storeLocations.length > 0 ? storeLocations[0].key : "";

    // console.log("storeLocations", storeLocations);
    this.setState({ storeLocations: storeLocations });

    this.props.config.CustomFields.map((customField) => {
      if (customField.ModuleName === "Product") {
        let productCustomField1DisplayName =
          customField.CustomField1Name.trim() === ""
            ? "ProductCustomField1"
            : customField.CustomField1Name.trim();
        let productCustomField2DisplayName =
          customField.CustomField2Name.trim() === ""
            ? "ProductCustomField2"
            : customField.CustomField2Name.trim();
        let productCustomField3DisplayName =
          customField.CustomField3Name.trim() === ""
            ? "ProductCustomField3"
            : customField.CustomField3Name.trim();
        let productCustomField4DisplayName =
          customField.CustomField4Name.trim() === ""
            ? "ProductCustomField4"
            : customField.CustomField4Name.trim();
        let productCustomField5DisplayName =
          customField.CustomField5Name.trim() === ""
            ? "ProductCustomField5"
            : customField.CustomField5Name.trim();
        let productCustomField6DisplayName =
          customField.CustomField6Name.trim() === ""
            ? "ProductCustomField6"
            : customField.CustomField6Name.trim();
        this.setState({
          productCustomField1DisplayName: productCustomField1DisplayName,
          productCustomField2DisplayName: productCustomField2DisplayName,
          productCustomField3DisplayName: productCustomField3DisplayName,
          productCustomField4DisplayName: productCustomField4DisplayName,
          productCustomField5DisplayName: productCustomField5DisplayName,
          productCustomField6DisplayName: productCustomField6DisplayName,
        });
      }
    });

    this.setState({
      storeList: storeList,
      storeID: this.props.user.store.key,
      searchStoreLocation: searchStoreLocation,
    });

    this.loadProductsReportData(
      storeList,
      0,
      searchStoreLocation,
      this.state.searchCategory,
      this.state.searchSubCategory,
      this.state.searchProductSKU,
      this.state.searchProductName,
      this.state.searchBrand,
      this.state.searchBarCode,
      this.state.searchProductCustomField1,
      this.state.searchProductCustomField2,
      this.state.searchProductCustomField3,
      this.state.searchProductCustomField4,
      this.state.searchProductCustomField5,
      this.state.searchProductCustomField6,
      this.state.showInstockQtyGreaterThanZero,
      this.state.searchProductTypeID
    );
  }

  async loadProductsReportData(
    storeList,
    from,
    searchStoreLocation,
    searchCategory,
    searchSubCategory,
    searchProductSKU,
    searchProductName,
    searchBrand,
    searchBarCode,
    searchProductCustomField1,
    searchProductCustomField2,
    searchProductCustomField3,
    searchProductCustomField4,
    searchProductCustomField5,
    searchProductCustomField6,
    showInstockQtyGreaterThanZero = false,
    searchProductTypeID
  ) {
    this.setState({ loading: true });
    //
    this.props
      .getItemInStockTotals(
        storeList,
        searchStoreLocation,
        searchCategory,
        searchSubCategory,
        searchProductSKU,
        searchProductName,
        searchBrand,
        searchBarCode,
        searchProductCustomField1,
        searchProductCustomField2,
        searchProductCustomField3,
        searchProductCustomField4,
        searchProductCustomField5,
        searchProductCustomField6,
        showInstockQtyGreaterThanZero,
        searchProductTypeID
      )
      .then((result) => {
        this.setState({ instockTotals: result.searchResult });
      });
    //
    let result = await this.props.getItemInStockData(
      storeList,
      from,
      this.state.itemsCountPerPage,
      this.props.user.store.key,
      searchStoreLocation,
      searchCategory,
      searchSubCategory,
      searchProductSKU,
      searchProductName,
      searchBrand,
      searchBarCode,
      searchProductCustomField1,
      searchProductCustomField2,
      searchProductCustomField3,
      searchProductCustomField4,
      searchProductCustomField5,
      searchProductCustomField6,
      showInstockQtyGreaterThanZero,
      searchProductTypeID
    );

    console.log("result", result);
    let productIDs = [];

    for (let i = 0; i < result.searchResult.length; i++) {
      const productID = result.searchResult[i].ProductID;
      productIDs.push(productID);
    }

    let res1 = await this.props.getProductsByProductIDs(productIDs);
    let productList = res1.searchResult;

    let products = [];
    for (let index = 0; index < result.searchResult.length; index++) {
      const data = result.searchResult[index];
      // console.log('data', data)

      let product = {
        key: data.key,
        StoreName: data.StoreName,
        StoreLocation: data.Location,
        Brand: data.Brand,
        Category: data.Category,
        SubCategory: data.SubCategory,
        ProductSKU: data.ProductSKU,
        BarCode: data.BarCode,
        ProductID: data.ProductID,
        ProductName: data.ProductName,
        ProductType: data.ProductType,
        PincodeProductID: data.PincodeProductID ? data.PincodeProductID : "",
      };

      product[this.state.productCustomField1DisplayName] = data.hasOwnProperty(
        "ProductCustomField1"
      )
        ? data.ProductCustomField1
        : "";
      product[this.state.productCustomField2DisplayName] = data.hasOwnProperty(
        "ProductCustomField2"
      )
        ? data.ProductCustomField2
        : "";
      product[this.state.productCustomField3DisplayName] = data.hasOwnProperty(
        "ProductCustomField3"
      )
        ? data.ProductCustomField3
        : "";
      product[this.state.productCustomField4DisplayName] = data.hasOwnProperty(
        "ProductCustomField4"
      )
        ? data.ProductCustomField4
        : "";
      product[this.state.productCustomField5DisplayName] = data.hasOwnProperty(
        "ProductCustomField5"
      )
        ? data.ProductCustomField5
        : "";
      product[this.state.productCustomField6DisplayName] = data.hasOwnProperty(
        "ProductCustomField6"
      )
        ? data.ProductCustomField6
        : "";

      let showDeleteOption = false;
      let showBatchList = false;
      let showUniqueList = false;

      if (productList && productList.length > 0) {
        let objProd = productList.filter((p) => p.key === data.ProductID);
        if (objProd && objProd.length > 0) {
          product.Image =
            objProd[0].images && objProd[0].images.length > 0
              ? objProd[0].images[0].ImagePath
              : "";
          // console.log('objProd[0]',objProd[0])
          if (objProd[0].inventoryType === "Batch Tracking") {
            showBatchList = true;
          }
          if (objProd[0].inventoryType === "Unique No") {
            showUniqueList = true;
          }
          if (this.props.user.user.Role === "Power User") {
            if (!showBatchList && !showUniqueList) {
              showDeleteOption = true;
            }
          }
        }
      }
      product.ShowDeleteOption = showDeleteOption;
      product.ShowBatchList = showBatchList;
      product.ShowUniqueList = showUniqueList;

      product["LocationID"] = data.LocationID;

      product["SupplyPrice"] = Number(data.SupplyPrice).toFixed(2);

      product["SupplyPrice"] = Number(data.SupplyPrice).toFixed(2);
      product["RetailPrice"] = Number(data.RetailPrice).toFixed(2);
      product["InStockQty"] = Number(
        data.InStockQty ? data.InStockQty : 0
      ).toFixed(2);

      product["PurchaseValue"] = Number(
        (data.InStockQty ? data.InStockQty : 0) *
          (data.SupplyPrice ? data.SupplyPrice : 0)
      ).toFixed(2);
      product["SalesValue"] = Number(
        (data.InStockQty ? data.InStockQty : 0) *
          (data.RetailPrice ? data.RetailPrice : 0)
      ).toFixed(2);

      products.push(product);
    }
    // console.log("products", products);
    this.setState({
      products: products,
      totalItemsCount: result.totalItemsCount,
      loading: false,
    });

    this.showPaginationStatus();
  }

  checkIfAtLeastOneSelected() {
    let productSelected = false;
    if (this.state.products.filter((f) => f.Selected).length > 0) {
      productSelected = true;
    }
    this.setState({ productSelected: productSelected });
  }

  async onDeleteStock() {
    this.setState({ deleting: true });
    let selectedRecord = this.state.selectedRecord;
    console.log("selectedRecord", selectedRecord);
    if (selectedRecord) {
      await this.props.deleteInventoryInStock(selectedRecord.key);
      this.setState({ deleting: false, showDelete: false });
      setTimeout(
        function() {
          //Start the timer
          this.props.getToastr("Selected Product(s) deleted successfully");
          this.loadProductsReportData(
            this.state.storeList,
            0,
            this.state.searchStoreLocation,
            this.state.searchCategory,
            this.state.searchSubCategory,
            this.state.searchProductSKU,
            this.state.searchProductName,
            this.state.searchBrand,
            this.state.searchBarCode,
            this.state.searchProductCustomField1,
            this.state.searchProductCustomField2,
            this.state.searchProductCustomField3,
            this.state.searchProductCustomField4,
            this.state.searchProductCustomField5,
            this.state.searchProductCustomField6,
            this.state.showInstockQtyGreaterThanZero,
            this.state.searchProductTypeID
          );
        }.bind(this),
        5000
      );
    }
  }

  onClearSearch() {
    let storeList = [];
    storeList.push({ key: this.props.user.store.key });

    this.setState({
      storeList: storeList,
      recordFrom: 0,
      searchStoreLocation: "",
      searchCategory: "",
      searchSubCategory: "",
      searchProductSKU: "",
      searchProductName: "",
      searchBrand: "",
      searchBarCode: "",
      searchProductCustomField1: "",
      searchProductCustomField2: "",
      searchProductCustomField3: "",
      searchProductCustomField4: "",
      searchProductCustomField5: "",
      searchProductCustomField6: "",
      showInstockQtyGreaterThanZero: false,
    });

    this.loadProductsReportData(
      this.state.storeList,
      0,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      false,
      ""
    );
  }

  async onExportClick() {
    this.setState({ loading: false, exportCalled: true });

    let result = await this.props.getBulkInstock(
      "",
      this.state.storeList,
      this.state.searchStoreLocation,
      this.state.searchCategory,
      this.state.searchSubCategory,
      this.state.searchProductSKU,
      this.state.searchProductName,
      this.state.searchBrand,
      this.state.searchBarCode,
      this.state.searchProductCustomField1,
      this.state.searchProductCustomField2,
      this.state.searchProductCustomField3,
      this.state.searchProductCustomField4,
      this.state.searchProductCustomField5,
      this.state.searchProductCustomField6,
      this.state.showInstockQtyGreaterThanZero,
      this.state.searchProductTypeID
    );

    let prod = result.searchResult;
    let scrollID = result.scrollID;

    for (let index = 1; index < result.totalItemsCount / 2000; index++) {
      let result = await this.props.getBulkInstock(
        scrollID,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        ""
      );
      result.searchResult.forEach((element) => {
        prod.push(element);
      });
    }
    let products = [];
    prod.map((data) => {
      let product = {
        "Store Name": data.StoreName,
        "Store Location": data.Location,
        Brand: data.Brand,
        Category: data.Category,
        "Sub Category": data.SubCategory,
        SKU: data.ProductSKU,
        Barcode: data.BarCode,
        "Product Name": data.ProductName,
      };

      if (this.state.showCustomFields) {
        product[
          this.state.productCustomField1DisplayName
        ] = data.hasOwnProperty("ProductCustomField1")
          ? data.ProductCustomField1
          : "";
        product[
          this.state.productCustomField2DisplayName
        ] = data.hasOwnProperty("ProductCustomField2")
          ? data.ProductCustomField2
          : "";
        product[
          this.state.productCustomField3DisplayName
        ] = data.hasOwnProperty("ProductCustomField3")
          ? data.ProductCustomField3
          : "";
        product[
          this.state.productCustomField4DisplayName
        ] = data.hasOwnProperty("ProductCustomField4")
          ? data.ProductCustomField4
          : "";
        product[
          this.state.productCustomField5DisplayName
        ] = data.hasOwnProperty("ProductCustomField5")
          ? data.ProductCustomField5
          : "";
        product[
          this.state.productCustomField6DisplayName
        ] = data.hasOwnProperty("ProductCustomField6")
          ? data.ProductCustomField6
          : "";
      }
      product["Supply Price"] = Number(data.SupplyPrice).toFixed(2);
      product["Retail Price"] = Number(data.RetailPrice).toFixed(2);
      product["In Stock Qty"] = Number(
        data.InStockQty ? data.InStockQty : 0
      ).toFixed(2);
      product["Purchase Value"] = Number(
        (data.InStockQty ? data.InStockQty : 0) *
          (data.SupplyPrice ? data.SupplyPrice : 0)
      ).toFixed(2);
      product["Sales Value"] = Number(
        (data.InStockQty ? data.InStockQty : 0) *
          (data.RetailPrice ? data.RetailPrice : 0)
      ).toFixed(2);
      if (this.props.registration.registration.EnablePosBillingForPincode) {
        product["ActivePSIN"] = data.PincodeProductID
          ? data.PincodeProductID
          : "";
        product["GCNGC"] = data.ProductCustomField2
          ? data.ProductCustomField2
          : "";
        product["GCIssue"] = data.ProductCustomField4
          ? data.ProductCustomField4
          : "";
        product["Remarks"] = data.Remarks ? data.Remarks : "";
      }
      products.push(product);
    });
    const csvData = constatnts.objToCSV(products);
    this.setState({ loading: false, exportCalled: false });
    const csvBlob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "InStock.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  onSearchClick() {
    this.setState({
      loading: true,
      showUniqueNoSelection: false,
      showBatchsForSelection: false,
    });

    this.loadProductsReportData(
      this.state.storeList,
      0,
      this.state.searchStoreLocation,
      this.state.searchCategory,
      this.state.searchSubCategory,
      this.state.searchProductSKU,
      this.state.searchProductName,
      this.state.searchBrand,
      this.state.searchBarCode,
      this.state.searchProductCustomField1,
      this.state.searchProductCustomField2,
      this.state.searchProductCustomField3,
      this.state.searchProductCustomField4,
      this.state.searchProductCustomField5,
      this.state.searchProductCustomField6,
      this.state.showInstockQtyGreaterThanZero,
      this.state.searchProductTypeID
    );
  }

  handleKeyPress(e) {
    if (e.key === "Enter") {
      this.onSearchClick();
    }
  }

  async onStoreChange(evt) {
    let storeID = evt.target.value;
    let storeList = [];
    let storeLocationsTmp = [];
    let storeLocations = [];
    let searchStoreLocation = "";
    if (storeID !== "") {
      storeList.push({ key: storeID });

      storeLocationsTmp = await this.props.getStoreLocations(storeID);
      storeLocations = storeLocationsTmp.filter((f) => !f.IsDeleted);
      console.log("storeLocations", storeLocations);
      if (storeLocations.length > 0) {
        searchStoreLocation = storeLocations[0].key;
      }
    } else {
      for (let i = 0; i < this.state.stores.length; i++) {
        const store = this.state.stores[i];
        storeList.push({ key: store.key });
      }
    }
    this.setState({
      storeList: storeList,
      storeID: storeID,
      storeLocations: storeLocations,
      searchStoreLocation: searchStoreLocation,
    });
  }

  handlePageChange(pageNumber) {
    // console.log(`active page is ${pageNumber}`);
    let recordFrom = (pageNumber - 1) * this.state.itemsCountPerPage;
    this.setState({ activePage: pageNumber, recordFrom: recordFrom });

    this.loadProductsReportData(
      this.state.storeList,
      recordFrom,
      this.state.searchStoreLocation,
      this.state.searchCategory,
      this.state.searchSubCategory,
      this.state.searchProductSKU,
      this.state.searchProductName,
      this.state.searchBrand,
      this.state.searchBarCode,
      this.state.searchProductCustomField1,
      this.state.searchProductCustomField2,
      this.state.searchProductCustomField3,
      this.state.searchProductCustomField4,
      this.state.searchProductCustomField5,
      this.state.searchProductCustomField6,
      this.state.showInstockQtyGreaterThanZero,
      this.state.searchProductTypeID
    );
  }

  showPaginationStatus() {
    // console.log('this.state.recordFrom', this.state.recordFrom)
    let paginationStatus;
    if (this.state.recordFrom + 1 === this.state.totalItemsCount) {
      paginationStatus =
        "Showing " +
        this.state.totalItemsCount +
        " of " +
        this.state.totalItemsCount;
    } else if (
      this.state.recordFrom + this.state.itemsCountPerPage + 1 >
      this.state.totalItemsCount
    ) {
      paginationStatus =
        "Showing " +
        (this.state.recordFrom + 1) +
        " to  " +
        this.state.totalItemsCount +
        " of " +
        this.state.totalItemsCount;
    } else {
      paginationStatus =
        "Showing " +
        (this.state.recordFrom + 1) +
        " to  " +
        (this.state.recordFrom + this.state.itemsCountPerPage) +
        " of " +
        this.state.totalItemsCount;
    }
    this.setState({ paginationStatus: paginationStatus });
  }

  showIMEIList() {}

  render() {
    return (
      <div className="content-wrapper">
        <section className="content">
          <div className="row">
            <div className="col-md-12">
              <div className="box ">
                <div className="box-header with-border">
                  <div className="row">
                    <div className="col-md-6 col-xs-9">
                      <font style={{ fontSize: "30px" }}>In Stock Report</font>
                    </div>
                    <div className="col-md-6 col-xs-3">
                      <button
                        type="cancel"
                        className="btn btn-default pull-right btn-flat"
                        style={{ marginTop: "5px", marginLeft: "10px" }}
                        onClick={() =>
                          this.props.history.push({
                            pathname: "/home/reports/allreport",
                          })
                        }
                      >
                        <i className="fa fa-arrow-left"></i> Back
                      </button>
                    </div>
                  </div>
                </div>
                <div className="box-body">
                  <div className="row">
                    <div className="col-md-2">
                      <label>Store Name</label>
                      <select
                        className="form-control"
                        value={this.state.storeID}
                        onChange={this.onStoreChange}
                      >
                        <option value="">All Stores</option>
                        {this.state.stores
                          .filter((e) => !e.IsDeleted)
                          .map((store) => (
                            <option value={store.key}>
                              {store.StoreCode}-{store.StoreName}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="col-md-2">
                      <div className="form-group">
                        <label>Store Location</label>
                        {/* <input type="text" className="form-control input-md"
                                                value={this.state.searchStoreLocation}
                                                onChange={(evt) => this.setState({ searchStoreLocation: evt.target.value })}
                                                onKeyPress={this.handleKeyPress} /> */}
                        <select
                          className="form-control"
                          value={this.state.searchStoreLocation}
                          onChange={(e) => {
                            console.log("searchStoreLocation", e.target.value);
                            this.setState({
                              searchStoreLocation: e.target.value,
                            });
                          }}
                        >
                          {/* <option value="">Select Store Location</option> */}
                          {this.state.storeLocations
                            .filter((e) => !e.IsDeleted)
                            .map((storeLocation) => (
                              <option
                                key={storeLocation.key}
                                value={storeLocation.key}
                              >
                                {storeLocation.LocationCode}{" "}
                                {storeLocation.LocationName}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-group">
                        <label>Brand</label>
                        <input
                          type="text"
                          className="form-control input-md"
                          value={this.state.searchBrand}
                          onChange={(evt) =>
                            this.setState({ searchBrand: evt.target.value })
                          }
                          onKeyPress={this.handleKeyPress}
                        />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-group">
                        <label>Category</label>
                        <input
                          type="text"
                          className="form-control input-md"
                          value={this.state.searchCategory}
                          onChange={(evt) =>
                            this.setState({ searchCategory: evt.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-group">
                        <label>Sub Category</label>
                        <input
                          type="text"
                          className="form-control input-md"
                          value={this.state.searchSubCategory}
                          onChange={(evt) =>
                            this.setState({
                              searchSubCategory: evt.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-group">
                        <label>Product Type</label>
                        <select
                          className="form-control"
                          value={this.state.searchProductTypeID}
                          onChange={(e) => {
                            this.setState({
                              searchProductTypeID: e.target.value,
                            });
                          }}
                        >
                          <option value="">All</option>
                          <option value="1">Goods for sale</option>
                          <option value="2">Goods not for sale</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-2">
                      <div className="form-group">
                        <label>SKU</label>
                        <input
                          type="text"
                          className="form-control input-md"
                          value={this.state.searchProductSKU}
                          onChange={(evt) =>
                            this.setState({
                              searchProductSKU: evt.target.value,
                            })
                          }
                          onKeyPress={this.handleKeyPress}
                        />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-group">
                        <label>BarCode</label>
                        <input
                          type="text"
                          className="form-control input-md"
                          value={this.state.searchBarCode}
                          onChange={(evt) =>
                            this.setState({ searchBarCode: evt.target.value })
                          }
                          onKeyPress={this.handleKeyPress}
                        />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-group">
                        <label>Product Name</label>
                        <input
                          type="text"
                          className="form-control input-md"
                          value={this.state.searchProductName}
                          onChange={(evt) =>
                            this.setState({
                              searchProductName: evt.target.value,
                            })
                          }
                          onKeyPress={this.handleKeyPress}
                        />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <label>
                        <strong>Show Custom Fields</strong>
                      </label>
                      <br />
                      <input
                        type="checkbox"
                        checked={this.state.showCustomFields}
                        onChange={() => {
                          this.setState({
                            showCustomFields: !this.state.showCustomFields,
                          });
                        }}
                      />
                    </div>
                    <div className="col-md-3">
                      <label>
                        <strong>Show Products with Qty &gt; 0</strong>
                      </label>
                      <br />
                      <input
                        type="checkbox"
                        checked={this.state.showInstockQtyGreaterThanZero}
                        onChange={() => {
                          this.setState({
                            showInstockQtyGreaterThanZero: !this.state
                              .showInstockQtyGreaterThanZero,
                          });
                        }}
                      />
                    </div>
                  </div>
                  {this.state.showCustomFields && (
                    <React.Fragment>
                      {" "}
                      <div className="row" style={{ marginTop: "10px" }}>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>
                              {this.state.productCustomField1DisplayName}
                            </label>
                            <input
                              type="text"
                              className="form-control input-md"
                              value={this.state.searchProductCustomField1}
                              onChange={(evt) =>
                                this.setState({
                                  searchProductCustomField1: evt.target.value,
                                })
                              }
                              onKeyPress={this.handleKeyPress}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>
                              {this.state.productCustomField2DisplayName}
                            </label>
                            <input
                              type="text"
                              className="form-control input-md"
                              value={this.state.searchProductCustomField2}
                              onChange={(evt) =>
                                this.setState({
                                  searchProductCustomField2: evt.target.value,
                                })
                              }
                              onKeyPress={this.handleKeyPress}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>
                              {this.state.productCustomField3DisplayName}
                            </label>
                            <input
                              type="text"
                              className="form-control input-md"
                              value={this.state.searchProductCustomField3}
                              onChange={(evt) =>
                                this.setState({
                                  searchProductCustomField3: evt.target.value,
                                })
                              }
                              onKeyPress={this.handleKeyPress}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>
                              {this.state.productCustomField4DisplayName}
                            </label>
                            <input
                              type="text"
                              className="form-control input-md"
                              value={this.state.searchProductCustomField4}
                              onChange={(evt) =>
                                this.setState({
                                  searchProductCustomField4: evt.target.value,
                                })
                              }
                              onKeyPress={this.handleKeyPress}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>
                              {this.state.productCustomField5DisplayName}
                            </label>
                            <input
                              type="text"
                              className="form-control input-md"
                              value={this.state.searchProductCustomField5}
                              onChange={(evt) =>
                                this.setState({
                                  searchProductCustomField5: evt.target.value,
                                })
                              }
                              onKeyPress={this.handleKeyPress}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>
                              {this.state.productCustomField6DisplayName}
                            </label>
                            <input
                              type="text"
                              className="form-control input-md"
                              value={this.state.searchProductCustomField6}
                              onChange={(evt) =>
                                this.setState({
                                  searchProductCustomField6: evt.target.value,
                                })
                              }
                              onKeyPress={this.handleKeyPress}
                            />
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                  <div className="row">
                    <div className="col-md-12 pull-right">
                      <button
                        type="button"
                        className="btn btn-primary btn-flat btn-md pull-right"
                        style={{ marginLeft: "5px" }}
                        onClick={this.onSearchClick}
                      >
                        Search
                      </button>
                      <button
                        type="button"
                        className="btn btn-default btn-flat btn-md pull-right"
                        onClick={this.onClearSearch}
                      >
                        Clear
                      </button>
                      <button
                        className="btn btn-default btn-flat btn-md pull-right"
                        style={{ marginRight: "5px" }}
                        onClick={this.onExportClick}
                      >
                        {" "}
                        Export <i className="fa fa-download"></i>
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-2">
                      <div className="form-group">
                        <h4>
                          <b>
                            Total Qty:{" "}
                            {Number(this.state.instockTotals.totalqty).toFixed(
                              2
                            )}
                          </b>
                        </h4>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <h4>
                          <b>
                            Total Purchase :{" "}
                            {Number(
                              this.state.instockTotals.totalpurchase
                            ).toFixed(2)}
                          </b>
                        </h4>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <h4>
                          <b>
                            Total Sales :{" "}
                            {Number(
                              this.state.instockTotals.totalsales
                            ).toFixed(2)}
                          </b>
                        </h4>
                      </div>
                    </div>
                  </div>
                  <br></br>
                  {/* {this.state.productSelected && <div className="row" style={{padding:"10px 10px 10px 10px"}}>
                                    <div className="col-md-12  col-xs-12">
                                        <button className="btn btn-default btn-flat btn-md pull-left" onClick={() => this.onDeleteStock()}>Delete Stock</button>
                                    </div>
                                </div>} */}
                  {this.state.loading && <Loader show={this.state.loading} />}
                  {!this.state.loading && (
                    <div
                      style={{ width: "auto", overflowX: "auto" }}
                      className="scroll2"
                    >
                      <table className="table table-bordered table-striped">
                        <thead>
                          <tr>
                            <th>
                              <input
                                type="checkbox"
                                checked={this.state.selectAll}
                                onChange={() => {
                                  let products = this.state.products;
                                  products.map((b) => {
                                    b.Selected = !this.state.selectAll;
                                  });
                                  this.setState({
                                    selectAll: !this.state.selectAll,
                                    products: products,
                                  });
                                  this.checkIfAtLeastOneSelected();
                                }}
                              />
                            </th>
                            <th>Store Name</th>
                            <th>Store Location</th>
                            <th>Brand</th>
                            <th>Category</th>
                            <th>Sub Category</th>
                            <th>SKU</th>
                            <th>Barcode</th>
                            <th>Product Name</th>
                            <th>Product Type</th>
                            {this.state.showCustomFields && (
                              <React.Fragment>
                                <th>
                                  {this.state.productCustomField1DisplayName}
                                </th>
                                <th>
                                  {this.state.productCustomField2DisplayName}
                                </th>
                                <th>
                                  {this.state.productCustomField3DisplayName}
                                </th>
                                <th>
                                  {this.state.productCustomField4DisplayName}
                                </th>
                                <th>
                                  {this.state.productCustomField5DisplayName}
                                </th>
                                <th>
                                  {this.state.productCustomField6DisplayName}
                                </th>
                              </React.Fragment>
                            )}
                            <th>Supply Price</th>
                            <th>Retail Price</th>
                            <th>In Stock Qty</th>
                            <th>Purchase Value</th>
                            <th>Sales Value</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.products.map((item, index) => (
                            <tr>
                              <td>
                                <input
                                  type="checkbox"
                                  checked={item.Selected}
                                  onChange={() => {
                                    let products = this.state.products;
                                    products[index].Selected = !products[index]
                                      .Selected;
                                    this.setState({ products: products });
                                    this.checkIfAtLeastOneSelected();
                                  }}
                                />
                              </td>
                              <td>{item.StoreName}</td>
                              <td>{item.StoreLocation}</td>
                              <td>{item.Brand}</td>
                              <td>{item.Category}</td>
                              <td>{item.SubCategory}</td>
                              <td>
                                <div>{item.ProductSKU}</div>
                                {item.PincodeProductID &&
                                  item.PincodeProductID !== "" && (
                                    <div className="badge green">
                                      Active PSIN: {item.PincodeProductID}
                                    </div>
                                  )}
                              </td>
                              <td>{item.BarCode}</td>
                              <td>
                                <div>{item.ProductName}</div>
                                {item.Image !== "" && (
                                  <img
                                    style={{ height: "43px", width: "93px" }}
                                    src={item.Image}
                                  />
                                )}
                              </td>
                              <td>{item.ProductType}</td>
                              {this.state.showCustomFields && (
                                <React.Fragment>
                                  {" "}
                                  <td>
                                    {
                                      item[
                                        this.state
                                          .productCustomField1DisplayName
                                      ]
                                    }
                                  </td>
                                  <td>
                                    {
                                      item[
                                        this.state
                                          .productCustomField2DisplayName
                                      ]
                                    }
                                  </td>
                                  <td>
                                    {
                                      item[
                                        this.state
                                          .productCustomField3DisplayName
                                      ]
                                    }
                                  </td>
                                  <td>
                                    {
                                      item[
                                        this.state
                                          .productCustomField4DisplayName
                                      ]
                                    }
                                  </td>
                                  <td>
                                    {
                                      item[
                                        this.state
                                          .productCustomField5DisplayName
                                      ]
                                    }
                                  </td>
                                  <td>
                                    {
                                      item[
                                        this.state
                                          .productCustomField6DisplayName
                                      ]
                                    }
                                  </td>
                                </React.Fragment>
                              )}
                              <td>{Number(item.SupplyPrice).toFixed(2)}</td>
                              <td>{Number(item.RetailPrice).toFixed(2)}</td>
                              <td>
                                <div>
                                  <Link
                                    to={{
                                      pathname: "/home/reports/ItemLedger",
                                      state: {
                                        ProductID: item.ProductID,
                                        LocationID: item.LocationID,
                                      },
                                    }}
                                  >
                                    {item.InStockQty}
                                  </Link>
                                </div>
                                {item.ShowDeleteOption && (
                                  <button
                                    className="btn btn-default pull-right btn-flat"
                                    onClick={() =>
                                      this.setState({
                                        showDelete: true,
                                        selectedRecord: item,
                                      })
                                    }
                                  >
                                    Delete In Stock
                                  </button>
                                )}
                                {item.ShowUniqueList && (
                                  <button
                                    className="btn btn-default pull-right btn-flat"
                                    onClick={async () => {
                                      let product = await this.props.getProductDetails(
                                        item.ProductID
                                      ); //this.props.config.Products.filter(f=>f.key ===item.ProductID);
                                      product.key = item.ProductID;
                                      console.log("product", product);
                                      this.setState({
                                        showUniqueNoSelection: true,
                                        selectedProduct: product,
                                        inStockQty: item.InStockQty,
                                        id: item.key,
                                      });
                                    }}
                                  >
                                    Show In Stock Unique Nos
                                  </button>
                                )}
                                {item.ShowBatchList && (
                                  <button
                                    className="btn btn-default pull-right btn-flat"
                                    onClick={async () => {
                                      let product = await this.props.getProductDetails(
                                        item.ProductID
                                      );
                                      product.key = item.ProductID;
                                      console.log("product", product);
                                      this.setState({
                                        showBatchsForSelection: true,
                                        selectedProduct: product,
                                        inStockQty: item.InStockQty,
                                        id: item.key,
                                      });
                                    }}
                                  >
                                    Show In Stock Batces
                                  </button>
                                )}
                              </td>
                              <td>{item.PurchaseValue}</td>
                              <td>{item.SalesValue}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                  {this.state.totalItemsCount > 0 && (
                    <div className="pull-left" style={{ marginTop: "25px" }}>
                      {this.state.paginationStatus}
                    </div>
                  )}
                  <div className="pull-right">
                    <Pagination
                      activePage={this.state.activePage} //this.state.activePage
                      itemsCountPerPage={this.state.itemsCountPerPage}
                      totalItemsCount={this.state.totalItemsCount}
                      pageRangeDisplayed={2}
                      onChange={this.handlePageChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {this.renderDeleteWindow()}
        {/* {this.state.showUniqueNoSelection && <Window 
            header={this.state.selectedProduct.Name}  
            width={"600px"}
            onClose={()=>this.setState({showUniqueNoSelection:false, selectedLineItem: null,})}
            buttons={[]}> */}
        {this.state.showUniqueNoSelection && (
          <UniqueNoSelection
            {...this.props}
            mobile={this.props.isMobileApp()}
            product={this.state.selectedProduct}
            hideSelection={true}
            lineItem={null}
            lineItems={[]}
            inStockQty={this.state.inStockQty}
            id={this.state.id}
            reLoad={() => this.onSearchClick()}
            onClose={() => this.setState({ showUniqueNoSelection: false })}
          />
        )}
        {/* </Window>} */}

        {/* <Modal open={this.state.showUniqueNoSelection}
                onClose={() => this.setState({ showUniqueNoSelection: false })} center>
                <UniqueNoSelection
                    {...this.props}
                    mobile={this.props.isMobileApp()}
                    product={this.state.selectedProduct}
                    hideSelection={true}
                    lineItem={null}
                    lineItems={[]}
                    inStockQty={this.state.inStockQty}
                    id={this.state.id}
                    reLoad={()=>this.onSearchClick()}
                    onClose={() => this.setState({ showUniqueNoSelection: false })} />
            </Modal> */}

        {/* {this.state.showBatchsForSelection && <Window 
            header={"Batch Selection"}  
            width={"600px"}
            onClose={()=>this.setState({showBatchsForSelection:false, selectedLineItem: null,selectedProduct:null})}
            buttons={[]}> */}
        {this.state.showBatchsForSelection && (
          <BatchSelection
            {...this.props}
            mobile={this.props.isMobileApp()}
            product={this.state.selectedProduct}
            storeLocationID={this.state.searchStoreLocation}
            hideSelection={true}
            lineItems={[]}
            inStockQty={this.state.inStockQty}
            id={this.state.id}
            reLoad={() => this.onSearchClick()}
            onClose={() => this.setState({ showBatchsForSelection: false })}
          />
        )}
        {/* </Window>} */}
      </div>
    );
  }

  renderDeleteWindow() {
    return (
      <React.Fragment>
        {this.state.showDelete && (
          <Window
            header={"Delete"}
            height={"200px"}
            modal={true}
            processing={this.state.deleting}
            onClose={() => this.setState({ showDelete: false })}
            onButtonClick={(buttonType) => {
              if (buttonType.toLowerCase() === "yes") {
                this.onDeleteStock();
              } else {
                this.setState({ showDelete: false, selectedRecord: null });
              }
            }}
            buttons={[
              { Type: "secondary", Text: "No", Align: "left" },
              { Type: "primary", Text: "Yes", Align: "right" },
            ]}
          >
            <div>Are you sure you wish to delete this record?</div>
          </Window>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(Instock);
