import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import * as constatnts from "../../Constatnts";
import Loader from "../Loader";
import firebase from "../../firebase";
import Pagination from "react-js-pagination";

class MarginReport extends Component {
  constructor() {
    super();
    this.state = {
      searchTransactionFromDate: new Date().toISOString().split("T")[0],
      searchTransactionToDate: new Date().toISOString().split("T")[0],
      loading: false,
      invoices: [],
      storeList: [],
      activePage: 1,
      itemsCountPerPage: 20,
      totalItemsCount: 0,
      recordFrom: 0,
    };
    this.onClearSearch = this.onClearSearch.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onExportClick = this.onExportClick.bind(this);
    this.showPaginationStatus = this.showPaginationStatus.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  async componentDidMount() {
    let storeList = [];
    storeList.push({ key: this.props.user.store.key });

    let stores = await this.props.getUserStores(
      this.props.user.user.RegistrationID,
      ""
    );

    this.setState({ storeList: storeList, stores: stores });

    this.loadData(
      storeList,
      this.state.recordFrom,
      this.state.itemsCountPerPage,
      this.state.searchTransactionFromDate,
      this.state.searchTransactionToDate,
      false
    );
  }

  async loadData(
    storeList,
    from,
    size,
    searchTransactionFromDate,
    searchTransactionToDate,
    forExport = false
  ) {
    this.setState({ loading: true });
    let result = await this.getElasticData(
      this.props.user.user.RegistrationID,
      storeList,
      from,
      size,
      searchTransactionFromDate,
      searchTransactionToDate,
      forExport
    );

    let invoices = [];
    if (result && result.searchResult && result.searchResult.length > 0) {
      for (let index = 0; index < result.searchResult.length; index++) {
        const data = result.searchResult[index];
        let supplyPrice = data.hasOwnProperty("SupplyPriceWithTax")
          ? data.SupplyPriceWithTax
          : 0;

        supplyPrice = Number(supplyPrice).toFixed(2);
        let retailPrice = data.RetailPrice;
        let marginPerUnit = Number(
          Number(retailPrice) - Number(supplyPrice)
        ).toFixed(2);
        let supplyTotal = Number(
          Number(supplyPrice) * Number(data.Qty)
        ).toFixed(2);
        let totalMargin = Number(
          Number(data.Amount) - Number(supplyTotal)
        ).toFixed(2);
        let invoice = {
          StoreName: data.StoreName,
          CashRegister: data.CashRegisterName,
        };
        invoice["TransactionNo"] = data.TransactionNo;
        invoice["TransactionType"] = data.TransactionType;
        invoice["TransactionDate"] = new Date(data.TransactionDate)
          .toString()
          .substring(0, 15);
        invoice.Cancelled = "No";
        if (data.CancellationReason) {
          invoice.Cancelled = "Yes";
        }

        invoice["Brand"] = data.Brand ? data.Brand : "";
        invoice["Category"] = data.Category;
        invoice["SubCategory"] = data.SubCategory ? data.SubCategory : "";
        invoice["SKU"] = data.SKU;
        invoice["BarCode"] = data.BarCode;
        invoice["ItemName"] = data.ProductName.replace(/,/g, "");
        invoice["MRP"] = data.MRP;
        invoice["SupplyPrice"] = supplyPrice;
        invoice["RetailPrice"] = data.RetailPrice;
        invoice["Qty"] = data.Qty;
        invoice["SaleValue"] = data.Amount;
        invoice["MarginPerUnit"] = marginPerUnit;
        invoice["TotalMargin"] = totalMargin;
        invoices.push(invoice);
      }
    }

    if (!forExport) {
      this.setState({
        totalItemsCount: result.totalItemsCount,
        invoices: invoices,
        loading: false,
      });
      this.showPaginationStatus();
    }

    return invoices;
  }

  async getElasticData(
    registrationID,
    searchStores,
    from,
    size,
    searchTransactionFromDate,
    searchTransactionToDate,
    exportData = false
  ) {
    const fromDateStr = searchTransactionFromDate + "T00:00:00";
    const fromDate = new Date(fromDateStr);
    const epochMillisFrom = fromDate.getTime();

    const toDateStr = searchTransactionToDate + "T23:59:59";
    const toDate = new Date(toDateStr);
    const epochMillisTo = toDate.getTime();

    let searchParameters = {
      _source: [
        "StoreID",
        "StoreName",
        "StoreCode",
        "CashRegisterName",
        "CashRegisterCode",
        "TransactionNo",
        "TransactionType",
        "TransactionDate",
        "CancellationReason",
        "ProductType",
        "HSNSACCode",
        "Brand",
        "Category",
        "SubCategory",
        "SKU",
        "BarCode",
        "ProductID",
        "ProductName",
        "MRP",
        "SupplyPrice",
        "SupplyPriceWithTax",
        "RetailPrice",
        "Qty",
        "TaxInclusive",
        "TaxableAmount",
        "DiscountPer",
        "TaxComponentAmount",
        "SubTotal",
        "TotalTax",
        "DiscountAmount",
        "Amount",
      ],
      from: from,
      size: size,
      track_total_hits: true,
      query: {
        bool: {
          must: [
            {
              term: {
                RegistrationID: registrationID,
              },
            },
            {
              range: {
                TransactionDate: {
                  lte: epochMillisTo,
                  gte: epochMillisFrom,
                },
              },
            },
          ],
          should: [],
          minimum_should_match: 1,
          must_not: [
            {
              bool: {
                should: [
                  {
                    bool: {
                      must_not: {
                        exists: {
                          field: "SupplyPriceWithTax",
                        },
                      },
                    },
                  },
                  {
                    term: {
                      SupplyPriceWithTax: 0,
                    },
                  },
                ],
              },
            },
          ],
          filter: {
            bool: {
              must: [],
            },
          },
        },
      },
      sort: [
        {
          TransactionDate: {
            order: "desc",
          },
        },
      ],
    };

    for (let i = 0; i < searchStores.length; i++) {
      const store = searchStores[i];
      searchParameters.query.bool.should.push({
        term: {
          "StoreID.keyword": store.key,
        },
      });
    }

    console.log("searchParameters", JSON.stringify(searchParameters));
    let addMessage = firebase.functions().httpsCallable("scrollData");
    let result = await addMessage({
      text: searchParameters,
      collection: "denomsales",
      scrollID: "",
    });

    const invoices = [];
    let sanitizedMessage = result.data.text;
    let total = sanitizedMessage.hits.total.value;
    sanitizedMessage.hits.hits.map((data, index) => {
      invoices.push({ ...data._source, key: data._id });
    });
    // console.log("getElasticData 0");

    if (total > size && exportData) {
      let scrollID = sanitizedMessage._scroll_id;
      searchParameters = {
        scroll: "9m",
        scroll_id: scrollID,
      };
      for (let index = 1; index < total / size; index++) {
        // console.log("getElasticData ", index);
        let addMessage = firebase.functions().httpsCallable("scrollData");
        let loopResult = await addMessage({
          text: searchParameters,
          scrollID: scrollID,
        });
        loopResult.data.text.hits.hits.map((data, index) => {
          invoices.push({ ...data._source, key: data._id });
        });
      }
    }

    return {
      totalItemsCount: total,
      searchResult: invoices,
    };
  }

  onClearSearch() {
    let storeList = [];
    storeList.push({ key: this.props.user.store.key });

    this.setState({
      searchStartDate: new Date().toISOString().split("T")[0],
      searchEndDate: new Date().toISOString().split("T")[0],
      storeList: storeList,
      recordFrom: 0,
    });

    this.loadData(
      storeList,
      0,
      this.state.itemsCountPerPage,
      new Date().toISOString().split("T")[0],
      new Date().toISOString().split("T")[0],
      false
    );
  }

  async onExportClick() {
    let result = await this.loadData(
      this.state.storeList,
      0,
      this.state.itemsCountPerPage,
      this.state.searchTransactionFromDate,
      this.state.searchTransactionToDate,
      true
    );
    // console.log("result", result);
    const csvData = constatnts.objToCSV(result);
    this.setState({ loading: false, exportCalled: false });
    const csvBlob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    let fileName = "PincodeDashboard.csv";
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  onSearchClick() {
    this.setState({ searchFrom: 0, loading: true });
    this.loadData(
      this.state.storeList,
      0,
      this.state.itemsCountPerPage,
      this.state.searchTransactionFromDate,
      this.state.searchTransactionToDate,
      false
    );
  }

  showPaginationStatus() {
    // console.log('this.state.recordFrom', this.state.recordFrom)
    let paginationStatus;
    if (this.state.recordFrom + 1 === this.state.totalItemsCount) {
      paginationStatus =
        "Showing " +
        this.state.totalItemsCount +
        " of " +
        this.state.totalItemsCount;
    } else if (
      this.state.recordFrom + this.state.itemsCountPerPage + 1 >
      this.state.totalItemsCount
    ) {
      paginationStatus =
        "Showing " +
        (this.state.recordFrom + 1) +
        " to  " +
        this.state.totalItemsCount +
        " of " +
        this.state.totalItemsCount;
    } else {
      paginationStatus =
        "Showing " +
        (this.state.recordFrom + 1) +
        " to  " +
        (this.state.recordFrom + this.state.itemsCountPerPage) +
        " of " +
        this.state.totalItemsCount;
    }
    this.setState({ paginationStatus: paginationStatus });
  }

  handlePageChange(pageNumber) {
    let recordFrom = (pageNumber - 1) * this.state.itemsCountPerPage;
    this.setState({ activePage: pageNumber, recordFrom: recordFrom });
    this.loadData(
      this.state.storeList,
      recordFrom,
      this.state.itemsCountPerPage,
      this.state.searchTransactionFromDate,
      this.state.searchTransactionToDate
    );
  }

  render() {
    return (
      <div className="content-wrapper">
        <section className="content">
          <div className="row">
            <div className="col-md-12">
              <div className="box ">
                <div className="box-header with-border">
                  <div className="row">
                    <div className="col-md-6 col-xs-9">
                      <font style={{ fontSize: "30px" }}>Margin Report</font>
                    </div>
                    <div className="col-md-6 col-xs-3">
                      <button
                        type="cancel"
                        className="btn btn-default pull-right btn-flat"
                        style={{ marginTop: "5px", marginLeft: "10px" }}
                        onClick={() =>
                          this.props.history.push({
                            pathname: "/home/reports/allreport",
                          })
                        }
                      >
                        <i className="fa fa-arrow-left"></i> Back
                      </button>
                    </div>
                  </div>
                </div>
                <div className="box-body">
                  <div className="row">
                    <div className="col-md-2">
                      <div className="form-group">
                        <label>From</label>
                        <input
                          type="date"
                          className="form-control"
                          onChange={(evt) =>
                            this.setState({
                              searchTransactionFromDate: evt.target.value,
                            })
                          }
                          value={this.state.searchTransactionFromDate}
                          onKeyPress={this.handleKeyPress}
                        />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-group">
                        <label>To</label>
                        <input
                          type="date"
                          className="form-control"
                          onChange={(evt) =>
                            this.setState({
                              searchTransactionToDate: evt.target.value,
                            })
                          }
                          value={this.state.searchTransactionToDate}
                          onKeyPress={this.handleKeyPress}
                        />
                      </div>
                    </div>
                    <div className="col-md-5">
                      <button
                        type="button"
                        className="btn btn-primary btn-flat btn-md pull-right"
                        style={{ marginTop: "25px", marginLeft: "5px" }}
                        disabled={
                          this.state.searchTransactionFromDate === "" ||
                          this.state.searchTransactionToDate === ""
                        }
                        onClick={this.onSearchClick}
                      >
                        Search
                      </button>
                      <button
                        type="button"
                        className="btn btn-default btn-flat btn-md pull-right"
                        style={{ marginTop: "25px" }}
                        onClick={this.onClearSearch}
                      >
                        Clear
                      </button>
                      <button
                        className="btn btn-default btn-flat btn-md pull-right"
                        style={{ marginTop: "25px", marginRight: "5px" }}
                        onClick={this.onExportClick}
                      >
                        Export{" "}
                        <i className="glyphicon glyphicon-download-alt "></i>
                      </button>
                    </div>
                  </div>
                  <br></br>
                  {this.state.loading && <Loader show={this.state.loading} />}
                  {!this.state.loading && (
                    <div
                      style={{ width: "auto", overflowX: "auto" }}
                      className="scroll2"
                    >
                      <table className="table table-bordered table-striped">
                        <thead>
                          <tr>
                            <th>Transaction No</th>
                            <th>Transaction Type</th>
                            <th>Transaction Date</th>
                            <th>Cancelled</th>
                            <th>Brand</th>
                            <th>Category</th>
                            <th>SubCategory</th>
                            <th>SKU</th>
                            <th>BarCode</th>
                            <th>Item Name</th>
                            <th>MRP</th>
                            <th>SupplyPrice</th>
                            <th>RetailPrice</th>
                            <th>Qty</th>
                            <th>Sale Value</th>
                            <th>Margin Per Unit</th>
                            <th>Total Margin</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.invoices.map((m) => (
                            <tr>
                              <td>{m.TransactionNo}</td>
                              <td>{m.TransactionType}</td>
                              <td>{m.TransactionDate}</td>
                              <td>{m.Cancelled}</td>
                              <td>{m.Brand}</td>
                              <td>{m.Category}</td>
                              <td>{m.SubCategory}</td>
                              <td>{m.SKU}</td>
                              <td>{m.BarCode}</td>
                              <td>{m.ItemName}</td>
                              <td className="align-right">{m.MRP}</td>
                              <td className="align-right">{m.SupplyPrice}</td>
                              <td className="align-right">{m.RetailPrice}</td>
                              <td className="align-right">{m.Qty}</td>
                              <td className="align-right">{m.SaleValue}</td>
                              <td className="align-right">{m.MarginPerUnit}</td>
                              <td className="align-right">{m.TotalMargin}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                  {this.state.totalItemsCount > 0 && (
                    <div className="col-md-12">
                      <div className="pull-left" style={{ marginTop: "25px" }}>
                        {this.state.paginationStatus}
                      </div>
                      <div className="pull-right">
                        <Pagination
                          activePage={this.state.activePage} //this.state.activePage
                          itemsCountPerPage={this.state.itemsCountPerPage}
                          totalItemsCount={this.state.totalItemsCount}
                          pageRangeDisplayed={2}
                          onChange={this.handlePageChange}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(MarginReport);
