import React, { Component } from "react";
import { withRouter } from "react-router";
import "./Common/Common.css";
import "./2/style.css";
import Page from "./2/Controls/Page";
import PageSection from "./2/Controls/PageSection";
import Input from "./2/Controls/Input";
import { auth } from "../firebase";

class NewRegistration extends Component {
  constructor() {
    super();
    this.state = {
      businessName: "",
      businessUniqueName: "yourbusinessname",
      businessTypeID: "",
      businessType: "",
      businessTypeError: "",
      countryCode: "IN",
      country: "India",
      countryError: "",
      fullName: "",
      contactNo: "",
      emailID: "",
      password: "",
      confirmPassword: "",
      loading: false,
      showModal: false,
      businessNameError: "",
      fullNameError: "",
      contactNoError: "",
      emailIDError: "",
      success: false,
      referredBy: "",
      plan: null,
      errorPassword: "",
      errorConfirmPassword: "",
      businessTypes: [
        {
          BusinessTypeID: "4",
          BusinessType: "Grocery / Kirana / Department Store",
        },
        { BusinessTypeID: "13", BusinessType: "Fruits & Vegetables" },
        { BusinessTypeID: "12", BusinessType: "Poultry & Meat Shop" },
        { BusinessTypeID: "11", BusinessType: "Seafood" },
        { BusinessTypeID: "1", BusinessType: "Salons & Spas" },
        { BusinessTypeID: "2", BusinessType: "Electronics and White Goods" },
        { BusinessTypeID: "3", BusinessType: "Apparel & Footwear" },
        { BusinessTypeID: "5", BusinessType: "Quick Service Restaurants" },
        { BusinessTypeID: "6", BusinessType: "Bakeries & Cafes" },
        { BusinessTypeID: "7", BusinessType: "Pet Stores" },
        { BusinessTypeID: "8", BusinessType: "Baby Products" },
        { BusinessTypeID: "9", BusinessType: "Cosmetic" },
        { BusinessTypeID: "10", BusinessType: "Others" },
      ],
      countries: [],
      whiteLabel: "POSIBLE",
      plans: [],
    };
    this.onRegister = this.onRegister.bind(this);
  }

  async componentDidMount() {
    let plans = await this.props.getPlans();
    let whiteLabel = "POSIBLE";

    if (this.props.registration.registration.key === "INZWhIrcdykiinoNqST9") {
      whiteLabel = "PINCODE";
    }
    this.setState({ plan: plans[0], plans: plans, whiteLabel: whiteLabel });
  }

  async onRegister() {
    this.setState({ loading: true });
    let businessNameError = "";
    let contactNoError = "";
    let emailIDError = "";
    let businessTypeError = "";

    // let planError = ""

    if (this.state.businessTypeID.trim() === "") {
      businessTypeError = "Please select type of business";
    }
    if (this.state.businessName.trim() === "") {
      businessNameError = "Please provide business/brand name";
    }
    if (this.state.contactNo.trim() === "") {
      contactNoError = "Please provide your mobile no";
    }
    if (this.state.emailID.trim() === "") {
      emailIDError = "Please provide email id";
    }

    // if (this.state.plan.trim() === '') { planError = 'Please select plan' }

    if (
      businessNameError !== "" ||
      businessTypeError !== "" ||
      contactNoError !== "" ||
      emailIDError !== ""
      // || planError !== ""
    ) {
      this.setState({
        businessNameError: businessNameError,
        businessTypeError: businessTypeError,
        contactNoError: contactNoError,
        emailIDError: emailIDError,
        loading: false,
      });
      return;
    }

    if (this.state.emailID.trim() != "") {
      let emailIDExists = await this.props.checkIfEmailIDExists(
        this.state.emailID
      );
      if (emailIDExists) {
        emailIDError =
          "Email ID is already registered with us. Please try a different Email ID";
        this.setState({
          emailIDError: emailIDError,
          showError: true,
          loading: false,
        });
        return;
      }
    }

    let businessNameIsUnique = await this.props.checkBusinessNameIsUnique(
      this.state.businessUniqueName
    );
    // .then(() => {
    if (!businessNameIsUnique) {
      //alert('Business name already registered with us. Please try a different business name')
      this.setState({
        businessName: "",
        businessUniqueName: "yourbusinessname",
        businessNameError:
          "Business name already registered with us. Please try a different business name",
        showError: true,
        loading: false,
      });
      return;
    }

    let storeOwnerUID = "";
    let powerUserUID = "";
    let storeOwnerEmailID = "";
    let powerUserEmailID = "";

    if (this.state.whiteLabel === "PINCODE") {
      storeOwnerEmailID = this.state.emailID;
      let authStoreOwnerResult = await auth.createUserWithEmailAndPassword(
        storeOwnerEmailID,
        this.state.contactNo
      );
      storeOwnerUID = authStoreOwnerResult.user.uid;
      powerUserEmailID = "admin" + this.state.emailID;
      let authPowerUserResult = await auth.createUserWithEmailAndPassword(
        powerUserEmailID,
        this.state.contactNo
      );
      powerUserUID = authPowerUserResult.user.uid;
    } else {
      powerUserEmailID = this.state.emailID;
      let authPowerUserResult = await auth.createUserWithEmailAndPassword(
        powerUserEmailID,
        this.state.contactNo
      );
      powerUserUID = authPowerUserResult.user.uid;
    }

    const registration = {
      ActionBy: "",
      ActionByUID: "",
      ActionByEmailID: "",
      BusinessName: this.state.businessName,
      BusinessTypeID: this.state.businessTypeID,
      BusinessType: this.state.businessType,
      CountryCode: this.state.countryCode,
      Country: this.state.country,
      ContactNo: this.state.contactNo,
      StorageFolder: this.state.businessUniqueName,
      UniqueName: this.state.businessUniqueName,
      EmailID: this.state.emailID,
      Name: this.state.fullName,
      Source: "web",
      ReferredBy: this.state.referredBy,
      WhiteLabel: this.state.whiteLabel,
      Password: this.state.password,
      PowerUserUID: powerUserUID,
      StoreOwnerUID: storeOwnerUID,
      StoreOwnerEmailID: storeOwnerEmailID,
      PowerUserEmailID: powerUserEmailID,
    };

    let plan = this.state.plan;

    if (plan) {
      registration.PlanID = plan.key;
      registration.Plan = plan.Name;
      registration.Slug = plan.Slug;
      registration.Currency = plan.Currency;
      registration.CurrencySymbol = plan.CurrencySymbol;
      registration.PlanModules = plan.Modules;
      registration.NoOfCashRegistersAllowed = plan.NoOfCashRegistersAllowed;
      registration.NoOfCustomersAllowed = plan.NoOfCustomersAllowed;
      registration.NoOfEmployeesAllowed = plan.NoOfEmployeesAllowed;
      registration.NoOfInventoryTransactionsAllowed =
        plan.NoOfInventoryTransactionsAllowed;
      registration.NoOfProductsAllowed = plan.NoOfProductsAllowed;
      registration.NoOfPromotionalSMS = plan.NoOfPromotionalSMS;
      registration.NoOfSalesTransactionsAllowed =
        plan.NoOfSalesTransactionsAllowed;
      registration.NoOfStoreLocationsAllowed = plan.NoOfStoreLocationsAllowed;
      registration.NoOfStoresAllowed = plan.NoOfStoresAllowed;
      registration.NoOfSupportHrs = plan.NoOfSupportHrs;
      registration.NoOfTransactionalSMS = plan.NoOfTransactionalSMS;
      registration.NoOfUsersAllowed = plan.NoOfUsersAllowed;
      registration.TrialPeriod = plan.TrialPeriod;
    }
    console.log("registration", JSON.stringify(registration));
    await this.props.createRegistration(registration);
    // .then(() => {
    this.setState({
      loading: false,
      success: true,
      businessName: "",
      businessTypeID: "",
      businessType: "",
      emailID: "",
      contactNo: "",
    });
    this.props.getToastr("Registration created successfully");
    alert("Registration created successfully");
    // });
  }

  render() {
    return (
      <Page
        title="New Registration"
        showSaveButton={true}
        showCancelButton={true}
        processing={this.state.saving}
        onClick={(action) => {
          if (action === "save") {
            this.onRegister();
          }
        }}
      >
        <PageSection>
          <div className="row">
            <div className="col-md-3">
              <Input
                type="text"
                label="Business/Brand name"
                error={this.state.businessNameError}
                value={this.state.businessName}
                onChange={(value) => {
                  this.setState({
                    businessName: value.replace(
                      /[&\/\\#,+()$~%.'":*?<>{}]/g,
                      ""
                    ),
                    fullName: value.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ""),
                    businessUniqueName: value
                      .replace(/[^a-zA-Z0-9]/g, "")
                      .toLowerCase(),
                    businessNameError: "",
                  });
                }}
              />
              <Input
                type="select"
                label="Business Type"
                value={this.state.businessTypeID}
                onChange={(value) => {
                  let businessTypeID = value;
                  let businessType = "";
                  if (value.trim() !== "") {
                    businessType = this.state.businessTypes.filter(
                      (f) => f.BusinessTypeID === value
                    )[0].BusinessType;
                  }
                  this.setState({
                    businessTypeID: businessTypeID,
                    businessType: businessType,
                    businessTypeError: "",
                  });
                }}
                error={this.state.businessTypeError}
              >
                <option value="">Select business type</option>
                {this.state.businessTypes.map((b) => (
                  <option value={b.BusinessTypeID}>{b.BusinessType}</option>
                ))}
              </Input>
              <Input
                type="number"
                label="Mobile No"
                value={this.state.contactNo}
                onChange={(value) =>
                  this.setState({
                    contactNo: value,
                    contactNoError: "",
                  })
                }
                error={this.state.contactNoError}
              />
              <Input
                type="text"
                label="Email"
                value={this.state.emailID}
                error={this.state.emailIDError}
                onChange={(value) =>
                  this.setState({ emailID: value, emailIDError: "" })
                }
              />
              {this.state.whiteLabel !== "PINCODE" && (
                <Input
                  type="select"
                  value={this.state.whiteLabel}
                  onChange={(value) => {
                    this.setState({ whiteLabel: value });
                  }}
                >
                  <option value="POSIBLE">POSIBLE</option>
                  <option value="PINCODE">PINCODE</option>
                  <option value="WRX">WRX</option>
                </Input>
              )}
            </div>
          </div>
        </PageSection>
      </Page>
    );
  }
}

export default withRouter(NewRegistration);
