import React, { Component } from "react";
import Modal from "react-responsive-modal";
import Page from "../2/Controls/Page";
import PageSection from "../2/Controls/PageSection";
var AsyncTypeahead = require("react-bootstrap-typeahead").AsyncTypeahead;

class BarcodePrint extends Component {
  constructor() {
    super();
    this.state = {
      data: null,
      transactionType: "",
      transactionNo: "",
      storeID: "",
      printDataList: [],
      toggleSearch: false,
      productsTypeAheadOptions: [],
      isProductTypeAheadLoading: false,
      productsTypeAheadSelected: [],
      queryText: "",
      error: "",
      totalQty: 0,
      showError: false,
      customField1DisplayName: "Custom Field 1",
      customField2DisplayName: "Custom Field 2",
      customField3DisplayName: "Custom Field 3",
      customField4DisplayName: "Custom Field 4",
      customField5DisplayName: "Custom Field 5",
      customField6DisplayName: "Custom Field 6",
    };
    this.onNoOfPrintsChange = this.onNoOfPrintsChange.bind(this);
    this.onChangeCustomField1 = this.onChangeCustomField1.bind(this);
    this.onChangeCustomField2 = this.onChangeCustomField2.bind(this);
    this.onRemoveLineItem = this.onRemoveLineItem.bind(this);
    this.onPrint = this.onPrint.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onProductSelected = this.onProductSelected.bind(this);
    this.onProductTypeAheadSearch = this.onProductTypeAheadSearch.bind(this);
    this.onProductBarCodeSearch = this.onProductBarCodeSearch.bind(this);
  }

  componentDidMount() {
    this.props.config.CustomFields.map((customField) => {
      if (customField.ModuleName === "Product") {
        let customField1DisplayName =
          customField.CustomField1Name.trim() === ""
            ? "Custom Field 1"
            : customField.CustomField1Name.trim();
        let customField2DisplayName =
          customField.CustomField2Name.trim() === ""
            ? "Custom Field 2"
            : customField.CustomField2Name.trim();
        let customField3DisplayName =
          customField.CustomField3Name.trim() === ""
            ? "Custom Field 3"
            : customField.CustomField3Name.trim();
        let customField4DisplayName =
          customField.CustomField4Name.trim() === ""
            ? "Custom Field 4"
            : customField.CustomField4Name.trim();
        let customField5DisplayName =
          customField.CustomField5Name.trim() === ""
            ? "Custom Field 5"
            : customField.CustomField5Name.trim();
        let customField6DisplayName =
          customField.CustomField6Name.trim() === ""
            ? "Custom Field 6"
            : customField.CustomField6Name.trim();
        this.setState({
          customField1DisplayName: customField1DisplayName,
          customField2DisplayName: customField2DisplayName,
          customField3DisplayName: customField3DisplayName,
          customField4DisplayName: customField4DisplayName,
          customField5DisplayName: customField5DisplayName,
          customField6DisplayName: customField6DisplayName,
        });
      }
    });

    let data = this.props.location.state.data;
    let transactionType = this.props.location.state.transactionType;
    let transactionNo = "";
    let transactionDate = "";
    let printDataList = [];
    let totalQty = 0;
    if (this.props.location.state.transactionType !== "master") {
      let vendorCode = "";
      let vendorName = "";
      let storeCode = data.Store.StoreCode;
      let storeName = data.Store.StoreName;
      transactionNo = data.TransactionNo;
      transactionDate = new Date(data.TransactionDate.seconds * 1000); //.toString().substring(0,24)
      let months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      let month = new Date(transactionDate).getMonth();
      month = months[month];
      let date =
        new Date(transactionDate).getDate() +
        "-" +
        month +
        "-" +
        new Date(transactionDate).getFullYear();
      // console.log(data);

      if (data.Vendor) {
        vendorCode = data.Vendor.VendorCode;
        vendorName =
          data.Vendor.VendorFirstName + " " + data.Vendor.VendorLastName;
      }
      data.LineItems.map((m) => {
        let printData = {
          SKU: m.Product.SKU,
          Name: m.Product.Name,
          Barcode: m.Product.BarCode,
          RetailPrice:
            m.hasOwnProperty("BatchRetailPrice") &&
            String(m.BatchRetailPrice) !== ""
              ? m.BatchRetailPrice
              : m.Product.RetailPrice,
          SupplyPrice:
            m.SupplyPrice && m.SupplyPrice !== 0
              ? m.SupplyPrice
              : m.Product.SupplyPrice,
          MRP:
            m.hasOwnProperty("BatchMRP") && String(m.BatchMRP) !== ""
              ? m.BatchMRP
              : m.Product.MRP,
          Category: m.Product.Category,
          SubCategory: m.Product.SubCategory,
          Brand: m.Product.Brand,
          VendorCode: vendorCode,
          VendorName: vendorName,
          StoreCode: storeCode,
          StoreName: storeName,
          TransactionNo: transactionNo,
          TransactionDate: date,
          NoOfPrints: Number(m.Qty),
        };
        printData["CustomField1"] = m.Product.hasOwnProperty("CustomField1")
          ? m.Product.CustomField1
          : "";
        printData["CustomField2"] = m.Product.hasOwnProperty("CustomField2")
          ? m.Product.CustomField2
          : "";
        printData["CustomField3"] = m.Product.hasOwnProperty("CustomField3")
          ? m.Product.CustomField3
          : "";
        printData["CustomField4"] = m.Product.hasOwnProperty("CustomField4")
          ? m.Product.CustomField4
          : "";
        printData["CustomField5"] = m.Product.hasOwnProperty("CustomField5")
          ? m.Product.CustomField5
          : "";
        printData["CustomField6"] = m.Product.hasOwnProperty("CustomField6")
          ? m.Product.CustomField6
          : "";
        totalQty += Number(m.Qty);
        if (this.props.registration.registration.EnablePosBillingForPincode) {
          printData.CustomField1 = "";
          printData.CustomField2 = "";
          printData.CustomField3 = "";
          printData.CustomField4 = "";
          printData.CustomField5 = "";
          printData.CustomField6 = "";
        }
        printDataList.push(printData);
      });
    }
    this.setState({
      data: data,
      transactionType: transactionType,
      printDataList: printDataList,
      storeID: this.props.user.store.key,
      transactionNo: transactionNo,
      totalQty: totalQty,
    });
  }

  onNoOfPrintsChange(index, value) {
    let printDataList = this.state.printDataList;
    printDataList[index].NoOfPrints = Number(value);
    let totalQty = 0;

    for (let index = 0; index < printDataList.length; index++) {
      const obj = printDataList[index];
      totalQty += obj.NoOfPrints;
    }
    this.setState({ printDataList: printDataList, totalQty: totalQty });
  }

  onChangeCustomField1(index, value) {
    let printDataList = this.state.printDataList;
    printDataList[index].CustomField1 = value;
    this.setState({ printDataList: printDataList });
  }

  onChangeCustomField2(index, value) {
    let printDataList = this.state.printDataList;
    printDataList[index].CustomField2 = value;
    this.setState({ printDataList: printDataList });
  }

  onPrint() {
    // console.log('barcode print called')
    //should have atleast ine line item
    //save to db
    //inoke exe and pass storekey and saved record data to exe
    if (this.state.printDataList.length === 0) {
      // alert('No data to print')
      this.setState({ error: "No data to print", showError: true });
      return;
    }
    let printData = {
      TransactionType: this.state.transactionType,
      TransactionNo: this.state.transactionNo,
      StoreID: this.state.storeID,
      PrintDataList: this.state.printDataList,
    }; //
    this.props.saveDataForBarcodePrint(printData).then((key) => {
      window.invokeBarcodePrintExe(this.props.user.store.key + "," + key);
      this.props.getToastr("Printing barcode");
      this.onCancel();
    });
  }

  onCancel() {
    if (this.props.location.state.transactionType === "master") {
      if (this.props.registration.registration.EnablePosBillingForPincode) {
        this.props.history.push({
          pathname: "/home/product/pincode",
        });
      } else {
        this.props.history.push({
          pathname: "/home/product",
        });
      }
    } else {
      this.props.history.push({
        pathname: "/home/inventory/inventorylist",
        state: { type: "list" },
      });
    }
  }

  onRemoveLineItem(index) {
    let printDataList = this.state.printDataList;
    let totalQty = 0;
    printDataList.splice(index, 1);
    for (let index = 0; index < printDataList.length; index++) {
      const obj = printDataList[index];
      totalQty += obj.NoOfPrints;
    }
    this.setState({ printDataList: printDataList, totalQty: totalQty });
  }

  onProductTypeAheadSearch = (query) => {
    this.setState({ isProductTypeAheadLoading: true });
    this.props
      .getProductsForInventorySearch(
        this.props.user.user.RegistrationID,
        query,
        false
      )
      .then((result) => {
        this.setState({
          productsTypeAheadOptions: result.searchResult,
          isProductTypeAheadLoading: false,
        });
      });
  };

  onProductBarCodeSearch(evt) {
    // console.log('this.state.queryText',this.state.queryText)
    if (evt.key === "Enter") {
      var productList = this.props.config.Products.filter(
        (e) => e.SKU === this.state.queryText
      );
      // console.log('productList',productList)
      if (productList.length > 0) {
        this.onProductSelected(productList[0]);
        this.setState({ queryText: "" });
      }
    }
  }

  async onProductSelected(product) {
    let printDataList = this.state.printDataList;
    let totalQty = this.state.totalQty;
    if (
      printDataList.filter((f) => f.ProductID.trim() === product.key.trim())
        .length > 0
    ) {
      printDataList.map((m) => {
        if (m.ProductID.trim() === product.key.trim()) {
          m.NoOfPrints = m.NoOfPrints + 1;
          totalQty += 1;
        }
      });
    } else {
      let printData = {
        ProductID: product.key,
        SKU: product.SKU,
        Name: product.Name,
        Barcode: product.BarCode,
        RetailPrice: product.RetailPrice,
        SupplyPrice: product.SupplyPrice,
        MRP: product.MRP,
        Category: product.Category,
        SubCategory: product.SubCategory,
        Brand: product.Brand,
        VendorCode: "",
        VendorName: "",
        StoreCode: "",
        StoreName: "",

        NoOfPrints: 1,
      };

      printData["CustomField1"] = product.hasOwnProperty("CustomField1")
        ? product.CustomField1
        : "";
      printData["CustomField2"] = product.hasOwnProperty("CustomField2")
        ? product.CustomField2
        : "";
      printData["CustomField3"] = product.hasOwnProperty("CustomField3")
        ? product.CustomField3
        : "";
      printData["CustomField4"] = product.hasOwnProperty("CustomField4")
        ? product.CustomField4
        : "";
      printData["CustomField5"] = product.hasOwnProperty("CustomField5")
        ? product.CustomField5
        : "";
      printData["CustomField6"] = product.hasOwnProperty("CustomField6")
        ? product.CustomField6
        : "";

      if (this.props.registration.registration.EnablePosBillingForPincode) {
        printData.CustomField1 = "";
        printData.CustomField2 = "";
        printData.CustomField3 = "";
        printData.CustomField4 = "";
        printData.CustomField5 = "";
        printData.CustomField6 = "";
      }

      if (product.hasOwnProperty("AccessibleToSelectedStores")) {
        if (product.AccessibleToSelectedStores) {
          let storePrice = product.StoreSpecificPrices.filter(
            (f) => f.StoreID === this.props.user.store.key
          );
          if (storePrice.length > 0) {
            printData.RetailPrice = storePrice[0].RetailPrice;
            printData.SupplyPrice = storePrice[0].SupplyPrice;
            printData.MRP = storePrice[0].MRP;
          }
        }
      }
      if (product.hasOwnProperty("StoreSpecificPricing")) {
        if (product.StoreSpecificPricing) {
          let storePrice = product.StoreSpecificPrices.filter(
            (f) => f.StoreID === this.props.user.store.key
          );

          if (storePrice.length > 0) {
            printData.SupplyPrice = storePrice[0].SupplyPrice;
            printData.RetailPrice = storePrice[0].RetailPrice;
            printData.MRP = storePrice[0].MRP;
          }
        }
      }

      totalQty += 1;

      // console.log("printData", JSON.stringify(printData));
      printDataList.push(printData);
    }
    this.setState({ printDataList: printDataList, totalQty: totalQty });
  }

  render() {
    return (
      <React.Fragment>
        <Page
          {...this.props}
          title="Print Barcode"
          showPrintButton={true}
          showCancelButton={true}
          processing={this.state.saving}
          onClick={(action) => {
            if (action === "cancel") {
              this.onCancel();
            } else if (action === "print") {
              this.onPrint();
            }
          }}
        >
          <PageSection title="">
            {/* <div className="row">
              <div className="col-md-12" style={{ marginTop: "10px" }}>
                <div className="box ">
                  <div className="box-body">
                   
                  </div>
                </div>
              </div>
            </div> */}
            <div className="row">
              <div className="col-md-4">
                {this.props.location.state.transactionType === "master" && (
                  <div
                    className="input-group"
                    style={{ width: "100%", paddingBottom: "10px" }}
                  >
                    {!this.state.toggleSearch && (
                      <AsyncTypeahead
                        autoFocus={true}
                        allowNew={false}
                        isLoading={this.state.isProductTypeAheadLoading}
                        multiple={false}
                        options={this.state.productsTypeAheadOptions}
                        labelKey="Name"
                        minLength={3}
                        onSearch={this.onProductTypeAheadSearch}
                        placeholder="Start typing or scanning to search products"
                        filterBy={["Name", "SKU", "BarCode"]}
                        dropup={false}
                        highlightOnlyResult={true}
                        selected={this.state.productsTypeAheadSelected}
                        onChange={(selected) => {
                          this.onProductSelected(selected[0]);
                          this.setState({
                            productsTypeAheadSelected: [],
                          });
                        }}
                        renderMenuItemChildren={(option, props) => (
                          <div>
                            <div>
                              <b>{option.Name}</b>
                            </div>
                            <div>{option.SKU}</div>
                          </div>
                        )}
                      />
                    )}
                    {this.state.toggleSearch && (
                      <input
                        type="text"
                        onChange={(evt) => {
                          this.setState({ queryText: evt.target.value });
                        }}
                        value={this.state.queryText}
                        onKeyPress={this.onProductBarCodeSearch}
                        placeholder="Start scanning to search products"
                        className="form-control"
                        style={{ height: "33px", fontSize: "14px" }}
                      />
                    )}
                    <div className="input-group-btn">
                      <button
                        type="button"
                        onClick={() =>
                          this.setState({
                            toggleSearch: !this.state.toggleSearch,
                          })
                        }
                        className="btn btn-default btn-lg btn-flat"
                        style={{
                          height: "33px",
                          padding: "5px 16px",
                          borderBottomLeftRadius: "0px",
                          borderTopLeftRadius: "0px",
                        }}
                      >
                        <i className="fa fa-exchange"></i>
                      </button>
                    </div>
                  </div>
                )}
              </div>

              <div className="col-md-12">
                <div
                  style={{ width: "auto", overflowX: "auto" }}
                  className="scroll2"
                >
                  <table className="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th>SKU</th>
                        <th>Barcode</th>
                        <th>Name</th>
                        <th style={{ width: "100px" }}>MRP</th>
                        <th style={{ width: "100px" }}>Retail Price</th>
                        <th style={{ width: "100px" }}>Supply Price</th>
                        {this.props.registration.registration
                          .EnablePosBillingForPincode && (
                          <>
                            <th style={{ width: "150px" }}>
                              {this.state.customField1DisplayName}
                            </th>
                            <th style={{ width: "150px" }}>
                              {this.state.customField2DisplayName}
                            </th>
                          </>
                        )}
                        <th style={{ width: "100px" }}>Qty</th>
                        <th style={{ width: "60px" }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.printDataList.map((printData, index) => (
                        <tr key={index}>
                          <td>{printData.SKU}</td>
                          <td>{printData.Barcode}</td>
                          <td>{printData.Name}</td>
                          <td>{printData.MRP}</td>
                          <td>{printData.RetailPrice}</td>
                          <td>{printData.SupplyPrice}</td>
                          {this.props.registration.registration
                            .EnablePosBillingForPincode && (
                            <>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={(evt) =>
                                    this.onChangeCustomField1(
                                      index,
                                      evt.target.value
                                    )
                                  }
                                  value={printData.CustomField1}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={(evt) =>
                                    this.onChangeCustomField2(
                                      index,
                                      evt.target.value
                                    )
                                  }
                                  value={printData.CustomField2}
                                />
                              </td>
                            </>
                          )}

                          <td>
                            <input
                              type="number"
                              className="form-control"
                              onChange={(evt) =>
                                this.onNoOfPrintsChange(index, evt.target.value)
                              }
                              value={printData.NoOfPrints}
                            />
                          </td>
                          <td>
                            <button
                              type="button"
                              className="btn btn-default btn-md"
                              onClick={() => this.onRemoveLineItem(index)}
                            >
                              <i className="glyphicon glyphicon-remove"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan="8">Total Qty</td>
                        <td>{this.state.totalQty}</td>
                        <td></td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </PageSection>
        </Page>

        <Modal
          open={this.state.showError}
          onClose={() => this.setState({ showError: false })}
          center
        >
          <div style={{ width: "300px" }}>
            <h3 style={{ marginTop: "0px" }}>Error</h3>
            <div className="box-body">
              <ul>{this.state.error !== "" && <li>{this.state.error}</li>}</ul>
            </div>
            <div className="box-footer">
              <button
                type="submit"
                className="btn btn-flat btn-md btn-danger pull-right"
                onClick={() => this.setState({ showError: false, error: "" })}
              >
                OK
              </button>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default BarcodePrint;
