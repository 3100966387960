import React, { Component } from "react";
import * as constants from "../Constatnts";
import axios from "axios";

class ImportLog extends Component {
  constructor() {
    super();
    this.state = {
      logList: [],
      loading: true,
      newImport: false,
    };
    this.renderDate = this.renderDate.bind(this);
    this.onExportClick = this.onExportClick.bind(this);
  }

  async componentDidMount() {
    let newImport = false;
    let logList = [];
    if (this.props.registration.registration.PincodeNewImport) {
      newImport = true;
      const response = await axios.post(
        "https://import-engine-891778245735.us-central1.run.app/catalog/import/products",
        {
          RegistrationID: this.props.registration.registration.key,
        }
      );
      // console.log("response", JSON.stringify(response));
      logList = response.data.importLogs;
    } else {
      logList = await this.props.getImportLogList(
        this.props.user.user.RegistrationID,
        this.props.importType
      );
      console.log("logList", logList);
      // .then((logList) => {
      //   console.log("logList", logList);
      //   this.setState({ logList: logList, loading: false });
      // });
    }
    this.setState({ newImport: newImport, logList: logList, loading: false });
  }

  async onExportClick(log) {
    // alert(key)
    // console.log("key", key);
    let productImportType = log.ProductImportType;
    let key = "";
    this.setState({ exportCalled: true });
    let importLogs = [];
    let result = [];
    if (this.state.newImport) {
      key = log._id;
      const responseLogs = await axios.get(
        "https://import-engine-891778245735.us-central1.run.app/catalog/import/products/" +
          key +
          "/logs"
      );
      console.log("showStatus responseLogs", JSON.stringify(responseLogs));
      // this.setState({ logs: responseLogs.data.importProducts });
      result = responseLogs.data.importProduct;
      console.log("result", result);
    } else {
      key = log.key;
      result = await this.props.getImportLogs(key);
    }
    result.map((log) => {
      if (this.props.importType.toLowerCase() === "product") {
        let importLog = null;
        if (productImportType === "2") {
          importLog = {
            ProductID: log.ProductID,
            SupplyPrice: log.SupplyPrice,
            Markup: log.Markup,
            RetailPrice: log.RetailPrice,
            MRP: log.MRP,
            DiscountPercentage: log.DiscountPercentage,
            DiscountAmount: log.DiscountAmount,
            Status: log.Status,
            Error: log.Error,
            ActionOn: new Date(log.ActionOn.seconds * 1000)
              .toString()
              .substring(0, 24),
          };
        } else if (
          productImportType === "4" ||
          productImportType === "4.1" ||
          productImportType === "4.2" ||
          productImportType === "4.3"
        ) {
          importLog = {
            ExcelRowNo: log.ExcelRowNo,
            SKU: log.hasOwnProperty("SKU") ? log.SKU : "",
            BarCode: log.BarCode,
            MRP: log.MRP,
            RetailPrice: log.RetailPrice,
            OutOfStock: log.OutOfStock,
            ShowOnline: log.ShowOnline,
            InStock: log.InStock,
            Status: log.Status,
            Message: log.Message,
            ActionOn: new Date(log.ActionOn.seconds * 1000)
              .toString()
              .substring(0, 24),
          };
        } else if (productImportType === "5") {
          importLog = {
            ExcelRowNo: log.ExcelRowNo,
            SKU: log.SKU,
            BarCode: log.BarCode,
            Brand: log.Brand,
            Category: log.Category,
            SubCategory: log.SubCategory,
            Name: log.Name,
            Description: log.Description,
            HSNSACCode: log.HSNSACCode,
            ChargeTaxOnProduct: log.ChargeTaxOnProduct,
            MRP: log.MRP,
            SupplyPrice: log.SupplyPrice,
            RetailPrice: log.RetailPrice,
            Markup: log.Markup,
            DiscountPercentage: log.DiscountPercentage,
            DiscountAmount: log.DiscountAmount,
            TaxInclusive: log.TaxInclusive,
            TaxInclusivePurchase: log.TaxInclusivePurchase,
            TaxGroup: log.TaxGroup,
            InventoryType: log.InventoryType,
            MinQty: log.MinQty,
            MaxQty: log.MaxQty,
            ReorderQty: log.ReorderQty,
            BatchTrackingBatchNoRequired: log.BatchTrackingBatchNoRequired,
            BatchTrackingMRPRequired: log.BatchTrackingMRPRequired,
            BatchTrackingMfgDateRequired: log.BatchTrackingMfgDateRequired,
            BatchTrackingExpDateRequired: log.BatchTrackingExpDateRequired,
            BatchTrackingDateFormat: log.BatchTrackingDateFormat,
            OpeningStock: log.OpeningStock,
            Status: log.Status,
            Error: log.Error,
            ActionOn: new Date(log.ActionOn.seconds * 1000)
              .toString()
              .substring(0, 24),
          };
          if (log.ProductID) {
            importLog.ProductID = log.ProductID;
          }
        } else {
          importLog = {
            ProductType: log.ProductType,
            SKU: log.SKU,
            BarCode: log.BarCode,
            Brand: log.Brand,
            Category: log.Category,
            SubCategory: log.SubCategory,
            Name: log.Name,
            Description: log.Description,
            HSNSACCode: log.HSNSACCode,
            ChargeTaxOnProduct: log.ChargeTaxOnProduct,
            MRP: log.MRP,
            SupplyPrice: log.SupplyPrice,
            RetailPrice: log.RetailPrice,
            Markup: log.Markup,
            TaxInclusive: log.TaxInclusive,
            TaxGroup: log.TaxGroup,
            PriceBasedTax: log.PriceBasedTax,
            PriceRangeFrom1: log.PriceRangeFrom1,
            PriceRangeTo1: log.PriceRangeTo1,
            TaxGroup1: log.TaxGroup1,
            PriceRangeFrom2: log.PriceRangeFrom2,
            PriceRangeTo2: log.PriceRangeTo2,
            TaxGroup2: log.TaxGroup2,
            InventoryType: log.hasOwnProperty("InventoryType")
              ? log.InventoryType
              : "",
            MinQty: log.hasOwnProperty("MinQty") ? log.MinQty : 0,
            MaxQty: log.hasOwnProperty("MaxQty") ? log.MaxQty : 0,
            ReorderQty: log.hasOwnProperty("ReorderQty") ? log.ReorderQty : 0,
            UniqueNoLabel: log.hasOwnProperty("UniqueNoLabel")
              ? log.UniqueNoLabel
              : 0,
            NoOfUniqueNoPerItem: log.hasOwnProperty("NoOfUniqueNoPerItem")
              ? log.NoOfUniqueNoPerItem
              : 0,
            AllowDiscount: log.AllowDiscount,
            AllowPriceEdit: log.AllowPriceEdit,
            AllowTaxChange: log.AllowTaxChange,
            WalletTopUpAmount: log.WalletTopUpAmount,
            WalletTopUpSameAsRetailPrice: log.WalletTopUpSameAsRetailPrice,
            Status: log.Status,
            Error: log.Error,
            ActionOn: new Date(log.ActionOn.seconds * 1000)
              .toString()
              .substring(0, 24),
            ProductID: log.key,
          };
        }
        importLogs.push(importLog);
      } else if (this.props.importType.toLowerCase() === "customer") {
        let importLog = {
          CustomerCode: log.CustomerCode,
          FirstName: log.FirstName,
          LastName: log.LastName,
          Gender: log.Gender,
          PhoneNo: log.PhoneNo,
          EmailID: log.EmailID,
          Notes: log.Notes,
          DOB: log.DOB,
          Anniversary: log.Anniversary,
          AddressType: log.AddressType,
          AddressLine1: log.AddressLine1,
          AddressLine2: log.AddressLine2,
          City: log.City,
          State: log.State,
          PostalCode: log.PostalCode,
          Country: log.Country,
          CustomField1: log.CustomField1,
          CustomField2: log.CustomField2,
          CustomField3: log.CustomField3,
          CustomField4: log.CustomField4,
          CustomField5: log.CustomField5,
          CustomField6: log.CustomField6,
          Membership: log.Membership,
          WalletOpeningBalance: log.WalletOpeningBalance,
          OutstandingAmount: log.OutstandingAmount,
          Status: log.Status,
          Error: log.Error,
          ActionOn: new Date(log.ActionOn.seconds * 1000)
            .toString()
            .substring(0, 24),
          CustomerID: log.key,
        };
        importLogs.push(importLog);
      } else if (this.props.importType.toLowerCase() === "bom") {
        let importLog = {
          BOMName: log.BOMName,
          BOMProductSKU: log.BOMProductSKU,
          BOMProductName: log.BOMProductName,
          RawMaterialSKU: log.RawMaterialSKU,
          RawMaterialName: log.RawMaterialName,
          RawMaterialQty: log.RawMaterialQty,
          Status: log.Status,
          Error: log.Error,
          ActionOn: new Date(log.ActionOn.seconds * 1000)
            .toString()
            .substring(0, 24),
          CustomerID: log.key,
        };
        importLogs.push(importLog);
      } else if (this.props.importType.toLowerCase() === "loyaltycustomer") {
        let importLog = {
          CustomerCode: log.CustomerCode,
          CustomerFirstName: log.CustomerFirstName,
          CustomerFirstName: log.CustomerLastName,
          CustomerPhoneNo: log.CustomerPhoneNo,
          CustomerEmailID: log.CustomerEmailID,
          LoyaltyType: log.LoyaltyType,
          LoyaltyPoints: log.LoyaltyPoints,
          Status: log.Status,
          Error: log.Error,
          ActionOn: new Date(log.ActionOn.seconds * 1000)
            .toString()
            .substring(0, 24),
        };
        importLogs.push(importLog);
      }
    });
    const csvData = constants.objToCSV(importLogs);
    this.setState({ loading: false, exportCalled: false });
    const csvBlob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "ImportLogs.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    // });
  }

  renderDate(dateField) {
    if (!dateField) {
      return "";
    }
    if (!this.state.newImport) {
      return new Date(dateField.seconds * 1000).toString().substring(0, 24);
    } else {
      return new Date(dateField).toString().substring(0, 24);
    }
  }
  render() {
    return (
      <React.Fragment>
        <div className="import-header">
          <div>Import Log</div>
        </div>
        <div
          className="import-content"
          style={{
            height: window.innerHeight - 50 + "px",
            overflowY: "auto",
          }}
        >
          {this.state.logList.map((log) => (
            <div className="info">
              <div className="info-header">
                Date & time : {this.renderDate(log.ActionOn)}
              </div>
              <div className="info-text">
                Action By: {log.ActionBy} ({log.ActionByEmailID})
              </div>
              <div className="info-text">
                Processing Start Time:{" "}
                {this.renderDate(log.ProcessingStartTime)}
                {/* {log.ProcessingStartTime
                  ? new Date(log.ProcessingStartTime.seconds * 1000)
                      .toString()
                      .substring(0, 24)
                  : ""} */}
              </div>
              <div className="info-text">
                Processing End Time: {this.renderDate(log.ProcessingEndTime)}
                {/* {log.ProcessingEndTime
                  ? new Date(log.ProcessingEndTime.seconds * 1000)
                      .toString()
                      .substring(0, 24)
                  : ""} */}
              </div>
              {log.ForStore && (
                <div className="info-text">For Store: {log.ForStore}</div>
              )}
              <div className="info-text">File: {log.FileName}</div>
              {log.hasOwnProperty("totalCreate") && (
                <div className="info-text">New Count: {log.totalCreate}</div>
              )}
              {log.hasOwnProperty("totalUpdate") && (
                <div className="info-text">Update Count: {log.totalUpdate}</div>
              )}
              {log.hasOwnProperty("totalError") && (
                <div className="info-text">Error Count: {log.totalError}</div>
              )}
              <div className="info-text">Status: {log.Status}</div>
              <div>
                {log.Status === "Completed" && (
                  <a onClick={() => this.onExportClick(log)}>
                    <i className="glyphicon glyphicon-download-alt"></i>
                  </a>
                )}
              </div>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}
export default ImportLog;
